var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "receiving-details-modal",
        height: "auto",
        width: "400px",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container modal-content border-bottom-0 h-100" },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h4", { staticClass: "modal-title" }, [
                _vm._v("Receiving Details"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.index === "tool_scan"
              ? _c("h6", [
                  _vm._v("Tool Name: " + _vm._s(_vm.$props.product.name)),
                ])
              : _c("h6", [
                  _vm._v("Product Name: " + _vm._s(_vm.$props.product.name)),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "row mt-2 align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12 ml-0 pl-0" },
                        [
                          _c("label", { attrs: { for: "order_qty" } }, [
                            _vm._v("Qty. Received"),
                            _c("span", { staticClass: "required_asterisk" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.product.qty_received,
                                expression: "product.qty_received",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              autofocus: "",
                              type: "number",
                              oninput: "validity.valid||(value='')",
                              min: "0",
                              name: "product",
                              id: "order_qty1",
                              placeholder: "####",
                            },
                            domProps: { value: _vm.product.qty_received },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.product,
                                  "qty_received",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("warehouse-allocation-component", {
                      attrs: {
                        index: _vm.index,
                        parent: _vm.product,
                        type: "product",
                        zones: _vm.zones,
                        locations: _vm.locations,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.scanLocation()
                        },
                      },
                    },
                    [_vm._v("Scan Location")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v("Close")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ]),
          _vm._v(" "),
          _c("scan-location-modal", {
            attrs: {
              index: _vm.index,
              product: _vm.product,
              locations: _vm.locations,
              zones: _vm.zones,
              flag1: _vm.flag1,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }