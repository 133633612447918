var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "dispatch-products-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Product to Dispatch"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("label", { attrs: { for: "quantity" } }, [_vm._v("Product")]),
              _vm._v(" "),
              _c(
                "dropbox",
                {
                  attrs: {
                    name: "products",
                    id: "products",
                    options: this.products_bom,
                  },
                  on: {
                    change: function ($event) {
                      _vm.$forceUpdate(),
                        _vm.findProduct(_vm.form.product.product_id)
                    },
                  },
                  model: {
                    value: _vm.form.product.product_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.product, "product_id", $$v)
                    },
                    expression: "form.product.product_id",
                  },
                },
                [_c("option")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "quantity" } }, [_vm._v("Quantity")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.product.quantity,
                  expression: "form.product.quantity",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "number",
                oninput: "validity.valid||(value='')",
                min: "0",
                name: "quantity",
                placeholder: "####",
              },
              domProps: { value: _vm.form.product.quantity },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form.product, "quantity", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          this.products_bom.find(function (product) {
            return product.id == _vm.form.product.product_id
          }) != null &&
          this.products_bom.find(function (product) {
            return product.id == _vm.form.product.product_id
          }).use_serials
            ? _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("label", [_vm._v("Serial Numbers")]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: { color: "#007bff" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.findProduct(_vm.form.product.product_id),
                            _vm.$modal.show(1 + "-dispatch-serial-codes-modal")
                          _vm.$forceUpdate()
                        },
                      },
                    },
                    [_vm._v("Add or Edit Codes")]
                  ),
                  _vm._v(" "),
                  _c("dispatch-serial-codes-modal", {
                    attrs: {
                      product: _vm.form.product,
                      index: 1,
                      quantity: _vm.form.product.quantity,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("dispatch-products-modal")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              attrs: { disabled: _vm.submit_disabled, type: "button" },
              on: { click: _vm.submit },
            },
            [_vm._v("Dispatch")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }