var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { name: "project" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-10 grid-margin stretch-card" }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-8 ml-0" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("select-box", {
                              attrs: {
                                id: "supervisor",
                                name: "supervisor",
                                options: _vm.supervisors,
                              },
                              model: {
                                value: _vm.form.supervisor_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "supervisor_id", $$v)
                                },
                                expression: "form.supervisor_id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.form.zones, function (zone, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: index + "-picker" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        zone.type == "Warehouse"
                                          ? "Warehouse: " + zone.name
                                          : "Warehouse: " +
                                              zone.warehouse.name +
                                              " | Zone: " +
                                              zone.name
                                      )
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "required_asterisk" },
                                      [_vm._v("*")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("select-user-multiple", {
                                  attrs: {
                                    id: index + "-picker",
                                    options: _vm.pickers,
                                  },
                                  model: {
                                    value: zone.pickers,
                                    callback: function ($$v) {
                                      _vm.$set(zone, "pickers", $$v)
                                    },
                                    expression: "zone.pickers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("dropbox", {
                          attrs: {
                            id: "priority",
                            name: "priority",
                            options: _vm.priorities,
                          },
                          model: {
                            value: _vm.form.priority_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "priority_id", $$v)
                            },
                            expression: "form.priority_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "dock" } }, [
                          _vm._v("Waiting Dock"),
                        ]),
                        _vm._v(" "),
                        _c("dropbox-none", {
                          attrs: {
                            id: "dock",
                            name: "dock",
                            options: _vm.docks,
                          },
                          model: {
                            value: _vm.form.waiting_dock_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "waiting_dock_id", $$v)
                            },
                            expression: "form.waiting_dock_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("dropbox-none", {
                          attrs: {
                            id: "door",
                            name: "door",
                            options: _vm.doors,
                          },
                          model: {
                            value: _vm.form.warehouse_door_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "warehouse_door_id", $$v)
                            },
                            expression: "form.warehouse_door_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.submit_disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "spinner-border text-primary my-3",
                        attrs: { role: "status" },
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { disabled: _vm.submit_disabled },
                      },
                      [_vm._v("Submit")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-light",
                        attrs: { href: "/projects" },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-title" }, [
        _c("p", [_vm._v("Warehouse Information")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "supervisor" } }, [
      _vm._v("Crew Supervisor: "),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "priority" } }, [
      _vm._v("Priority"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "priority" } }, [
      _vm._v("Dispatch Door"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }