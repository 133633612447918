var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-10 grid-margin stretch-card" }, [
    _c("div", { staticClass: "card" }, [
      _c(
        "form",
        {
          ref: "product-return-form",
          attrs: { id: "product-return-form", name: "product-return-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "card-body" }, [
            _c("h6", { staticClass: "card-title" }, [_vm._v("Returns")]),
            _vm._v(" "),
            _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
              _vm._v("Dispatched Inventory"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-responsive pt-3", attrs: { id: "app" } },
              [
                _c(
                  "form",
                  {
                    staticStyle: { float: "right", "margin-bottom": "10px" },
                    attrs: { id: "search" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mb-2", staticStyle: { float: "right" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter,
                              expression: "filter",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "search",
                            placeholder: "Search",
                            onkeypress: "return event.keyCode != 13;",
                          },
                          domProps: { value: _vm.filter },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.filter = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "mb-2 mt-1",
                        staticStyle: { float: "right" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.scanProduct()
                              },
                            },
                          },
                          [_vm._v("Scan Product")]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  {
                    ref: "table",
                    staticClass: "table table-bordered table-hover",
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            "\n                                Add to list\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              onmouseover: "this.style.color='orange'",
                              onmouseout: "this.style.color=''",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sort("sku")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Product ID\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              onmouseover: "this.style.color='orange'",
                              onmouseout: "this.style.color=''",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sort("name")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Product\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              onmouseover: "this.style.color='orange'",
                              onmouseout: "this.style.color=''",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sort("warehouses")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Warehouse Name\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              onmouseover: "this.style.color='orange'",
                              onmouseout: "this.style.color=''",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sort("quantity_picked")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Dispatched Qty.\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Return Qty.\n                            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.sortedDispatched, function (item, index) {
                        return !_vm.product_returns.includes(item)
                          ? _c(
                              "tr",
                              {
                                key:
                                  item.id +
                                  "-" +
                                  item.pivot.warehouse_id +
                                  "-" +
                                  !_vm.product_returns.includes(item),
                              },
                              [
                                _c("td", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-icon ",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.returnProduct(item, index)
                                        },
                                      },
                                    },
                                    [
                                      _c("log-out-icon", {
                                        staticStyle: { color: "white" },
                                        attrs: { size: "1.5x" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.sku) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.name) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.warehouses.find(function (
                                          warehouse
                                        ) {
                                          return (
                                            warehouse.id ===
                                            item.pivot.warehouse_id
                                          )
                                        }) != null
                                          ? _vm.warehouses.find(function (
                                              warehouse
                                            ) {
                                              return (
                                                warehouse.id ===
                                                item.pivot.warehouse_id
                                              )
                                            }).name
                                          : ""
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.pivot.quantity_picked) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                item.use_serials
                                  ? _c(
                                      "td",
                                      { staticStyle: { width: "50px" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("select-record", {
                                              attrs: {
                                                name: "serial_numbers",
                                                options:
                                                  _vm.dispatched_record.filter(
                                                    function (record) {
                                                      return (
                                                        record.product_id ==
                                                          item.id &&
                                                        (record.last_history
                                                          .warehouse_id == null
                                                          ? true
                                                          : item.pivot
                                                              .warehouse_id ==
                                                            record.last_history
                                                              .warehouse_id)
                                                      )
                                                    }
                                                  ),
                                              },
                                              model: {
                                                value: item.serial_numbers,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "serial_numbers",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.serial_numbers",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "td",
                                      { staticStyle: { width: "50px" } },
                                      [
                                        !item.use_serials &&
                                        item.pivot.quantity_picked != 0
                                          ? _c("p", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.returned_qty,
                                                    expression:
                                                      "item.returned_qty",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "number",
                                                  oninput:
                                                    "validity.valid||(value=\\'\\')",
                                                  min: "1",
                                                  max: item.pivot
                                                    .quantity_picked,
                                                  name: "returned_qty",
                                                  placeholder: "##",
                                                },
                                                domProps: {
                                                  value: item.returned_qty,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.change_qty(
                                                      item,
                                                      item.id
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      item,
                                                      "returned_qty",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nav",
                  { staticStyle: { float: "right", "margin-top": "10px" } },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination" },
                      [
                        _c("li", { staticClass: "page-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "page-link",
                              attrs: { type: "button" },
                              on: { click: _vm.prevPage },
                            },
                            [_vm._v("Previous")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.pages, function (index) {
                          return _c("p", [
                            _vm.currentPage == index
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    staticStyle: {
                                      "background-color": "orange",
                                      color: "white",
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.alterCurrent(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(index) + " ")]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "page-link",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.alterCurrent(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(index) + " ")]
                                ),
                          ])
                        }),
                        _vm._v(" "),
                        _c("li", { staticClass: "page-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "page-link",
                              attrs: { type: "button" },
                              on: { click: _vm.nextPage },
                            },
                            [_vm._v("Next")]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(
                  "\n                    Showing " +
                    _vm._s(_vm.paginationCurrentFrom) +
                    " to " +
                    _vm._s(_vm.paginationCurrentTo) +
                    " of " +
                    _vm._s(_vm.filteredCats.length) +
                    " entries\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2 my-4",
                attrs: { type: "button" },
                on: { click: _vm.return_all },
              },
              [_vm._v("Return All")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2 my-4",
                attrs: { id: "add_returns_to_list", type: "button" },
                on: { click: _vm.add_returns_to_list },
              },
              [_vm._v("Add Returns to List")]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
              _vm._v("Returns"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive pt-3" }, [
              _c("table", { staticClass: "table table-bordered table-hover" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.product_returns, function (item, index) {
                    return _c(
                      "tr",
                      [
                        _c(
                          "td",
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFromIn(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-in-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("edit-icon", {
                              staticClass: "icon-color-primary",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(item.id, index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(item.sku) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(item.name) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.warehouses.find(function (warehouse) {
                                  return warehouse.id == item.warehouse_id
                                }) != null
                                  ? _vm.warehouses.find(function (warehouse) {
                                      return warehouse.id == item.warehouse_id
                                    }).name
                                  : ""
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(item.pivot.quantity_picked) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("p", [_vm._v(_vm._s(item.returned_qty))]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.quality.find(function (q) {
                                  return q.id == item.quality_check
                                }).name
                              ) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(item.return_reason) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          _vm._l(item.serial_numbers, function (record, index) {
                            return _c("div", { staticClass: "row" }, [
                              record.id == item.serial_numbers[0].id
                                ? _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dispatched_record.find(function (
                                          r
                                        ) {
                                          return r.id == record
                                        }).serial_number
                                      )
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        ", " +
                                          _vm.dispatched_record.find(function (
                                            r
                                          ) {
                                            return r.id == record
                                          }).serial_number
                                      )
                                    ),
                                  ]),
                            ])
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("project-return-modal", {
                          attrs: {
                            index: item.id,
                            qualities: _vm.quality,
                            product_returns: _vm.product_returns,
                            dispatched_inventory: _vm.dispatched_inventory,
                            dispatched_record: _vm.dispatched_record.filter(
                              function (record) {
                                return record.product_id == item.id
                              }
                            ),
                            warehouse_id1:
                              _vm.warehouses.find(function (warehouse) {
                                return warehouse.id == item.warehouse_id
                              }) != null
                                ? _vm.warehouses.find(function (warehouse) {
                                    return warehouse.id == item.warehouse_id
                                  }).id
                                : "",
                            zones: _vm.zones,
                            locations: _vm.locations,
                            product: item,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(1),
          ]),
          _vm._v(" "),
          _c("return-scan-item-modal", {
            attrs: {
              index: this.index,
              products: _vm.$props.dispatched_inventory,
              flag: _vm.flag,
              product_returns: _vm.sortedDispatched,
              returned_list: this.product_returns,
              dispatched_record: _vm.dispatched_record,
              warehouses: _vm.warehouses,
              zones: _vm.zones,
              locations: _vm.locations,
              parent: this,
              quality: _vm.$props.quality,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                Remove/Edit\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Product ID\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Product\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Warehouse Name\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Dispatched Qty.\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Returned Qty.\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Quality Check\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Reason\n                            "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                Serial Numbers\n                            "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-5" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary mr-2", attrs: { type: "submit" } },
        [_vm._v("Submit Inventory")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }