var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "receiving-scan-tool-modal-" + _vm.index,
        scrollable: true,
        height: "auto",
        width: "400px",
      },
    },
    [
      _c(
        "div",
        {
          ref: "vuemodal",
          staticClass: "modal-content border-bottom-0",
          attrs: { id: "myModal" },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between" }, [
                _vm.modal_type === "scan_item_tool"
                  ? _c("h4", [_vm._v("Scan Tool")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "input_qty"
                  ? _c("h4", [_vm._v("Receiving Details")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "scan_location"
                  ? _c("h4", [_vm._v("Scan Location")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "scan_serial_codes"
                  ? _c("h4", [_vm._v("Scan Serial Numbers")])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.modal_type === "scan_item_tool"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form",
                    attrs: { id: "form", name: "form" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-body justify-content-between" },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "text-align-all": "center",
                              "align-self": "center",
                              "align-content": "center",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input_scan,
                                  expression: "input_scan",
                                },
                              ],
                              staticClass: "form-control-lg text-center",
                              attrs: {
                                type: "text",
                                name: "scan_item",
                                id: "scan_item",
                                placeholder: "Scan Item",
                                autofocus: "",
                              },
                              domProps: { value: _vm.input_scan },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.submit(_vm.input_scan, _vm.index)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.input_scan = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: { color: "red" },
                                attrs: { hidden: !_vm.input_empty },
                              },
                              [
                                _vm._v(
                                  "Tool identifier or UPC not found in Purchase Order."
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "receiving-scan-tool-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.submit(_vm.input_scan, _vm.index)
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "input_qty"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form2",
                    attrs: { id: "form2", name: "form2" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit_qty.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                        _c("h5", { staticStyle: { color: "#f89b24" } }, [
                          _vm._v(
                            "Tool Name: " + _vm._s(_vm.scanned_product.name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _c("b", [_vm._v("Order Qty:")]),
                          _vm._v(
                            " " + _vm._s(_vm.scanned_product.order_qty) + " / "
                          ),
                          _c("b", [_vm._v("Remaining Qty:")]),
                          _vm._v(
                            " " + _vm._s(_vm.scanned_product.remaining_qty)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "text-align-all": "center",
                            "align-self": "center",
                            "align-content": "center",
                            "align-items": "center",
                            "margin-top": "4px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-md-12 justify-content-between",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.qty_received,
                                        expression: "qty_received",
                                      },
                                    ],
                                    staticClass:
                                      "text-center form-control-lg mb-2",
                                    attrs: {
                                      disabled: "",
                                      autofocus: "",
                                      oninput: "validity.valid||(value='')",
                                      min: "0",
                                      type: "number",
                                      name: "order_qty1",
                                      id: "order_qty1",
                                      placeholder: "Qty. Received",
                                    },
                                    domProps: { value: _vm.qty_received },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.qty_received = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;",
                                      },
                                    },
                                    [_vm._v("1")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;",
                                      },
                                    },
                                    [_vm._v("2")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;",
                                      },
                                    },
                                    [_vm._v("3")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';",
                                      },
                                    },
                                    [_vm._v("4")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';",
                                      },
                                    },
                                    [_vm._v("5")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';",
                                      },
                                    },
                                    [_vm._v("6")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';",
                                      },
                                    },
                                    [_vm._v("7")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';",
                                      },
                                    },
                                    [_vm._v("8")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';",
                                      },
                                    },
                                    [_vm._v("9")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=null",
                                      },
                                    },
                                    [_vm._v("C")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';",
                                      },
                                    },
                                    [_vm._v("0")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);",
                                      },
                                    },
                                    [_vm._v("<")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm.qty_error
                          ? _c("p", { staticStyle: { color: "red" } }, [
                              _vm._v("Please, input a valid quantity value."),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "receiving-scan-tool-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: { click: _vm.submit_qty },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "scan_location"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form3",
                    attrs: { id: "form3", name: "form3" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit_location.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-body justify-content-between" },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c("h5", { staticStyle: { color: "#f89b24" } }, [
                              _vm._v(
                                "Tool Name: " + _vm._s(_vm.scanned_product.name)
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "text-align-all": "center",
                              "align-self": "center",
                              "align-content": "center",
                              "align-items": "center",
                              "margin-top": "4px",
                            },
                          },
                          [
                            _c("div", [
                              _vm.scanned_product.same_warehouse
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.input_scan_location,
                                        expression: "input_scan_location",
                                      },
                                    ],
                                    staticClass: "form-control-lg text-center",
                                    attrs: {
                                      type: "text",
                                      name: "scan_location",
                                      id: "scan_location",
                                      placeholder: "Location",
                                      autofocus: "",
                                    },
                                    domProps: {
                                      value: _vm.input_scan_location,
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit_location(
                                          _vm.input_scan_location,
                                          _vm.index
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.input_scan_location =
                                          $event.target.value
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.scanned_product.same_warehouse &&
                              _vm.scanned_product.scan_serials
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.input_scan_location,
                                        expression: "input_scan_location",
                                      },
                                    ],
                                    staticClass: "form-control-lg text-center",
                                    attrs: {
                                      type: "text",
                                      name: "scan_location",
                                      id: "scan_location",
                                      placeholder: "Location",
                                      autofocus: "",
                                    },
                                    domProps: {
                                      value: _vm.input_scan_location,
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submit_location_dif_whs(
                                          _vm.input_scan_location,
                                          _vm.index
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.input_scan_location =
                                          $event.target.value
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.scanned_product.same_warehouse &&
                              !_vm.scanned_product.scan_serials
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.input_scan_location,
                                        expression: "input_scan_location",
                                      },
                                    ],
                                    staticClass: "form-control-lg text-center",
                                    attrs: {
                                      type: "text",
                                      name: "scan_location",
                                      id: "scan_location",
                                      placeholder: "Location",
                                      autofocus: "",
                                    },
                                    domProps: {
                                      value: _vm.input_scan_location,
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.input_scan_location =
                                          $event.target.value
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { hidden: !_vm.input_empty_location },
                                },
                                [_vm._v("Location not found for scanned item.")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "receiving-scan-item-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _vm.scanned_product.same_warehouse
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.submit_location(
                                    _vm.input_scan_location,
                                    _vm.index
                                  )
                                },
                              },
                            },
                            [_vm._v("Submit")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.scanned_product.same_warehouse &&
                      _vm.scanned_product.scan_serials
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.submit_location_dif_whs(
                                    _vm.input_scan_location,
                                    _vm.index
                                  )
                                },
                              },
                            },
                            [_vm._v("Submit")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.scanned_product.same_warehouse &&
                      !_vm.scanned_product.scan_serials
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.submit_location_dif_whs(
                                    _vm.input_scan_location,
                                    _vm.index
                                  )
                                  _vm.next_item = false
                                },
                              },
                            },
                            [_vm._v("Add Next Location")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.scanned_product.same_warehouse &&
                      !_vm.scanned_product.scan_serials
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.submit_location_dif_whs(
                                    _vm.input_scan_location,
                                    _vm.index
                                  )
                                  _vm.next_item = true
                                },
                              },
                            },
                            [_vm._v("Scan Next Item")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "scan_serial_codes"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "modal-header justify-content-between" },
                  [
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "h5",
                          {
                            staticClass: "modal-title",
                            staticStyle: { color: "#f89b24" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.scanned_product.tool_identifier +
                                  " (" +
                                  _vm.scanned_product.name +
                                  ")"
                              )
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("p", { staticClass: "text-right" }, [
                          _c("b", [_vm._v("Serials:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.scanned_product.serial_codes.length) +
                              "/" +
                              _vm._s(
                                _vm.scanned_product.qty_received === ""
                                  ? "0"
                                  : _vm.scanned_product.qty_received
                              )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm.item_warehouse !== ""
                          ? _c("p", [
                              _c("b", [_vm._v("WHS: ")]),
                              _vm._v(_vm._s(_vm.item_warehouse) + " / "),
                              _c("b", [_vm._v("ZN: ")]),
                              _vm._v(_vm._s(_vm.item_zone) + " / "),
                              _c("b", [_vm._v("LOC: ")]),
                              _vm._v(_vm._s(_vm.item_location)),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-3 row" }, [
                        _c(
                          "h6",
                          {
                            staticClass: "mr-2 p-2",
                            class: { "modal-serial-selected": _vm.isInput },
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.changeScreen("input")
                              },
                            },
                          },
                          [_vm._v("Input Serial Number")]
                        ),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "p-2",
                            class: { "modal-serial-selected": _vm.isList },
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.changeScreen("list")
                              },
                            },
                          },
                          [_vm._v("List of Serial Numbers")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    ref: "form4",
                    attrs: { id: "form4", name: "form4" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.continueScan.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _vm.screen == "input"
                        ? _c("div", { staticClass: "form-group" }, [
                            _vm.scanned_product.same_warehouse
                              ? _c("div", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.serial,
                                        expression: "serial",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      autofocus: "",
                                      autocomplete: "off",
                                      type: "text",
                                      rows: "4",
                                      name: "serial_number",
                                      id: "serial_number",
                                      placeholder: "Input Serial Numbers",
                                    },
                                    domProps: { value: _vm.serial },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        return _vm.addSerial.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.serial = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.screen === "input"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-outline-primary mt-2",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addSerial },
                                        },
                                        [_vm._v("Add S/N")]
                                      )
                                    : _vm._e(),
                                ])
                              : !_vm.scanned_product.same_warehouse
                              ? _c("div", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.serial,
                                        expression: "serial",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      autofocus: "",
                                      autocomplete: "off",
                                      type: "text",
                                      rows: "4",
                                      name: "serial_number1",
                                      id: "serial_number1",
                                      placeholder: "Input Serial Numbers",
                                    },
                                    domProps: { value: _vm.serial },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        return _vm.addSerial_dif_whs.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.serial = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.screen === "input"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-outline-primary mt-2",
                                          attrs: { type: "button" },
                                          on: { click: _vm.addSerial_dif_whs },
                                        },
                                        [_vm._v("Add S/N")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: " mt-4 alert alert-warning",
                                attrs: { role: "alert" },
                              },
                              [
                                _vm._v(
                                  "You can enter multiple serial numbers separated by comma."
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.screen == "list"
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "table-responsive pt-3" },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-bordered table-hover date-columns",
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                                        Serial Codes\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", [
                                          _vm._v(
                                            "\n                                        In Stock\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", [
                                          _vm._v(
                                            "\n                                        Action\n                                    "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm.scanned_product.same_warehouse
                                      ? _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.scanned_product.serial_codes,
                                            function (serial, index) {
                                              return _c("tr", [
                                                _c("td", [
                                                  _vm._v(_vm._s(serial.sn)),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  _vm._l(
                                                    _vm.serialsInventoryCodes,
                                                    function (valuer, i) {
                                                      return _c("div", [
                                                        serial.sn ==
                                                        valuer.serial_code
                                                          ? _c("p", [
                                                              _vm._v("Yes"),
                                                            ])
                                                          : _vm._e(),
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSerial(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Delete")]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : !_vm.scanned_product.same_warehouse
                                      ? _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.scanned_product.serial_codes.filter(
                                              function (serial_item) {
                                                return (
                                                  serial_item.warehouse_index ==
                                                  _vm.scanned_product
                                                    .warehouses_allocation
                                                    .length -
                                                    1
                                                )
                                              }
                                            ),
                                            function (serial, index) {
                                              return _c("tr", [
                                                _c("td", [
                                                  _vm._v(_vm._s(serial.sn)),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  _vm._l(
                                                    _vm.serialsInventoryCodes,
                                                    function (valuer, i) {
                                                      return _c("div", [
                                                        serial.sn ==
                                                        valuer.serial_code
                                                          ? _c("p", [
                                                              _vm._v("Yes"),
                                                            ])
                                                          : _vm._e(),
                                                      ])
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSerial_dif_whs(
                                                              serial.sn
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Delete")]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.scanned_product.same_warehouse
                      ? _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "p",
                            {
                              staticStyle: { color: "red" },
                              attrs: { hidden: !_vm.incomplete_serials },
                            },
                            [
                              _vm._v(
                                "Please, input all received serial numbers for this item."
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.$modal.hide(
                                    "receiving-scan-tool-modal-" + _vm.index
                                  ),
                                    _vm.clear_all()
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _vm._v(" "),
                          _vm.screen === "input"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mr-2",
                                  attrs: { type: "button" },
                                  on: { click: _vm.continueScan },
                                },
                                [_vm._v("Scan New Item")]
                              )
                            : _vm._e(),
                        ])
                      : !_vm.scanned_product.same_warehouse
                      ? _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "p",
                            {
                              staticStyle: { color: "red" },
                              attrs: { hidden: !_vm.incomplete_serials },
                            },
                            [
                              _vm._v(
                                "Please, input all received serial numbers for this item."
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  _vm.$modal.hide(
                                    "receiving-scan-tool-modal-" + _vm.index
                                  ),
                                    _vm.clear_all()
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _vm._v(" "),
                          _vm.screen === "input" &&
                          _vm.scanned_product.serial_codes.length <
                            _vm.scanned_product.qty_received
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-primary mr-2",
                                  attrs: { type: "button" },
                                  on: { click: _vm.continueScan },
                                },
                                [_vm._v("Add Next Location")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.screen === "input" &&
                          _vm.scanned_product.serial_codes.length ==
                            _vm.scanned_product.qty_received
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-primary mr-2",
                                  attrs: { type: "button" },
                                  on: { click: _vm.continueScan },
                                },
                                [_vm._v("Scan Next Item")]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }