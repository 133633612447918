var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "cycle-reconciliation-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Add Product from Inventory"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c("b", [_vm._v("Select Product:")]),
                    _vm._v(" "),
                    _c(
                      "dropbox-reconciliation",
                      {
                        attrs: {
                          name: "product_id",
                          id: "product_id",
                          options: _vm.inventory_products,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeProductToReconcile()
                          },
                        },
                        model: {
                          value: _vm.form.new_product_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "new_product_id", $$v)
                          },
                          expression: "form.new_product_id",
                        },
                      },
                      [_c("option")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("b", [
                    _vm._v("Quantity:"),
                    _c("span", { staticClass: "required_asterisk" }, [
                      _vm._v("*"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.quantity_to_add,
                        expression: "form.quantity_to_add",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      min: 0,
                      name: "reconciliation_number",
                      placeholder: "##",
                    },
                    domProps: { value: _vm.form.quantity_to_add },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "quantity_to_add",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "div",
                    { staticClass: "row ml-2 mr-2 mt-2" },
                    [
                      _c("b", [_vm._v("Select Warehouse:")]),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "warehouse_id",
                            id: "warehouse_id",
                            options: _vm.warehouses,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.resetZoneLocation(
                                _vm.form.new_warehouse_id
                              )
                            },
                          },
                          model: {
                            value: _vm.form.new_warehouse_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "new_warehouse_id", $$v)
                            },
                            expression: "form.new_warehouse_id",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.new_warehouse_id !== ""
                    ? _c(
                        "div",
                        { staticClass: "row ml-2 mr-2 mt-1" },
                        [
                          _c("b", [_vm._v("Select Zone")]),
                          _vm._v(" "),
                          _c(
                            "dropbox",
                            {
                              attrs: {
                                name: "zone_id",
                                id: "zone_id",
                                options: _vm.zones,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeLocation(
                                    _vm.form.new_zone_id
                                  )
                                },
                              },
                              model: {
                                value: _vm.form.new_zone_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "new_zone_id", $$v)
                                },
                                expression: "form.new_zone_id",
                              },
                            },
                            [_c("option")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.new_zone_id !== "" &&
                  _vm.form.new_warehouse_id !== ""
                    ? _c(
                        "div",
                        { staticClass: "row ml-2 mr-2 mt-1" },
                        [
                          _c("b", [_vm._v("Select Location")]),
                          _vm._v(" "),
                          _c(
                            "dropbox",
                            {
                              attrs: {
                                name: "location_id",
                                id: "location_id",
                                options: _vm.locations,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeLocationProducts()
                                },
                              },
                              model: {
                                value: _vm.form.new_location_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "new_location_id", $$v)
                                },
                                expression: "form.new_location_id",
                              },
                            },
                            [_c("option")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.$props.is_admin
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "row ml-2 mb-3 mt-3" }, [
                        _c("h6", [
                          _vm._v(
                            "In Stock:\n                                    "
                          ),
                          _vm.form.new_product_id != "" &&
                          _vm.form.new_warehouse_id != ""
                            ? _c("b", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.new_products_array != null
                                        ? _vm.new_products_array.quantity
                                        : 0
                                    )
                                  ),
                                ]),
                              ])
                            : _vm.form.new_product_id != ""
                            ? _c("b", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$props.inventory_products.find(
                                        function (ip) {
                                          return (
                                            ip.id == _vm.form.new_product_id
                                          )
                                        }
                                      ).quantity
                                    )
                                  ),
                                ]),
                              ])
                            : _c("b"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row ml-2" }, [
                        _c("h6", [
                          _vm._v(
                            "Variance:\n                                    "
                          ),
                          _vm.form.new_product_id != "" &&
                          _vm.form.new_warehouse_id != ""
                            ? _c("b", [
                                _vm.new_products_array != null &&
                                _vm.form.quantity_to_add != "" &&
                                _vm.form.quantity_to_add <
                                  _vm.new_products_array.quantity
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            "-" +
                                              Math.abs(
                                                _vm.new_products_array
                                                  .quantity -
                                                  _vm.form.quantity_to_add
                                              )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ])
                                  : _vm.new_products_array != null &&
                                    _vm.form.quantity_to_add != "" &&
                                    _vm.form.quantity_to_add >=
                                      _vm.new_products_array.quantity
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            "+" +
                                              Math.abs(
                                                _vm.new_products_array
                                                  .quantity -
                                                  _vm.form.quantity_to_add
                                              )
                                          ) +
                                          "\n                                        "
                                      ),
                                    ])
                                  : _vm.form.quantity_to_add != ""
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(
                                            "+" + _vm.form.quantity_to_add
                                          ) +
                                          "\n                                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _c("p"),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  _vm.$modal.hide("cycle-reconciliation-modal"),
                    _vm.closeModal()
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.addToIn()
                },
              },
            },
            [_vm._v("Add Product")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }