var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h6", { staticClass: "card-title" }, [_vm._v("Inventory Bin to Bin")]),
      _vm._v(" "),
      _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
        _vm._v("Transfer Inventory"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-responsive pt-3" },
        [
          _c(
            "table",
            { staticClass: "table table-bordered table-hover data-table" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(this.$props.transfer_inventory, function (item, index) {
                  return _c("tr", [
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.product_name) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.product_sku) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.warehouse_name) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.zone_name) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.location_name) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.quantity) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    item.use_serials > 0
                      ? _c("td", [
                          _vm._v(
                            "\n                        Yes\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.use_serials <= 0
                      ? _c("td", [
                          _vm._v(
                            "\n                        No\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.use_serials > 0
                      ? _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.startTransfer(-1, item)
                                },
                              },
                            },
                            [
                              _c("log-out-icon", {
                                staticStyle: { color: "white" },
                                attrs: { size: "1x" },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.use_serials <= 0
                      ? _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mr-2",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.startTransfer(0, item)
                                },
                              },
                            },
                            [
                              _c("log-out-icon", {
                                staticStyle: { color: "white" },
                                attrs: { size: "1x" },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c("inventory-transfer-bin-to-bin-modal", {
            attrs: {
              index: 0,
              item: _vm.item_to_transfer,
              warehouses: _vm.warehouses,
              zones: _vm.zones,
              locations: _vm.locations,
              flag: _vm.flag,
            },
          }),
          _vm._v(" "),
          _c("inventory-transfer-bin-to-bin-modal", {
            attrs: {
              index: -1,
              item: _vm.item_to_transfer,
              warehouses: _vm.warehouses,
              zones: _vm.zones,
              locations: _vm.locations,
              flag: _vm.flag,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v("\n                        Product\n                    "),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v("\n                        SKU\n                    "),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v("\n                        Warehouse\n                    "),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v("\n                        Zone\n                    "),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v("\n                        Location\n                    "),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                        Quantity in Stock\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                        Has Serial Numbers?\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v("\n                        Transfer\n                    "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }