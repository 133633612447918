var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "po-form",
        attrs: { id: "po-form", name: "po-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6 grid-margin stretch-card" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h6", { staticClass: "card-title mb-4" }, [
                  _vm._v("Create New PO"),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "mb-2" }, [_vm._v("PO Details")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.po_number,
                        expression: "form.po_number",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      name: "po_number",
                      id: "po_number",
                      placeholder: "Purchase Order #",
                    },
                    domProps: { value: _vm.form.po_number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "po_number", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "dropbox",
                      {
                        attrs: {
                          name: "supplier_id",
                          id: "supplier_id",
                          options: this.suppliers,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.loadProductsTools()
                          },
                        },
                        model: {
                          value: _vm.form.supplier_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supplier_id", $$v)
                          },
                          expression: "form.supplier_id",
                        },
                      },
                      [_c("option")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "quote" } }, [
                    _vm._v("Quote Number"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.quote,
                        expression: "form.quote",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      autocomplete: "off",
                      type: "text",
                      name: "quote",
                      id: "quote",
                      placeholder: "####",
                    },
                    domProps: { value: _vm.form.quote },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "quote", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex",
                    },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("datePicker", {
                        attrs: {
                          autocomplete: "off",
                          config: _vm.datePickerOptions,
                          name: "po_date",
                        },
                        model: {
                          value: _vm.form.po_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "po_date", $$v)
                          },
                          expression: "form.po_date",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex",
                    },
                    [
                      _vm._m(5),
                      _vm._v(" "),
                      _c("datePicker", {
                        attrs: {
                          autocomplete: "off",
                          config: _vm.datePickerOptions,
                          name: "req_delivery_date",
                        },
                        model: {
                          value: _vm.form.req_delivery_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "req_delivery_date", $$v)
                          },
                          expression: "form.req_delivery_date",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-2 mt-2" }, [
                  _c("h6", { staticClass: "mb-2" }, [_vm._v("Order Details")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("div", { staticClass: "row ml-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-check form-check-inline" },
                        [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.order,
                                  expression: "form.order",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "radio",
                                name: "optionsRadios5",
                                id: "optionsRadios5",
                                value: "Tool",
                              },
                              domProps: {
                                checked: _vm._q(_vm.form.order, "Tool"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.form, "order", "Tool")
                                },
                              },
                            }),
                            _vm._v(
                              "\n                                                Tool\n                                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-check form-check-inline" },
                        [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.order,
                                  expression: "form.order",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "radio",
                                name: "product",
                                id: "optionsRadios6",
                                value: "Product",
                              },
                              domProps: {
                                checked: _vm._q(_vm.form.order, "Product"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(_vm.form, "order", "Product")
                                },
                              },
                            }),
                            _vm._v(
                              "\n                                                Product\n                                            "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  this.form.order === "Product"
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._m(7),
                            _vm._v(" "),
                            _c(
                              "dropbox",
                              {
                                attrs: {
                                  name: "name",
                                  id: "name",
                                  options: this.dropdown_products,
                                },
                                on: { change: _vm.loadProductInfo },
                                model: {
                                  value: _vm.product.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.product, "id", $$v)
                                  },
                                  expression: "product.id",
                                },
                              },
                              [_c("option")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "product_id" } }, [
                            _vm._v("Product ID"),
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(this.product.sku))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "sku" } }, [
                            _vm._v("SKU"),
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(this.product.inventory_sku))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(8),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(this.product.description))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(9),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.product.order_qty,
                                expression: "product.order_qty",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              oninput: "validity.valid||(value='')",
                              min: "0",
                              name: "product",
                              id: "order_qty",
                              placeholder: "####",
                            },
                            domProps: { value: _vm.product.order_qty },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.product,
                                  "order_qty",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(10),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.product.price,
                                expression: "product.price",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              oninput: "validity.valid||(value='')",
                              min: "0",
                              step: "0.00001",
                              name: "product",
                              id: "price",
                              placeholder: "$##.#####",
                            },
                            domProps: { value: _vm.product.price },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.product,
                                  "price",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-2 mb-4",
                            attrs: { type: "button" },
                            on: { click: _vm.addProduct },
                          },
                          [_vm._v("Add Product")]
                        ),
                        _vm._v(" "),
                        this.form.products.length > 0
                          ? _c("div", { staticClass: "mb-4" }, [
                              _c("h6", { staticClass: "mb-2" }, [
                                _vm._v("Products List"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-hover date-columns",
                                  attrs: { id: "inventory-table" },
                                },
                                [
                                  _vm._m(11),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.form.products,
                                      function (product, index) {
                                        return _c("tr", [
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editProduct(
                                                      product.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("edit-icon", {
                                                  attrs: { size: "1.5x" },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeProduct(
                                                          product.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("trash-icon", {
                                                      attrs: { size: "1.5x" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    product.inventory_sku
                                                  ) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(product.name) +
                                                "\n                                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(product.description) +
                                                "\n                                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(product.order_qty) +
                                                "\n                                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(product.price) +
                                                "\n                                                "
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div"),
                  _vm._v(" "),
                  this.form.order === "Tool"
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._m(12),
                            _vm._v(" "),
                            _c(
                              "dropbox",
                              {
                                attrs: {
                                  name: "name",
                                  id: "name",
                                  options: this.dropdown_tools_categories,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.loadBrands()
                                  },
                                },
                                model: {
                                  value: _vm.tool.category_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tool, "category_id", $$v)
                                  },
                                  expression: "tool.category_id",
                                },
                              },
                              [_c("option")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._m(13),
                            _vm._v(" "),
                            _c(
                              "dropbox",
                              {
                                attrs: {
                                  name: "brand",
                                  id: "brand",
                                  options: this.dropdown_tools_brand,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.loadModels()
                                  },
                                },
                                model: {
                                  value: _vm.tool.brand_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tool, "brand_id", $$v)
                                  },
                                  expression: "tool.brand_id",
                                },
                              },
                              [_c("option")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _vm._m(14),
                            _vm._v(" "),
                            _c(
                              "select-tool-model",
                              {
                                attrs: {
                                  name: "model",
                                  id: "model",
                                  options: this.dropdown_tools_model,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.loadToolInfo()
                                  },
                                },
                                model: {
                                  value: _vm.tool.id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tool, "id", $$v)
                                  },
                                  expression: "tool.id",
                                },
                              },
                              [_c("option")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(15),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(this.tool.description))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(16),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tool.order_qty,
                                expression: "tool.order_qty",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              oninput: "validity.valid||(value='')",
                              min: "0",
                              name: "product",
                              id: "order_qty",
                              placeholder: "####",
                            },
                            domProps: { value: _vm.tool.order_qty },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.tool,
                                  "order_qty",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(17),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tool.price,
                                expression: "tool.price",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              oninput: "validity.valid||(value='')",
                              min: "0",
                              step: "0.0001",
                              name: "product",
                              id: "price",
                              placeholder: "$##.####",
                            },
                            domProps: { value: _vm.tool.price },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.tool, "price", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-2 mb-4",
                            attrs: { type: "button" },
                            on: { click: _vm.addTool },
                          },
                          [_vm._v("Add Tool")]
                        ),
                        _vm._v(" "),
                        this.form.tools.length > 0
                          ? _c("div", { staticClass: "mb-4" }, [
                              _c("h6", { staticClass: "mb-2" }, [
                                _vm._v("Tools List"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-hover date-columns",
                                  attrs: { id: "tools-table" },
                                },
                                [
                                  _vm._m(18),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.form.tools,
                                      function (tool, index) {
                                        return _c("tr", [
                                          _c("td", [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editTool(
                                                        tool.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("edit-icon", {
                                                    attrs: { size: "1.5x" },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeTool(
                                                        tool.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("trash-icon", {
                                                    attrs: { size: "1.5x" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "10%" } },
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(tool.category) +
                                                  "\n                                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(tool.brand) +
                                                "\n                                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(tool.model) +
                                                "\n                                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(tool.order_qty) +
                                                "\n                                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(tool.price) +
                                                "\n                                                "
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "mb-2" }, [
                  _vm._v("Additional Information"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "comments" } }, [
                    _vm._v("Comments"),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.comments,
                        expression: "form.comments",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "comments",
                      name: "comments",
                      placeholder: "Comments",
                      rows: "5",
                    },
                    domProps: { value: _vm.form.comments },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "comments", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "documents" } }, [
                    _vm._v("Documentation"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "documents",
                    staticClass: "border",
                    attrs: {
                      type: "file",
                      multiple: "",
                      name: "product",
                      id: "documents",
                    },
                    on: { change: _vm.updateDocuments },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { disabled: _vm.submit_disabled, type: "submit" },
                  },
                  [_vm._v("Submit")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "btn btn-light", attrs: { href: "/orders" } },
                  [_vm._v("Cancel")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "po_number" } }, [
      _vm._v("PO Number"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "supplier_id" } }, [
      _vm._v("Supplier"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "po_number" } }, [
      _vm._v("PO Date"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon bg-transparent" }, [
      _c("i", {
        staticClass: " text-primary",
        attrs: { "data-feather": "calendar" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "req_delivery_date" } }, [
      _vm._v("Request Delivery Date"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon bg-transparent" }, [
      _c("i", {
        staticClass: " text-primary",
        attrs: { "data-feather": "calendar" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "order" } }, [
      _vm._v("Order: "),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "name" } }, [
      _vm._v("Product"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "description" } }, [
      _vm._v("Description"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "order_qty" } }, [
      _vm._v("Order Qty"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "price" } }, [
      _vm._v("Price"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [
          _vm._v(
            "\n                                                    SKU\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Product\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Description\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Qty\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Price\n                                                "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "tool" } }, [
      _vm._v("Category"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "brand" } }, [
      _vm._v("Brand Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "model" } }, [
      _vm._v("Model"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "description" } }, [
      _vm._v("Description"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "order_qty" } }, [
      _vm._v("Order Qty"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "price" } }, [
      _vm._v("Price"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Category\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Brand Name\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Model\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Qty\n                                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                                    Price\n                                                "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }