<template>
    <div>
        <form @submit.prevent="submit" id="po-form" name="po-form" :ref="'po-form'">
            <div class="row col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title"><p>Picking List</p></div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="table-responsive pt-3">
                                <div>
                                    <div class="row col-md-6" style="float: right; display: inline-block">
                                        <div class="mb-2" style="float: right">
                                            <input type="text" class="form-control" name="filter_sku" id="filter_sku" placeholder="Search" autofocus v-model="filter" v-on:click="flag2=!flag2">
                                        </div>
                                        <div class="mb-2 mt-1" style="float: right">
                                            <button type="button" class="btn btn-primary mr-2" v-on:click="scanItem()">Scan Item</button>
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-hover date-columns" id="new-projects">
                                        <thead>
                                        <tr>
                                            <th style="width: 10%;">
                                                SKU
                                            </th>
                                            <th>
                                                Product
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                Zone
                                            </th>
                                            <th style="width: 10%;">
                                                Amount
                                            </th>
                                            <th style="width: 10%;">
                                                Quantity
                                            </th>
                                            <th>
                                                Comments
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(product,index) in filteredRows" :key="index">
                                            <td style="width: 10%;">
                                                {{product.sku}}
                                            </td>
                                            <td style="width: 15%">
                                                {{product.name}}
                                            </td>
                                            <td style="width: 15%">
                                                {{product.description}}
                                            </td>
                                            <td style="width: 10%; color:blue" class="cursor-pointer" v-on:click="openChangeZoneLocationModal(product)">
                                                {{product.zone}} - {{product.location}}
                                            </td>
                                            <td style="width: 10%;">
                                                {{product.quantity}}
                                            </td>
                                            <td style="width: 10%;">
                                                <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity"  placeholder="####" v-model="product.quantity_picked">
                                            </td>
                                            <td style="width: 10%;">
                                                <dropbox class="form-control" name="comment" id="comment"  :options="comment_options" v-model="product.comments">
                                                    <option></option>
                                                </dropbox>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5" v-if="form.products.filter(product => product.use_serials).length > 0">
                            <h6>Serial Numbers</h6>
                        </div>
                        <div class="row mt-2" v-for="(product,index) in form.products.filter(product => product.use_serials)" :key="index">
                            <p class="col-md-12">{{product.name + ' | Zone: ' + product.zone + ' - Location: ' + product.location  }}</p>
                            <div class="col-md-12">
                                <p type="button" style="color: #007bff" @click="$modal.show(index+'-serial-codes-modal') ; $forceUpdate()">Add or Edit Codes</p>
                                <serial-codes-modal :ref="index+'serial-code-modal'" :product="product" :index="index" v-bind:quantity="product.quantity_picked"></serial-codes-modal>
                            </div>
                        </div>
                        <div class="spinner-border text-primary my-3" v-if="flag_loading" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="mt-4">
                            <form @submit.prevent="submit" name="project-installation-form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="status">Dispatched?<span class="required_asterisk">*</span></label>
                                            <div class="row ml-2">
                                                <div class="form-check form-check-inline">
                                                    <label for="status" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="status" value="Dispatched" :checked="form.status == 'Dispatched'" v-model="form.status">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <label for="status" class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="status" value="Not Dispatched" :checked="form.status == 'Not Dispatched'" v-model="form.status">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2 my-4" v-if="form.status == 'Dispatched'">
                                            <h6>I hereby confirm that the items from the project were successfully received in order to be installed.</h6>
                                            <div class="m-signature-pad my-4" style="border: 2px dashed rgb(204, 204, 204);">
                                                <VueSignaturePad width="498px" height="158px" ref="signaturePad" class="signature-pad--body"/>
                                            </div>
                                            <div>
                                                <button type="button" class="btn btn-sm btn-secondary" @click="clearSignature">Clear</button>
                                                <button type="button" class="btn btn-sm btn-primary" @click="saveSignature">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="row mt-4">
                            <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2">Submit</button>
                            <button type="button" class="btn btn-primary mr-2" @click="savePicking">Save Picking</button>
                            <a href="/projects/picking" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <change-zone-location-modal :detail="detail" :zones="zones" :locations="locations" :products="form.products"></change-zone-location-modal>
        </form>
        <picking-scan-item-modal
            :index = "'picking'"
            :products = "form.products"
            :flag = "flag"
            :locations = "locations"
            :zones = "zones"
            :filter_sku_input = "filter_sku_input"
            :parent = "this"
            :filteredRows = "filteredRows"
            :real_filter = "this.filter"
            :picking = "this.$props.picking"
            :scan_warehouses = "this.scan_warehouses"
            :scan_zones = "this.scan_zones"
            :scan_locations = "this.scan_locations"
            :detail = "detail"
        ></picking-scan-item-modal>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import VueSignaturePad from 'vue-signature-pad';
import PickingScanItemModal from "./PickingScanItemModal";
Vue.use(VueSignaturePad);
export default {
    name: "ProjectPickingComponent",
    props: ['picking', 'temp_serial_codes', 'scan_warehouses', 'scan_locations', 'scan_zones'],
    components: {
        PickingScanItemModal,
    },
    beforeMount(){
        this.form.details = this.$props.picking.project.bom.details
            .filter(detail => detail.warehouse_id === this.$props.picking.warehouse_id && detail.zone_id === this.$props.picking.zone_id);

        this.form.details.forEach(detail =>{
            if(this.$props.picking.active === 1){
                if (detail.locations.length > 0) {
                    detail.locations.forEach(location => {
                        let serials_array = [];
                        let serials = this.$props.temp_serial_codes.filter(sr=> (sr.details_id === detail.id) && (sr.product_id === detail.product_id));
                        serials.forEach(sr=>{
                            serials_array.push({sn:sr.serial_code, warehouse_index:0});
                        })
                        location['qty_received'] = location.quantity
                        this.form.products.push({
                            type: 'DetailLocation',
                            id: location.id,
                            parent_id: detail.id,
                            sku: detail.product.sku,
                            upc: detail.product.upc,
                            name: detail.product.name,
                            description: detail.product.description,
                            zone: location.zone != null ? location.zone.name : (this.$props.picking.zone != null ? this.$props.picking.zone.name : null),
                            zone_id: location.zone_id,
                            location: location.location != null ? location.location.name : null,
                            location_id: location.location_id,
                            quantity: location.quantity,
                            quantity_picked: location.temp_qty_picked,
                            serial_codes: serials_array,//[{sn:"555", warehouse_index:0}],//this.$props.temp_serial_codes.filter(sr=> sr.detail_id === detail.id),
                            use_serials: detail.product.use_serials,
                            comments: detail.comments,
                            product_id: detail.product_id
                        })
                    })
                } else {
                    let serials_array = [];
                    let serials = this.$props.temp_serial_codes.filter(sr=> (sr.details_id === detail.id) && (sr.product_id === detail.product_id));
                    serials.forEach(sr=>{
                        serials_array.push({sn:sr.serial_code, warehouse_index:0});
                    })
                    this.form.products.push({
                        type: 'Detail',
                        id: detail.id,
                        parent_id: detail.id,
                        sku: detail.product.sku,
                        upc: detail.product.upc,
                        name: detail.product.name,
                        description: detail.product.description,
                        zone: null,
                        zone_id: null,
                        location: null,
                        location_id: null,
                        quantity: detail.quantity,
                        quantity_picked: detail.temp_qty_picked,
                        serial_codes: serials_array,
                        use_serials: detail.product.use_serials,
                        comments: detail.comments,
                        product_id: detail.product.id
                    })
                }
            }else {
                if (detail.locations.length > 0) {
                    detail.locations.forEach(location => {
                        location['qty_received'] = location.quantity

                        this.form.products.push({
                            type: 'DetailLocation',
                            id: location.id,
                            parent_id: detail.id,
                            sku: detail.product.sku,
                            upc: detail.product.upc,
                            name: detail.product.name,
                            description: detail.product.description,
                            zone: location.zone != null ? location.zone.name : (this.$props.picking.zone != null ? this.$props.picking.zone.name : null),
                            zone_id: location.zone_id,
                            location: location.location != null ? location.location.name : null,
                            location_id: location.location_id,
                            quantity: location.quantity,
                            quantity_picked: '0', //location.quantity,
                            serial_codes: [],
                            use_serials: detail.product.use_serials,
                            comments: '',
                            product_id: detail.product_id
                        })
                    })
                } else {
                    this.form.products.push({
                        type: 'Detail',
                        id: detail.id,
                        parent_id: detail.id,
                        sku: detail.product.sku,
                        upc: detail.product.upc,
                        name: detail.product.name,
                        description: detail.product.description,
                        zone: null,
                        zone_id: null,
                        location: null,
                        location_id: null,
                        quantity: detail.quantity,
                        quantity_picked: '0', //detail.quantity,
                        serial_codes: [],
                        use_serials: detail.product.use_serials,
                        comments: '',
                        product_id: detail.product.id
                    })
                }
            }
        });
    },
    data(){
        return{
            form:{
                products: [],
                details: [],
                warehouse_id: this.$props.picking.warehouse_id,
                status:""
            },
            submit_disabled: false,
            detail: {},
            zones: [],
            locations: [],
            flag_loading: false,
            comment_options:
                [
                    {'id':'dispatched', 'name':'Dispatched'},
                    {'id':'not dispatched', 'name':'Not Dispatched'},
                ],
            filter_sku:'',
            filter_sku_input:'',
            filter:'',
            flag:false,
            flag2:false,
            input_empty:false,
            upc_array:Array
        }
    },
    computed: {
        filteredRows() {
            if (this.filter_sku_input === '' || this.filter !== '') {
                return this.form.products.filter(product => {
                    const sku = product.sku.toString().toLowerCase();
                    const name = product.name.toString().toLowerCase();
                    const description = product.description.toString().toLowerCase();
                    const zone = product.zone != null ? product.zone.toString().toLowerCase() : '';
                    const location = product.location != null ? product.location.toString().toLowerCase() : '';
                    const quantity = product.quantity.toString().toLowerCase();
                    const searchTerm = this.filter.toLowerCase();

                    return sku.includes(searchTerm) ||
                        name.includes(searchTerm) ||
                        description.includes(searchTerm) ||
                        zone.includes(searchTerm) ||
                        location.includes(searchTerm) ||
                        quantity.includes(searchTerm);
                })
            }else{
                return this.form.products.filter(product => {
                    const sku = product.sku.toString().toLowerCase();
                    const searchTerm = this.filter_sku.toLowerCase();
                    let upc_filter = '';
                    if(product.upc != null){
                        this.upc_array = product.upc.split(",");
                        if(this.getMatchingResults(this.upc_array, searchTerm).length > 0){
                            upc_filter = this.getMatchingResults(this.upc_array, searchTerm)[0];
                        }
                    }
                    const upc = upc_filter.toString().toLowerCase();

                    return sku === searchTerm || upc === searchTerm;
                })
            }
        }
    },
    mounted() {
        this.submit_disabled = true;
    },
    methods:{
        submit(){
            let haveQuantity = true;
            let wrongComment = true;
            let diffQuantityComment = false;
            let products = [];
            let serials_success = true;
            let bad_serials_list = '';

            this.submit_disabled = true;
            this.flag_loading = true;

            // WMS-318 Pickings VUE Done
            this.form.products.forEach(product => {

                console.log(product.quantity_picked);
                console.log(product.quantity);

                if(product.quantity_picked.toString() == null || product.quantity_picked.toString() == ""){
                    haveQuantity = false;
                    //console.log('Have Quantity Null: ' + haveQuantity);
                }
                if(product.quantity == 0){
                    haveQuantity = true;
                    //console.log('Have Quantity Cero: ' + haveQuantity);
                }
                if((product.quantity_picked != product.quantity) && (product.comments == null || product.comments == "")){
                    products.push(product.sku)
                    diffQuantityComment = true;
                }
                if(product.use_serials){
                    if(product.quantity_picked != product.serial_codes.length){
                        serials_success = false;
                        bad_serials_list += '(' + product.name + ') ';
                    }
                }
                if(product.comments === 'dispatched' && product.quantity_picked.toString() === '0'){
                    wrongComment = false;
                }
                if(product.comments === 'not dispatched' && product.quantity_picked.toString() !== '0'){
                    wrongComment = false;
                }
            })

            if(!haveQuantity){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Input the quantity for all the products.',
                });
                this.flag_loading = false;
                this.submit_disabled = false;

            }else if(!wrongComment){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'If "Dispatched", quantity cannot be 0. If "Not Dispatched", quantity must be 0.',
                });
                this.flag_loading = false;
                this.submit_disabled = false;
            }else if(diffQuantityComment){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Please select a comment option for difference in qty. picked for the products: " + products.toString(),
                });
                this.flag_loading = false;
                this.submit_disabled = false;

            }else if(!serials_success){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please scan products with Serial Numbers to complete Picking.' + bad_serials_list,
                });
                this.flag_loading = false;
                this.submit_disabled = false;

            }else{
                axios.post('/projects/'+this.$props.picking.project.id+'/pickings/'+this.$props.picking.id,this.form).then(response => {
                    if(response.data.error == 404){

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        });
                        this.flag_loading = false;
                        this.submit_disabled = false;
                    }else{
                        location = response.data.message;
                    }
                }).catch(error =>{
                    if(error.response.status === 403){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'There was a problem.',
                        });
                    }
                    this.flag_loading = false;
                    this.submit_disabled = false;
                });
            }
        },
        getMatchingResults(upc_array, searchTerm ) {
            return upc_array.filter(d => searchTerm.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        check_serials(product_id){
            axios.get('/products/'+product_id+'/serials/').then(response => {
                let product = this.form.products.find(product=> product.id == product_id)
                product.use_serials = response.data.value;
                if(product.use_serials){
                    product['serial_codes'] = [];
                    product['qty_received'] = product.pivot.quantity;
                    product.quantity = product.pivot.quantity;
                }
            }).catch(error =>{
                this.form.products.find(product=> product.id == product_id).use_serials = false

            });
        },
        openChangeZoneLocationModal(detail){
            this.detail = detail;
            this.$forceUpdate();
            let vm = this;
            axios.get('/inventory/products/' + detail.product_id + '/warehouses/' + this.picking.warehouse_id + '/getZonesAndLocations').then(response =>{
                vm.$modal.show('zone-location-change-modal');
                vm.zones = response.data.zones;
            });
        },
        clearSignature(){
            console.log(this.$props.picking);
            this.$refs.signaturePad.undoSignature();
        },
        saveSignature(){
            const {isEmpty,data} = this.$refs.signaturePad.saveSignature();

            let attributes = {
                'image_data': data
            }

            axios.post("/projects/"+ this.$props.picking.id +"/picking/signatures",attributes).then(response =>{
                this.submit_disabled = !(response.data);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Signature was saved.',
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true
                })
            });
        },
        savePicking(){
            axios.post('/projects/'+this.$props.picking.project.id+'/pickings/'+this.$props.picking.id + '/save_picking',this.form).then(response => {
                if(response.data.error == 404){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    });
                    this.flag_loading = false;
                    this.submit_disabled = false;
                }else{
                    location = response.data.message;
                }
            }).catch(error =>{
                if(error.response.status === 403){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'There was a problem.',
                    });
                }
                this.flag_loading = false;
                this.submit_disabled = false;
            });
        },
        scanItem(){
            this.flag = !this.flag;
            this.filter = '';
            this.$modal.show('picking-scan-item-modal');
        },
        submitScan(filter_sku){
            if(this.filter_sku === ''){
                this.filter_sku_input = '';
                this.input_empty = true;
            }else {
                this.filter_sku_input = this.filter_sku;
                if (this.filteredRows.length <= 0) {
                    this.input_empty = true;
                    this.filter_sku_input = '';
                }else {
                    this.input_empty = false;
                    this.$modal.hide('scan-item-modal-7');
                }
            }
        },
        clear_all(){
            console.log('Clear')
            this.filter_sku = '';
            this.filter_sku_input = '';
            this.input_empty = false;
            setTimeout(function(){
                document.getElementById("scan_item_picking").focus();
            },0)
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.input_empty = false;
        },
        flag2: function(){
            this.filter_sku = '';
            this.filter_sku_input = '';
            this.input_empty = false;
        }
    }
}
</script>

<style scoped>

</style>
