var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "receiving-form",
        attrs: { id: "receiving-form", name: "receiving-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h6", { staticClass: "card-title" }, [
                _vm._v("Warranty Claim Details"),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-3 border-bottom" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.form.tool.tool.category.name)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: " col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.form.tool.tool.description)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.form.tool.tool.brand.name)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.form.tool.tool.model)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [_vm._v(_vm._s(1))]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.form.tool.tool_number)),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(7),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(_vm.form.tool.serial_code)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _vm._m(8),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-check form-check-flat form-check-primary",
                      },
                      [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.tool.refurbished,
                                expression: "form.tool.refurbished",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              name: "remember",
                              id: "remember",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.form.tool.refurbished)
                                ? _vm._i(_vm.form.tool.refurbished, null) > -1
                                : _vm.form.tool.refurbished,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.tool.refurbished,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form.tool,
                                        "refurbished",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form.tool,
                                        "refurbished",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form.tool, "refurbished", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(
                            "\n                                        Refurbished Tool\n                                    "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.form.tool.refurbished
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "order_qty" } }, [
                            _vm._v("Serial Number"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.tool.serial_number,
                                expression: "form.tool.serial_number",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "tool",
                              id: "serial_number",
                              placeholder: "####",
                            },
                            domProps: { value: _vm.form.tool.serial_number },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.tool,
                                  "serial_number",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _vm._m(9),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: 1 + "warehouse_id",
                              id: 1 + "warehouse_id",
                              options: _vm.form.tool.tool.warehouses,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadZones(
                                  _vm.form.tool.warehouse_id,
                                  _vm.form.tool
                                )
                              },
                            },
                            model: {
                              value: _vm.form.tool.warehouse_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.tool, "warehouse_id", $$v)
                              },
                              expression: "form.tool.warehouse_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _c("label", { attrs: { for: "zone_id" } }, [
                          _vm._v("Zone"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: 1 + "zone_id",
                              id: 1 + "zone_id",
                              options: _vm.zones_options,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadLocations(
                                  _vm.tool.warehouse_id,
                                  _vm.tool.zone_id,
                                  _vm.tool
                                )
                              },
                            },
                            model: {
                              value: _vm.form.tool.zone_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.tool, "zone_id", $$v)
                              },
                              expression: "form.tool.zone_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _c("label", { attrs: { for: "location_id" } }, [
                          _vm._v("Location"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: 1 + "location_id",
                              id: 1 + "location_id",
                              options: _vm.location_options,
                            },
                            model: {
                              value: _vm.form.tool.location_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.tool, "location_id", $$v)
                              },
                              expression: "form.tool.location_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(10),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex",
                        },
                        [
                          _vm._m(11),
                          _vm._v(" "),
                          _c("datePicker", {
                            attrs: {
                              autocomplete: "off",
                              config: _vm.datePickerOptions,
                              name: "received_date",
                            },
                            model: {
                              value: _vm.form.received_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "received_date", $$v)
                              },
                              expression: "form.received_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "form-group w-100" }, [
                      _c("label", { attrs: { for: "comment" } }, [
                        _vm._v("Comment"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "textarea",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.comment,
                              expression: "form.comment",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "comment",
                            name: "comment",
                            placeholder: "Comments",
                            rows: "5",
                          },
                          domProps: { value: _vm.form.comment },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "comment", $event.target.value)
                            },
                          },
                        },
                        [_vm._v("Comment")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-light",
                  attrs: { href: "/accounting/warranty" },
                },
                [_vm._v("Cancel")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-5" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("h6", { staticClass: "card-title" }, [_vm._v("Tools Details")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 pl-0" }, [
        _c("h6", { staticClass: "card-title" }, [
          _vm._v("Replacement Details"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Category: ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Description: ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Brand Name:  ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Model:  ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Returned Qty. ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Tool Number:  ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Serial Number:  ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "order_qty" } }, [
          _vm._v("Qty Received"),
          _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("1")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "warehouse_id" } }, [
      _vm._v("Warehouse Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "received_date" } }, [
      _vm._v("Date Received"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon bg-transparent" }, [
      _c("i", {
        staticClass: " text-primary",
        attrs: { "data-feather": "calendar" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }