var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          ref: "po-form",
          attrs: { id: "po-form", name: "po-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "row col-md-12 grid-margin stretch-card" }, [
            _c("div", { staticClass: "card" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "table-responsive pt-3" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "row col-md-6",
                            staticStyle: {
                              float: "right",
                              display: "inline-block",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mb-2",
                                staticStyle: { float: "right" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filter,
                                      expression: "filter",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "filter_sku",
                                    id: "filter_sku",
                                    placeholder: "Search",
                                    autofocus: "",
                                  },
                                  domProps: { value: _vm.filter },
                                  on: {
                                    click: function ($event) {
                                      _vm.flag2 = !_vm.flag2
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.filter = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "mb-2 mt-1",
                                staticStyle: { float: "right" },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary mr-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.scanItem()
                                      },
                                    },
                                  },
                                  [_vm._v("Scan Item")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-bordered table-hover date-columns",
                            attrs: { id: "new-projects" },
                          },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.filteredRows,
                                function (product, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      { staticStyle: { width: "10%" } },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(product.sku) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticStyle: { width: "15%" } },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(product.name) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticStyle: { width: "15%" } },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(product.description) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "cursor-pointer",
                                        staticStyle: {
                                          width: "10%",
                                          color: "blue",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openChangeZoneLocationModal(
                                              product
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(product.zone) +
                                            " - " +
                                            _vm._s(product.location) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticStyle: { width: "10%" } },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(product.quantity) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticStyle: { width: "10%" } },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: product.quantity_picked,
                                              expression:
                                                "product.quantity_picked",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "number",
                                            oninput:
                                              "validity.valid||(value='')",
                                            min: "0",
                                            name: "quantity",
                                            placeholder: "####",
                                          },
                                          domProps: {
                                            value: product.quantity_picked,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                product,
                                                "quantity_picked",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticStyle: { width: "10%" } },
                                      [
                                        _c(
                                          "dropbox",
                                          {
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "comment",
                                              id: "comment",
                                              options: _vm.comment_options,
                                            },
                                            model: {
                                              value: product.comments,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  product,
                                                  "comments",
                                                  $$v
                                                )
                                              },
                                              expression: "product.comments",
                                            },
                                          },
                                          [_c("option")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.form.products.filter(function (product) {
                    return product.use_serials
                  }).length > 0
                    ? _c("div", { staticClass: "row mt-5" }, [
                        _c("h6", [_vm._v("Serial Numbers")]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    _vm.form.products.filter(function (product) {
                      return product.use_serials
                    }),
                    function (product, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "row mt-2" },
                        [
                          _c("p", { staticClass: "col-md-12" }, [
                            _vm._v(
                              _vm._s(
                                product.name +
                                  " | Zone: " +
                                  product.zone +
                                  " - Location: " +
                                  product.location
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: { color: "#007bff" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$modal.show(
                                        index + "-serial-codes-modal"
                                      )
                                      _vm.$forceUpdate()
                                    },
                                  },
                                },
                                [_vm._v("Add or Edit Codes")]
                              ),
                              _vm._v(" "),
                              _c("serial-codes-modal", {
                                ref: index + "serial-code-modal",
                                refInFor: true,
                                attrs: {
                                  product: product,
                                  index: index,
                                  quantity: product.quantity_picked,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.flag_loading
                    ? _c(
                        "div",
                        {
                          staticClass: "spinner-border text-primary my-3",
                          attrs: { role: "status" },
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Loading..."),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-4" }, [
                    _c(
                      "form",
                      {
                        attrs: { name: "project-installation-form" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("div", { staticClass: "row ml-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "status" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.status,
                                              expression: "form.status",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "radio",
                                            name: "status",
                                            value: "Dispatched",
                                          },
                                          domProps: {
                                            checked:
                                              _vm.form.status == "Dispatched",
                                            checked: _vm._q(
                                              _vm.form.status,
                                              "Dispatched"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.form,
                                                "status",
                                                "Dispatched"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          "\n                                                    Yes\n                                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-check form-check-inline",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-check-label",
                                        attrs: { for: "status" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.status,
                                              expression: "form.status",
                                            },
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            type: "radio",
                                            name: "status",
                                            value: "Not Dispatched",
                                          },
                                          domProps: {
                                            checked:
                                              _vm.form.status ==
                                              "Not Dispatched",
                                            checked: _vm._q(
                                              _vm.form.status,
                                              "Not Dispatched"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.form,
                                                "status",
                                                "Not Dispatched"
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          "\n                                                    No\n                                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.form.status == "Dispatched"
                              ? _c("div", { staticClass: "mb-2 my-4" }, [
                                  _c("h6", [
                                    _vm._v(
                                      "I hereby confirm that the items from the project were successfully received in order to be installed."
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "m-signature-pad my-4",
                                      staticStyle: {
                                        border: "2px dashed rgb(204, 204, 204)",
                                      },
                                    },
                                    [
                                      _c("VueSignaturePad", {
                                        ref: "signaturePad",
                                        staticClass: "signature-pad--body",
                                        attrs: {
                                          width: "498px",
                                          height: "158px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-secondary",
                                        attrs: { type: "button" },
                                        on: { click: _vm.clearSignature },
                                      },
                                      [_vm._v("Clear")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm btn-primary",
                                        attrs: { type: "button" },
                                        on: { click: _vm.saveSignature },
                                      },
                                      [_vm._v("Save")]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-2",
                        attrs: {
                          disabled: _vm.submit_disabled,
                          type: "submit",
                        },
                      },
                      [_vm._v("Submit")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary mr-2",
                        attrs: { type: "button" },
                        on: { click: _vm.savePicking },
                      },
                      [_vm._v("Save Picking")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-light",
                        attrs: { href: "/projects/picking" },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ]),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("change-zone-location-modal", {
            attrs: {
              detail: _vm.detail,
              zones: _vm.zones,
              locations: _vm.locations,
              products: _vm.form.products,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("picking-scan-item-modal", {
        attrs: {
          index: "picking",
          products: _vm.form.products,
          flag: _vm.flag,
          locations: _vm.locations,
          zones: _vm.zones,
          filter_sku_input: _vm.filter_sku_input,
          parent: this,
          filteredRows: _vm.filteredRows,
          real_filter: this.filter,
          picking: this.$props.picking,
          scan_warehouses: this.scan_warehouses,
          scan_zones: this.scan_zones,
          scan_locations: this.scan_locations,
          detail: _vm.detail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-title" }, [
        _c("p", [_vm._v("Picking List")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "10%" } }, [
          _vm._v(
            "\n                                            SKU\n                                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                            Product\n                                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                            Description\n                                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                            Zone\n                                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [
          _vm._v(
            "\n                                            Amount\n                                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [
          _vm._v(
            "\n                                            Quantity\n                                        "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                            Comments\n                                        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "status" } }, [
      _vm._v("Dispatched?"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }