var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "scan-item-modal-" + _vm.index,
        scrollable: true,
        height: "auto",
        width: "400px",
      },
    },
    [
      _c(
        "div",
        {
          ref: "vuemodal",
          staticClass: "modal-content border-bottom-0",
          attrs: { id: "myModal" },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between" }, [
                _vm.index === "tool_scan"
                  ? _c("h4", [_vm._v("Scan Tool")])
                  : _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Scan Item"),
                    ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              attrs: { id: "form", name: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.input_scan,
                        expression: "input_scan",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "scan_item",
                      id: "scan_item",
                      placeholder: "XY-###",
                      autofocus: "",
                    },
                    domProps: { value: _vm.input_scan },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submit(_vm.input_scan, _vm.index)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.input_scan = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.index !== "tool_scan"
                    ? _c("div", [
                        _vm.index !== "return_component"
                          ? _c(
                              "p",
                              {
                                staticStyle: { color: "red" },
                                attrs: { hidden: !_vm.input_empty },
                              },
                              [
                                _vm._v(
                                  "SKU or UPC not found in Purchase Order."
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticStyle: { color: "red" },
                            attrs: { hidden: !_vm.already_in_list },
                          },
                          [
                            _vm._v(
                              "Product Already added to the list of returns."
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.index === "return_component"
                          ? _c(
                              "p",
                              {
                                staticStyle: { color: "red" },
                                attrs: { hidden: !_vm.input_empty },
                              },
                              [
                                _vm._v(
                                  "SKU or UPC not found in Dispatched Inventory."
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c(
                          "p",
                          {
                            staticStyle: { color: "red" },
                            attrs: { hidden: !_vm.input_empty },
                          },
                          [
                            _vm._v(
                              "Tool identifier or UPC not found in Purchase Order."
                            ),
                          ]
                        ),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.$modal.hide("scan-item-modal-" + _vm.index),
                          _vm.clear_all()
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submit(_vm.input_scan, _vm.index)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ]),
              _vm._v(" "),
              _c("receiving-details-modal", {
                attrs: {
                  product: _vm.scanned_product,
                  index: _vm.index,
                  locations: _vm.locations,
                  zones: _vm.zones,
                  flag2: _vm.flag2,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("scan-returns-modal", {
        attrs: {
          product: _vm.scanned_product,
          product_returns: _vm.$props.product_returns,
          flag_return: _vm.flag_return,
          parent: _vm.$props.parent,
          index: _vm.$props.index,
          returned_list: _vm.$props.returned_list,
          dispatched_record: _vm.dispatched_record,
          zones: _vm.zones,
          locations: _vm.locations,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }