var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "receiving-form",
        attrs: { id: "receiving-form", name: "receiving-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("h6", [_vm._v("Adjustment Details")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("div", { staticClass: "form-group mb-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row ml-2" }, [
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("label", { staticClass: "form-check-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.adjustment_type,
                          expression: "form.adjustment_type",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "optionsRadios5",
                        id: "optionsRadios5",
                        value: "add",
                      },
                      domProps: {
                        checked: _vm._q(_vm.form.adjustment_type, "add"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.form, "adjustment_type", "add")
                          },
                          _vm.updateComponent,
                        ],
                      },
                    }),
                    _vm._v(
                      "\n                                Add\n                            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("label", { staticClass: "form-check-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.adjustment_type,
                          expression: "form.adjustment_type",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "product",
                        id: "optionsRadios6",
                        value: "remove",
                      },
                      domProps: {
                        checked: _vm._q(_vm.form.adjustment_type, "remove"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "adjustment_type",
                              "remove"
                            )
                          },
                          _vm.updateComponent,
                        ],
                      },
                    }),
                    _vm._v(
                      "\n                                Remove\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.form.products, function (item, index) {
              return _c("div", { key: index }, [
                _vm.form.adjustment_type == "add"
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12 ml-0 pl-0 mb-3" },
                        [
                          _vm._m(1, true),
                          _vm._v(" "),
                          _c(
                            "dropbox",
                            {
                              attrs: { options: _vm.suppliers },
                              model: {
                                value: _vm.form.supplier_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "supplier_id", $$v)
                                },
                                expression: "form.supplier_id",
                              },
                            },
                            [_c("option")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group mb-3" }, [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.qty_received,
                              expression: "item.qty_received",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            oninput: "validity.valid||(value='')",
                            min: "0",
                            name: "amount",
                            id: "amount",
                            placeholder: "####",
                          },
                          domProps: { value: item.qty_received },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                item,
                                "qty_received",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-check form-check-flat form-check-primary mb-3",
                        },
                        [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.same_warehouse,
                                  expression: "item.same_warehouse",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(item.same_warehouse)
                                  ? _vm._i(item.same_warehouse, null) > -1
                                  : item.same_warehouse,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.same_warehouse,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "same_warehouse",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "same_warehouse",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "same_warehouse", $$c)
                                    }
                                  },
                                  _vm.updateComponent,
                                ],
                              },
                            }),
                            _vm._v(
                              "\n                                Same Warehouse?\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-check form-check-flat form-check-primary mb-3",
                        },
                        [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.scan_serials,
                                  expression: "item.scan_serials",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(item.scan_serials)
                                  ? _vm._i(item.scan_serials, null) > -1
                                  : item.scan_serials,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.scan_serials,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "scan_serials",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "scan_serials",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "scan_serials", $$c)
                                    }
                                  },
                                  _vm.updateComponent,
                                ],
                              },
                            }),
                            _vm._v(
                              "\n                                Scan Serial Numbers?\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group w-100 mb-3" }, [
                        _c("label", { attrs: { for: "comment" } }, [
                          _vm._v("Adjustment Reason"),
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.reason,
                              expression: "form.reason",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "adjustment",
                            name: "adjustment",
                            placeholder: "Adjustment Reason",
                            rows: "5",
                          },
                          domProps: { value: _vm.form.reason },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "reason", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm._m(3, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c("warehouse-allocation-component", {
                            ref: "warehouse-component",
                            refInFor: true,
                            attrs: {
                              index: index,
                              parent: item,
                              type: "product",
                              zones: _vm.zones,
                              locations: _vm.locations,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12 ml-0 pl-0 mb-3" },
                        [
                          _vm._m(4, true),
                          _vm._v(" "),
                          _c(
                            "dropbox",
                            {
                              attrs: { options: _vm.suppliers },
                              model: {
                                value: _vm.form.supplier_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "supplier_id", $$v)
                                },
                                expression: "form.supplier_id",
                              },
                            },
                            [_c("option")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group mb-3" }, [
                        _vm._m(5, true),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.qty_received,
                              expression: "item.qty_received",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            oninput: "validity.valid||(value='')",
                            min: "0",
                            name: "amount",
                            placeholder: "####",
                          },
                          domProps: { value: item.qty_received },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                item,
                                "qty_received",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-check form-check-flat form-check-primary mb-3",
                        },
                        [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.same_warehouse,
                                  expression: "item.same_warehouse",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(item.same_warehouse)
                                  ? _vm._i(item.same_warehouse, null) > -1
                                  : item.same_warehouse,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.same_warehouse,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "same_warehouse",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "same_warehouse",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "same_warehouse", $$c)
                                    }
                                  },
                                  _vm.updateComponent,
                                ],
                              },
                            }),
                            _vm._v(
                              "\n                                Same Warehouse?\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-check form-check-flat form-check-primary mb-3",
                        },
                        [
                          _c("label", { staticClass: "form-check-label" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.scan_serials,
                                  expression: "item.scan_serials",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(item.scan_serials)
                                  ? _vm._i(item.scan_serials, null) > -1
                                  : item.scan_serials,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.scan_serials,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "scan_serials",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "scan_serials",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "scan_serials", $$c)
                                    }
                                  },
                                  _vm.updateComponent,
                                ],
                              },
                            }),
                            _vm._v(
                              "\n                                Scan Serial Numbers?\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group w-100 mb-3" }, [
                        _c("label", { attrs: { for: "comment" } }, [
                          _vm._v("Adjustment Reason"),
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.reason,
                              expression: "form.reason",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "adjustment",
                            name: "adjustment",
                            placeholder: "Adjustment Reason",
                            rows: "5",
                          },
                          domProps: { value: _vm.form.reason },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "reason", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm._m(6, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-3" },
                        [
                          _c("warehouse-allocation-component", {
                            ref: "warehouse-component",
                            refInFor: true,
                            attrs: {
                              index: index,
                              parent: item,
                              type: "product",
                              zones: _vm.zones,
                              locations: _vm.locations,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary mr-2",
            attrs: { disabled: _vm.submit_disabled, type: "submit" },
          },
          [_vm._v("Submit")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-light",
            attrs: { href: "/accounting/reconciliation" },
          },
          [_vm._v("Cancel")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "inventory" } }, [
      _vm._v("Inventory: "),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "supplier" } }, [
      _vm._v("Supplier"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "order_qty" } }, [
      _vm._v("Amount"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h6", { staticClass: "card-title" }, [_vm._v("Warehouse Allocation")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "supplier" } }, [
      _vm._v("Supplier"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "amount" } }, [
      _vm._v("Amount"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h6", { staticClass: "card-title" }, [_vm._v("Warehouse Allocation")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }