var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.index + "-return-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Product to Return"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-body" },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "returned_qty" } }, [
                _vm._v("Returned Qty."),
                _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.returned_qty,
                    expression: "returned_qty",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "number",
                  oninput: "validity.valid||(value=\\'\\')",
                  min: "1",
                  max: this.product.pivot.quantity_picked,
                  name: "returned_qty",
                  placeholder: "##",
                },
                domProps: { value: _vm.returned_qty },
                on: {
                  change: function ($event) {
                    return _vm.change_qty()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.returned_qty = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "warehouse" } }, [
                  _vm._v("Warehouse to Return"),
                  _c("span", { staticClass: "required_asterisk" }, [
                    _vm._v("*"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: _vm.index + "warehouse_select",
                      id: _vm.index + "warehouse_select",
                      options: this.product.warehouses,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.loadZones(_vm.warehouse_id)
                      },
                    },
                    model: {
                      value: _vm.warehouse_id,
                      callback: function ($$v) {
                        _vm.warehouse_id = $$v
                      },
                      expression: "warehouse_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.warehouse_id !== "" || _vm.product.warehouse_id !== ""
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "zone" } }, [
                      _vm._v("Zone to Return"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "dropbox",
                      {
                        attrs: { options: _vm.zones_show },
                        on: {
                          change: function ($event) {
                            return _vm.loadLocations(_vm.warehouse_id)
                          },
                        },
                        model: {
                          value: _vm.zone_id,
                          callback: function ($$v) {
                            _vm.zone_id = $$v
                          },
                          expression: "zone_id",
                        },
                      },
                      [_c("option")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.zone_id !== ""
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "location" } }, [
                      _vm._v("Location to Return"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "dropbox",
                      {
                        attrs: { options: _vm.locations_show },
                        model: {
                          value: _vm.location_id,
                          callback: function ($$v) {
                            _vm.location_id = $$v
                          },
                          expression: "location_id",
                        },
                      },
                      [_c("option")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "quality_check" } }, [
                  _vm._v("Quality Check"),
                  _c("span", { staticClass: "required_asterisk" }, [
                    _vm._v("*"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: "quality_check",
                      id: "quality_check",
                      options: this.$props.qualities,
                    },
                    model: {
                      value: _vm.quality_check_id,
                      callback: function ($$v) {
                        _vm.quality_check_id = $$v
                      },
                      expression: "quality_check_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "return_reason" } }, [
                _vm._v("Return Reason"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.return_reason,
                    expression: "return_reason",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "return_reason",
                  placeholder: "Return Reason",
                },
                domProps: { value: _vm.return_reason },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.return_reason = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.dispatched_record.length > 0
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "serial_numbers" } }, [
                      _vm._v("Serial Number"),
                      _c("span", { staticClass: "required_asterisk" }, [
                        _vm._v("*"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("select-record", {
                      attrs: {
                        name: "serial_numbers",
                        id: "serial_numbers",
                        options: this.$props.dispatched_record,
                      },
                      model: {
                        value: _vm.product.serial_numbers,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "serial_numbers", $$v)
                        },
                        expression: "product.serial_numbers",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "replacement" } }, [
                  _vm._v("Replacement?"),
                  _c("span", { staticClass: "required_asterisk" }, [
                    _vm._v("*"),
                  ]),
                ]),
                _vm._v(" "),
                _c("dropbox", {
                  attrs: {
                    name: "replacement",
                    id: "replacement",
                    options: this.replacement_options,
                  },
                  model: {
                    value: _vm.product.replacement,
                    callback: function ($$v) {
                      _vm.$set(_vm.product, "replacement", $$v)
                    },
                    expression: "product.replacement",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "returned_qty" } }, [
                _vm._v("Qty."),
                _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.returned_qty,
                    expression: "returned_qty",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "number",
                  disabled: "",
                  min: "1",
                  max: this.product.pivot.quantity_picked,
                  name: "returned_qty",
                  placeholder: "##",
                },
                domProps: { value: _vm.returned_qty },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.returned_qty = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "serial_numbers" } }, [
                _vm._v("Serial Numbers"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: { color: "#007bff" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.show(_vm.index + "-serial-codes-modal")
                    },
                  },
                },
                [_vm._v("Add or Edit Codes")]
              ),
            ]),
            _vm._v(" "),
            _c("serial-codes-modal", {
              attrs: {
                product: _vm.product,
                index: _vm.index,
                quantity: _vm.returned_qty,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide(_vm.index + "-return-modal")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.addToIn()
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }