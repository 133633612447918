var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.parent.same_warehouse == true
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12 ml-0 pl-0" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: _vm.index + "warehouse_id",
                      id: _vm.index + "warehouse_id",
                      options: _vm.parent.warehouses,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.loadZones(_vm.parent.warehouse_id)
                      },
                    },
                    model: {
                      value: _vm.parent.warehouse_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.parent, "warehouse_id", $$v)
                      },
                      expression: "parent.warehouse_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12 ml-0 pl-0" },
              [
                _c("label", { attrs: { for: "zone_id" } }, [_vm._v("Zone")]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: _vm.index + "zone_id",
                      id: _vm.index + "zone_id",
                      options: _vm.parent.zones_options,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.loadLocations(_vm.parent.zone_id)
                      },
                    },
                    model: {
                      value: _vm.parent.zone_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.parent, "zone_id", $$v)
                      },
                      expression: "parent.zone_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-12 ml-0 pl-0" },
              [
                _c("label", { attrs: { for: "location_id" } }, [
                  _vm._v("Location"),
                ]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: _vm.index + "location_id",
                      id: _vm.index + "location_id",
                      options: _vm.parent.locations_options,
                    },
                    model: {
                      value: _vm.parent.location_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.parent, "location_id", $$v)
                      },
                      expression: "parent.location_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.parent.scan_serials
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "order_qty" } }, [
                        _vm._v("Serial Numbers"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "#007bff" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.openSerialCodesModal(_vm.index)
                            },
                          },
                        },
                        [_vm._v("Add or Edit Codes")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("serial-codes-modal", {
                    attrs: {
                      product: _vm.parent,
                      index: _vm.index,
                      quantity: _vm.parent.qty_received,
                      wflag: _vm.wflag,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm.parent.same_warehouse == false && _vm.parent.scan_serials == true
      ? _c(
          "div",
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addWarehouseSerials()
                  },
                },
              },
              [_vm._v("Add Warehouse")]
            ),
            _vm._v(" "),
            _vm._l(
              _vm.parent.warehouses_allocation,
              function (item, warehouse_index) {
                return _c(
                  "div",
                  { key: warehouse_index, staticClass: "border-bottom mt-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: warehouse_index + "warehouse_id",
                              id: warehouse_index + "warehouse_id",
                              options: _vm.parent.warehouses,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadMultipleWarehouseZones(item)
                              },
                            },
                            model: {
                              value: item.warehouse_id,
                              callback: function ($$v) {
                                _vm.$set(item, "warehouse_id", $$v)
                              },
                              expression: "item.warehouse_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _c("label", { attrs: { for: "zone_id" } }, [
                          _vm._v("Zone"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: warehouse_index + "zone_id",
                              id: warehouse_index + "zone_id",
                              options: item.zones_options,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadMultipleWarehouseLocations(item)
                              },
                            },
                            model: {
                              value: item.zone_id,
                              callback: function ($$v) {
                                _vm.$set(item, "zone_id", $$v)
                              },
                              expression: "item.zone_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _c("label", { attrs: { for: "location_id" } }, [
                          _vm._v("Location"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: warehouse_index + "location_id",
                              id: warehouse_index + "location_id",
                              options: item.locations_options,
                            },
                            model: {
                              value: item.location_id,
                              callback: function ($$v) {
                                _vm.$set(item, "location_id", $$v)
                              },
                              expression: "item.location_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "order_qty" } }, [
                        _vm._v("Serial Numbers"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "#007bff" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$modal.show(
                                warehouse_index +
                                  "-" +
                                  _vm.parent.name +
                                  "-serial-codes-modal"
                              )
                            },
                          },
                        },
                        [_vm._v("Add or Edit Codes")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.parent.scan_serials == true
                      ? _c(
                          "div",
                          [
                            _c("warehouse-serial-codes-modal", {
                              attrs: {
                                product: _vm.parent,
                                serials_list: item.serials,
                                index: warehouse_index,
                                warehouse: item,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }
            ),
          ],
          2
        )
      : _vm.parent.same_warehouse == false && _vm.parent.scan_serials == false
      ? _c(
          "div",
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addWarehouse()
                  },
                },
              },
              [_vm._v("Add Warehouse")]
            ),
            _vm._v(" "),
            _vm._l(
              _vm.parent.warehouses_allocation,
              function (item, warehouse_index) {
                return _c(
                  "div",
                  { key: warehouse_index, staticClass: "border-bottom mt-3" },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: warehouse_index + "warehouse_id",
                              id: warehouse_index + "warehouse_id",
                              options: _vm.parent.warehouses,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadMultipleWarehouseZones(item)
                              },
                            },
                            model: {
                              value: item.warehouse_id,
                              callback: function ($$v) {
                                _vm.$set(item, "warehouse_id", $$v)
                              },
                              expression: "item.warehouse_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _c("label", { attrs: { for: "zone_id" } }, [
                          _vm._v("Zone"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: warehouse_index + "zone_id",
                              id: warehouse_index + "zone_id",
                              options: item.zones_options,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadMultipleWarehouseLocations(item)
                              },
                            },
                            model: {
                              value: item.zone_id,
                              callback: function ($$v) {
                                _vm.$set(item, "zone_id", $$v)
                              },
                              expression: "item.zone_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-12 ml-0 pl-0" },
                      [
                        _c("label", { attrs: { for: "location_id" } }, [
                          _vm._v("Location"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: warehouse_index + "location_id",
                              id: warehouse_index + "location_id",
                              options: item.locations_options,
                            },
                            model: {
                              value: item.location_id,
                              callback: function ($$v) {
                                _vm.$set(item, "location_id", $$v)
                              },
                              expression: "item.location_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(3, true),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.qty,
                            expression: "item.qty",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          autocomplete: "off",
                          type: "number",
                          placeholder: "####",
                        },
                        domProps: { value: item.qty },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(item, "qty", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]
                )
              }
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "warehouse_id" } }, [
      _vm._v("Warehouse Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "warehouse_id" } }, [
      _vm._v("Warehouse Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "warehouse_id" } }, [
      _vm._v("Warehouse Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Qty. to Warehouse"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }