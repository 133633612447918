var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          { staticClass: "col-md-3", staticStyle: { float: "left" } },
          [
            _c("p", [_vm._v("Select Warehouse:")]),
            _vm._v(" "),
            _c(
              "dropbox",
              {
                attrs: {
                  name: "warehouse_id",
                  id: "warehouse_id",
                  options: _vm.warehouses,
                },
                on: {
                  change: function ($event) {
                    return _vm.resetZone(_vm.filter.new_warehouse_id)
                  },
                },
                model: {
                  value: _vm.filter.new_warehouse_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "new_warehouse_id", $$v)
                  },
                  expression: "filter.new_warehouse_id",
                },
              },
              [_c("option")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.filter.new_warehouse_id !== ""
          ? _c(
              "div",
              { staticClass: "col-md-3", staticStyle: { float: "left" } },
              [
                _c("p", [_vm._v("Select Zone:")]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: "zone_id",
                      id: "zone_id",
                      options: _vm.filtered_zones,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.resetLocation(_vm.filter.new_zone_id)
                      },
                    },
                    model: {
                      value: _vm.filter.new_zone_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "new_zone_id", $$v)
                      },
                      expression: "filter.new_zone_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.filter.new_zone_id !== "" && _vm.filter.new_warehouse_id !== ""
          ? _c(
              "div",
              { staticClass: "col-md-3", staticStyle: { float: "left" } },
              [
                _c("p", [_vm._v("Select Location:")]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: "location_id",
                      id: "location_id",
                      options: _vm.filtered_locations,
                    },
                    model: {
                      value: _vm.filter.new_location_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "new_location_id", $$v)
                      },
                      expression: "filter.new_location_id",
                    },
                  },
                  [_c("option")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3 mt-1", staticStyle: { float: "left" } },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-3 mb-3",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.filter_search = ""
                    _vm.getData(_vm.path + "?" + _vm.getFilter())
                  },
                },
              },
              [_vm._v("Search")]
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          {
            staticClass: "row col-md-12",
            staticStyle: { display: "inline-block" },
          },
          [
            _c(
              "div",
              { staticClass: "col-md-1", staticStyle: { float: "right" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.getData(_vm.path + "?" + _vm.getFilter())
                      },
                    },
                  },
                  [_vm._v("Search")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3", staticStyle: { float: "right" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter_search,
                      expression: "filter_search",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "filter",
                    name: "filter",
                    placeholder: "Search",
                  },
                  domProps: { value: _vm.filter_search },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      _vm.getData(_vm.path + "?" + _vm.getFilter())
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter_search = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "edit-cycle-count-reconciliation-form",
            attrs: {
              id: "create-cycle-count-reconciliation-form",
              name: "create-cycle-count-reconciliation-form",
            },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "table-responsive col-md-12" }, [
              _c("table", { staticClass: "table table-bordered table-hover" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        "\n                                Warehouse\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                                Zone\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                                Location\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                                SKU\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                                Product\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                                Description\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.is_admin === 1
                      ? _c("th", [
                          _vm._v(
                            "\n                                In Stock\n                            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        "\n                                Physical Inventory\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.is_admin === 1
                      ? _c("th", [
                          _vm._v(
                            "\n                                Variance\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.list_of_products, function (item, index) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.warehouses.find(function (wh) {
                                return wh.id === item.warehouse_id
                              }).name
                            ) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.zones.find(function (zn) {
                        return zn.id === item.zone_id
                      }) != null
                        ? _c("td", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.zones.find(function (zn) {
                                    return zn.id === item.zone_id
                                  }).name
                                ) +
                                "\n                            "
                            ),
                          ])
                        : _c("td"),
                      _vm._v(" "),
                      _vm.locations.find(function (ln) {
                        return ln.id === item.location_id
                      }) != null
                        ? _c("td", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.locations.find(function (ln) {
                                    return ln.id === item.location_id
                                  }).name
                                ) +
                                "\n                            "
                            ),
                          ])
                        : _c("td"),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.sku) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.name) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.description) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.is_admin === 1
                        ? _c("td", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.quantity) +
                                "\n                            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.reconciliation_qty,
                              expression: "item.reconciliation_qty",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            oninput: "validity.valid",
                            min: "0",
                            name: "reconciliation_qty",
                            placeholder: "##",
                          },
                          domProps: { value: item.reconciliation_qty },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                item,
                                "reconciliation_qty",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm.is_admin === 1
                        ? _c("td", [
                            "reconciliation_qty" in item &&
                            item.reconciliation_qty !== "" &&
                            item.reconciliation_qty < item.quantity
                              ? _c("p", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        "-" +
                                          Math.abs(
                                            item.quantity -
                                              item.reconciliation_qty
                                          )
                                      ) +
                                      "\n                                "
                                  ),
                                ])
                              : "reconciliation_qty" in item &&
                                item.reconciliation_qty !== "" &&
                                item.reconciliation_qty > item.quantity
                              ? _c("p", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        "+" +
                                          Math.abs(
                                            item.quantity -
                                              item.reconciliation_qty
                                          )
                                      ) +
                                      "\n                                "
                                  ),
                                ])
                              : _c("p"),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mt-3 mb-3",
                    attrs: { type: "button" },
                    on: { click: _vm.addWithNoInventory },
                  },
                  [_vm._v("Add Inventory Product")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mt-3 mb-3",
                    staticStyle: { float: "right" },
                    attrs: { type: "button" },
                    on: { click: _vm.reconcileInventory },
                  },
                  [_vm._v("Add Reconciliation")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
              _vm._v("Manual Reconciliations"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-responsive pt-3" },
              [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            "\n                                Warehouse\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Zone\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Location\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                SKU\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Product\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Description\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.is_admin === 1
                          ? _c("th", [
                              _vm._v(
                                "\n                                In Stock\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Physical Count\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.is_admin === 1
                          ? _c("th", [
                              _vm._v(
                                "\n                                Variance\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(
                            "\n                                Action\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.reconciliation.status === "Recount"
                          ? _c("th", [
                              _vm._v(
                                "\n                                Recount\n                            "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.form.product_reconciliations,
                        function (item, index) {
                          return _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.warehouses.find(function (wh) {
                                      return wh.id === item.warehouse_id
                                    }).name
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.zones.find(function (zn) {
                              return zn.id === item.zone_id
                            }) != null
                              ? _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.zones.find(function (zn) {
                                          return zn.id === item.zone_id
                                        }).name
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _c("td"),
                            _vm._v(" "),
                            _vm.locations.find(function (ln) {
                              return ln.id === item.location_id
                            }) != null
                              ? _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.locations.find(function (ln) {
                                          return ln.id === item.location_id
                                        }).name
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _c("td"),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.sku) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.name) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.description) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.is_admin === 1
                              ? _c("td", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.quantity) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.reconciliation_qty_list) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.is_admin === 1
                              ? _c("td", [
                                  item.reconciliation_qty_list < item.quantity
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              "-" +
                                                Math.abs(
                                                  item.quantity -
                                                    item.reconciliation_qty_list
                                                )
                                            ) +
                                            "\n                                "
                                        ),
                                      ])
                                    : item.reconciliation_qty_list >
                                      item.quantity
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              "+" +
                                                Math.abs(
                                                  item.quantity -
                                                    item.reconciliation_qty_list
                                                )
                                            ) +
                                            "\n                                "
                                        ),
                                      ])
                                    : _c("p"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeReconciliation(index)
                                    },
                                  },
                                },
                                [
                                  _c("trash2-icon", {
                                    attrs: { size: "1.5x" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.reconciliation.status === "Recount"
                              ? _c("td", [
                                  "recount" in item !== false
                                    ? _c("div", [
                                        _vm.reconciliation_products.find(
                                          function (elem) {
                                            return (
                                              elem.product_id ===
                                                item.product_id &&
                                              elem.warehouse_id ===
                                                item.warehouse_id &&
                                              elem.zone_id === item.zone_id &&
                                              elem.location_id ===
                                                item.location_id
                                            )
                                          }
                                        ).recount !== null
                                          ? _c(
                                              "p",
                                              { staticStyle: { color: "red" } },
                                              [
                                                _c("check-square-icon", {
                                                  attrs: { size: "1.5x" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        }
                      ),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _vm.reconciliation.status !== "Recount"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mt-3 mb-3 mr-3",
                          staticStyle: { float: "left" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.save_edited_reconciliation(false)
                            },
                          },
                        },
                        [_vm._v("Save")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.is_admin !== 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mt-3 mb-3 mr-3",
                          staticStyle: { float: "left" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.submit_for_approval_edit()
                            },
                          },
                        },
                        [_vm._v("Submit for Approval")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.is_admin === 1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mt-3 mb-3",
                          staticStyle: { float: "left" },
                          attrs: { type: "button", disabled: _vm.disable_save },
                          on: {
                            click: function ($event) {
                              return _vm.submit_warning()
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mt-3 mb-3",
                      staticStyle: { float: "right" },
                      attrs: { href: "/accounting/reconciliation" },
                    },
                    [_vm._v("Serials Reconciliation")]
                  ),
                ]),
                _vm._v(" "),
                _c("cycle-reconciliation-modal", {
                  attrs: {
                    inventory_products: _vm.inventory_products,
                    warehouses: _vm.warehouses,
                    zones: _vm.zones,
                    locations: _vm.locations,
                    products_to_reconcile: _vm.form.product_reconciliations,
                    all_products: _vm.products_inv,
                    is_admin: _vm.is_admin,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }