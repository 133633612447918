var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.index + "-tool-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.tool.description)),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("h6", [_vm._v("Scan or Search Serial/Tool Number:")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "checkout-tool-inventory-box",
                {
                  attrs: {
                    name: "category",
                    id: "category",
                    options: _vm.tool.items,
                  },
                  model: {
                    value: _vm.inventory_ids,
                    callback: function ($$v) {
                      _vm.inventory_ids = $$v
                    },
                    expression: "inventory_ids",
                  },
                },
                [_c("option")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "comments" } }, [_vm._v("Comments")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.comment,
                  expression: "comment",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "comments",
                placeholder: "Comments",
              },
              domProps: { value: _vm.comment },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.comment = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "crew" } }, [
                _vm._v("Crew"),
                _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c(
                "dropbox",
                {
                  attrs: { name: "crew", id: "crew", options: _vm.crews },
                  model: {
                    value: _vm.crew,
                    callback: function ($$v) {
                      _vm.crew = $$v
                    },
                    expression: "crew",
                  },
                },
                [_c("option")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide(_vm.index + "-tool-modal")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.addToOut(_vm.inventory_ids)
                },
              },
            },
            [_vm._v("Check Out Tool")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }