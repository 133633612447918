var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "cycle-reconciliation-edit-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Non-Inventory Products"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("p", [_vm._v("Select Product:")]),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "product_id",
                            id: "product_id",
                            options: _vm.no_inventory_products,
                          },
                          model: {
                            value: _vm.form.new_product_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "new_product_id", $$v)
                            },
                            expression: "form.new_product_id",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("p", [
                      _vm._v("Quantity:"),
                      _c("span", { staticClass: "required_asterisk" }, [
                        _vm._v("*"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.quantity_to_add,
                          expression: "form.quantity_to_add",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        min: 1,
                        name: "reconciliation_number",
                        placeholder: "##",
                      },
                      domProps: { value: _vm.form.quantity_to_add },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "quantity_to_add",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("Destination Warehouse:")]),
                _vm._v(" "),
                _c(
                  "dropbox",
                  {
                    attrs: {
                      name: "warehouse_id",
                      id: "warehouse_id",
                      options: _vm.warehouses,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.resetZoneLocation(_vm.form.new_warehouse_id)
                      },
                    },
                    model: {
                      value: _vm.form.new_warehouse_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "new_warehouse_id", $$v)
                      },
                      expression: "form.new_warehouse_id",
                    },
                  },
                  [_c("option")]
                ),
                _vm._v(" "),
                _vm.form.new_warehouse_id !== ""
                  ? _c(
                      "div",
                      { staticClass: "mb-2 mt-2" },
                      [
                        _c("p", [_vm._v("Destination Zone")]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: "zone_id",
                              id: "zone_id",
                              options: _vm.zones,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeLocation(_vm.form.new_zone_id)
                              },
                            },
                            model: {
                              value: _vm.form.new_zone_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "new_zone_id", $$v)
                              },
                              expression: "form.new_zone_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.new_zone_id !== "" && _vm.form.new_warehouse_id !== ""
                  ? _c(
                      "div",
                      { staticClass: "mb-2 mt-2" },
                      [
                        _c("p", [_vm._v("Destination Location")]),
                        _vm._v(" "),
                        _c(
                          "dropbox",
                          {
                            attrs: {
                              name: "location_id",
                              id: "location_id",
                              options: _vm.locations,
                            },
                            model: {
                              value: _vm.form.new_location_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "new_location_id", $$v)
                              },
                              expression: "form.new_location_id",
                            },
                          },
                          [_c("option")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v("\n        " + _vm._s(_vm.products_to_reconcile) + "\n        "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("cycle-reconciliation-edit-modal")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.addToIn()
                },
              },
            },
            [_vm._v("Add Product")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }