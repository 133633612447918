<template>
    <modal :name="'dispatch-products-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Product to Dispatch</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="quantity">Product</label>
                    <dropbox name="products" id="products" :options="this.products_bom" v-model="form.product.product_id" v-on:change="$forceUpdate(), findProduct(form.product.product_id)">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="quantity">Quantity</label>
                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity"  placeholder="####" v-model="form.product.quantity">
                </div>
<!--                <div class="form-group">-->
<!--                    <label for="comments">Comments</label>-->
<!--&lt;!&ndash;                    <input type="text" class="form-control" name="comments"  placeholder="####" v-model="form.product.comments">&ndash;&gt;-->
<!--                    <dropbox class="form-control" name="comment" id="comment"  :options="comment_options" v-model="form.product.comments" disabled="true">-->
<!--                        <option selected="true">Dispatched</option>-->
<!--                    </dropbox>-->
<!--                </div>-->
                <div class="col-md-12" v-if="this.products_bom.find(product => product.id == form.product.product_id) != null && this.products_bom.find(product => product.id == form.product.product_id).use_serials">
                    <label>Serial Numbers</label>
                    <p type="button" style="color: #007bff" @click="findProduct(form.product.product_id), $modal.show(1+'-dispatch-serial-codes-modal'); $forceUpdate()">Add or Edit Codes</p>
                    <dispatch-serial-codes-modal :product="form.product" :index="1" v-bind:quantity="form.product.quantity"></dispatch-serial-codes-modal>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('dispatch-products-modal')">Close</button>
                <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" v-on:click="submit">Dispatch</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "DispatchProductsModal",
        props: {
            products: Object,
            project: Object,
            picking: Object
        },
        mounted() {
        },
        data(){
            return{
                products_bom: Object.values(this.$props.products),
                form:{
                    product:{
                        product_id:'',
                        serial_codes: [],
                        comments: '',
                        quantity:'',
                        name:'',
                        sku: '',
                        id:''
                    },
                    warehouse_id: this.$props.picking.warehouse_id
                },
                submit_disabled : false,
                product_to_dispatch: Object,
                productID_new:'',
                comment_options:
                    [
                        {'id':'dispatched', 'name':'Dispatched'},
                        {'id':'not dispatched', 'name':'Not Dispatched'},
                    ],
            }
        },
        methods: {
            findProduct(product_id){
                // axios.get('/additional_product/'+product_id+'/dispatch').then(response =>{
                //     this.form.product.serial_codes = response.data;
                //     this.product_to_dispatch = this.products_bom.find(prd => prd.id == product_id);
                //     this.form.product.id = this.product_to_dispatch.id;
                //     this.form.product.name = this.product_to_dispatch.name;
                //     this.form.product.sku = this.product_to_dispatch.sku;
                //     console.log(this.form.product);
                //     console.log(this.product_to_dispatch.use_serials);
                // }).catch(error =>{
                //     if(error.response.status === 403){
                //         Swal.fire({
                //             icon: 'error',
                //             title: 'Oops...',
                //             text: 'There was a problem.',
                //         });
                //     }
                // });


                this.product_to_dispatch = this.products_bom.find(prd => prd.id == product_id);
                this.form.product.id = this.product_to_dispatch.id;
                this.form.product.name = this.product_to_dispatch.name;
                this.form.product.sku = this.product_to_dispatch.sku;
                console.log(this.form.product);
                console.log(this.product_to_dispatch.use_serials);
                console.log(this.form.product.id);

            },
            submit(){
                let haveQuantity = true;
                let wrongComment = true;
                let diffQuantityComment = false;
                let serials_success = true;
                let bad_serials_list = '';
                let product = this.products_bom.find(product => product.id == this.form.product.product_id)

                this.submit_disabled = true;

                if(this.form.product.quantity == null || this.form.product.quantity == ""){
                    haveQuantity = false;
                }
                if(product != null && product.use_serials){
                    if(this.form.product.quantity != this.form.product.serial_codes.length){
                        serials_success = false;
                    }
                }
                // if(this.form.product.comments === 'dispatched' && (this.form.product.quantity === 0 || this.form.product.quantity === '0')){
                //     wrongComment = false;
                // }
                // if(this.form.product.comments === 'not dispatched' && (this.form.product.quantity !== 0 || this.form.product.quantity !== '0')){
                //     wrongComment = false;
                // }
                this.form.product.comments = 'dispatched';

                if(!haveQuantity){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity for all the products.',
                    });
                    this.submit_disabled = false;
                }else if(!wrongComment){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'If "Dispatched", quantity cannot be 0. If "Not Dispatched", quantity must be 0.',
                    });
                    this.submit_disabled = false;
                }else if(!serials_success){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please scan products with Serial Numbers to complete Picking.' + bad_serials_list,
                    });
                    this.submit_disabled = false;
                }else{
                    axios.post('/projects/'+this.$props.project.id+'/pickings/'+this.$props.picking.id +'/dispatch',this.form).then(response =>{
                        //WMS-287
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                            this.submit_disabled = false;
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                        this.submit_disabled = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>

