var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "inventory-transfer-bin-to-bin-modal-" + _vm.index,
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c(
        "div",
        {
          ref: "vuemodal",
          staticClass: "modal-content border-bottom-0",
          attrs: { id: "myModal" },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between mb-2" }, [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v("Transfer Inventory"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("h6", { staticClass: "mb-2" }, [
                  _vm._v("Product SKU: " + _vm._s(_vm.item.product_sku)),
                ]),
                _vm._v(" "),
                _c("h6", [
                  _vm._v(
                    "Origin: " +
                      _vm._s(_vm.item.warehouse_name) +
                      " / " +
                      _vm._s(_vm.item.zone_name) +
                      " / " +
                      _vm._s(_vm.item.location_name)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.show("bin-to-bin-serial-codes-modal")
                    },
                  },
                },
                [_c("h3", [_vm._v("Scan S/N")])]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              attrs: { id: "form", name: "form" },
              on: {
                submit: [
                  function ($event) {
                    $event.preventDefault()
                  },
                  function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                ],
              },
            },
            [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row col-md-12" }, [
                  _c("div", { staticClass: "col-md-8" }, [
                    _vm.index === 0
                      ? _c(
                          "div",
                          { staticClass: "form-group col-md-12" },
                          [
                            _c("p", [_vm._v("Destination Warehouse:")]),
                            _vm._v(" "),
                            _c(
                              "dropbox",
                              {
                                attrs: {
                                  name: "warehouse_id",
                                  id: "warehouse_id1",
                                  options: _vm.warehouses,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resetZoneLocation(
                                      _vm.form.new_warehouse_id
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.new_warehouse_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "new_warehouse_id", $$v)
                                  },
                                  expression: "form.new_warehouse_id",
                                },
                              },
                              [_c("option")]
                            ),
                            _vm._v(" "),
                            _vm.form.new_warehouse_id !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "mb-2 mt-2" },
                                  [
                                    _c("p", [_vm._v("Destination Zone")]),
                                    _vm._v(" "),
                                    _c(
                                      "dropbox",
                                      {
                                        attrs: {
                                          name: "zone_id",
                                          id: "zone_id1",
                                          options: _vm.zones,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeLocation(
                                              _vm.form.new_zone_id
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.new_zone_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "new_zone_id",
                                              $$v
                                            )
                                          },
                                          expression: "form.new_zone_id",
                                        },
                                      },
                                      [_c("option")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.form.new_zone_id !== "" &&
                            _vm.form.new_warehouse_id !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "mb-2 mt-2" },
                                  [
                                    _c("p", [_vm._v("Destination Location")]),
                                    _vm._v(" "),
                                    _c(
                                      "dropbox",
                                      {
                                        attrs: {
                                          name: "location_id",
                                          id: "location_id1",
                                          options: _vm.locations,
                                        },
                                        model: {
                                          value: _vm.form.new_location_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "new_location_id",
                                              $$v
                                            )
                                          },
                                          expression: "form.new_location_id",
                                        },
                                      },
                                      [_c("option")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("h6", { staticClass: "mb-2 mt-4" }, [
                              _vm._v("Select Quantity:"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.quantity_to_transfer,
                                  expression: "form.quantity_to_transfer",
                                },
                              ],
                              staticClass: "form-control mb-2",
                              attrs: {
                                autocomplete: "off",
                                max: _vm.item.quantity,
                                min: 0,
                                type: "number",
                                name: "quantity",
                                id: "quantity",
                                placeholder: "###",
                              },
                              domProps: {
                                value: _vm.form.quantity_to_transfer,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "quantity_to_transfer",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "form-group col-md-12" },
                          [
                            _c("h6", { staticClass: "mb-2" }, [
                              _vm._v("Destination Warehouse:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "dropbox",
                              {
                                attrs: {
                                  name: "warehouse_id",
                                  id: "warehouse_id",
                                  options: _vm.warehouses,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resetZoneLocation(
                                      _vm.form.new_warehouse_id
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.new_warehouse_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "new_warehouse_id", $$v)
                                  },
                                  expression: "form.new_warehouse_id",
                                },
                              },
                              [_c("option")]
                            ),
                            _vm._v(" "),
                            _vm.form.new_warehouse_id !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "mb-2 mt-2" },
                                  [
                                    _c("p", [_vm._v("Destination Zone")]),
                                    _vm._v(" "),
                                    _c(
                                      "dropbox",
                                      {
                                        attrs: {
                                          name: "zone_id",
                                          id: "zone_id",
                                          options: _vm.zones,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeLocation(
                                              _vm.form.new_zone_id
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.new_zone_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "new_zone_id",
                                              $$v
                                            )
                                          },
                                          expression: "form.new_zone_id",
                                        },
                                      },
                                      [_c("option")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.form.new_zone_id !== "" &&
                            _vm.form.new_warehouse_id !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "mb-2 mt-2" },
                                  [
                                    _c("p", [_vm._v("Destination Location")]),
                                    _vm._v(" "),
                                    _c(
                                      "dropbox",
                                      {
                                        attrs: {
                                          name: "location_id",
                                          id: "location_id",
                                          options: _vm.locations,
                                        },
                                        model: {
                                          value: _vm.form.new_location_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "new_location_id",
                                              $$v
                                            )
                                          },
                                          expression: "form.new_location_id",
                                        },
                                      },
                                      [_c("option")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("h6", { staticClass: "mb-2 mt-4" }, [
                              _vm._v("Selected S/N: "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.form.selected_serial_numbers.length
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _vm.serials_loading
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "spinner-border",
                                      attrs: { role: "status" },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "visually-hidden",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary mr-2 mt-2",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.select_all()
                                    },
                                  },
                                },
                                [_vm._v("Select All")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-light mr-2 mt-2",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearSerials()
                                    },
                                  },
                                },
                                [_vm._v("Clear")]
                              ),
                            ]),
                          ],
                          1
                        ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-4",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary ml-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.scanLocation()
                            },
                          },
                        },
                        [_vm._v("Scan Location")]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.$modal.hide(
                          "inventory-transfer-bin-to-bin-modal-" + _vm.index
                        ),
                          _vm.clear_all()
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { disabled: _vm.submit_disabled, type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submit(_vm.item)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
                _vm._v(" "),
                _vm.loading
                  ? _c(
                      "div",
                      {
                        staticClass: "spinner-border",
                        attrs: { role: "status" },
                      },
                      [_c("span", { staticClass: "visually-hidden" })]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("bin-to-bin-serial-codes-modal", {
                attrs: {
                  product: _vm.item,
                  index: 1,
                  serials: _vm.form.selected_serial_numbers,
                  product_id: _vm.item.product_id,
                  warehouse_id: _vm.item.warehouse_id,
                  zone_id: _vm.item.zone_id,
                  location_id: _vm.item.location_id,
                  removeSerial: _vm.removeSerial,
                },
              }),
              _vm._v(" "),
              _c("scan-location-modal", {
                attrs: {
                  index: _vm.index1,
                  product: _vm.item,
                  locations: _vm.locations,
                  zones: _vm.zones,
                  warehouses: _vm.warehouses,
                  flag1: _vm.flag1,
                  new_warehouse_id: _vm.form.new_warehouse_id,
                  new_zone_id: _vm.form.new_zone_id,
                  new_location_id: _vm.form.new_location_id,
                  parent: this,
                  backup_locations: _vm.backup_locations,
                  backup_warehouses: _vm.backup_warehouses,
                  backup_zones: _vm.backup_zones,
                  scan_flag: _vm.scan_flag,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }