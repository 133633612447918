var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "scan-returns-modal",
        scrollable: true,
        height: "auto",
        width: "500px",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container modal-content border-bottom-0 h-100" },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Product to Return"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                ref: "form",
                attrs: { id: "form", name: "form" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitReturn.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "table-responsive pt-3" }, [
                    _c(
                      "table",
                      { staticClass: "table table-bordered table-hover" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_c("h5", [_vm._v("Product SKU")])]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.$props.product.sku))]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_c("h5", [_vm._v("Product Name")])]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.$props.product.name))]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [_c("h5", [_vm._v("Dispatched Qty.")])]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$props.product !== null &&
                                    _vm.$props.product !== ""
                                    ? _vm.$props.product.pivot.quantity_picked
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.$props.product.use_serials
                            ? _c(
                                "tr",
                                [
                                  _c("td", [
                                    _c("h5", [_vm._v("Return Serials")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("select-record", {
                                    attrs: {
                                      name: "serial_numbers",
                                      id: "serial_numbers",
                                      options:
                                        _vm.$props.dispatched_record.filter(
                                          function (record) {
                                            return (
                                              record.product_id ==
                                                _vm.$props.product.id &&
                                              (record.last_history
                                                .warehouse_id == null
                                                ? true
                                                : _vm.$props.product.pivot
                                                    .warehouse_id ==
                                                  record.last_history
                                                    .warehouse_id)
                                            )
                                          }
                                        ),
                                    },
                                    model: {
                                      value: _vm.$props.product.serial_numbers,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$props.product,
                                          "serial_numbers",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$props.product.serial_numbers",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("tr", [
                                _c("td", [_c("h5", [_vm._v("Return Qty.")])]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.qty_returned,
                                        expression: "qty_returned",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      oninput: "validity.valid||(value='')",
                                      min: "1",
                                      max:
                                        _vm.$props.product !== null &&
                                        _vm.$props.product !== ""
                                          ? _vm.$props.product.pivot
                                              .quantity_picked
                                          : "",
                                      name: "product_return_qty",
                                      id: "product_return_qty",
                                      placeholder: "####",
                                    },
                                    domProps: { value: _vm.qty_returned },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.qty_returned = $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "row col-md-12",
                        staticStyle: { "align-items": "flex-end" },
                      },
                      [
                        _c("div", { staticClass: "col-md-7" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "warehouse_select" } },
                                [
                                  _vm._v("Warehouse"),
                                  _c(
                                    "span",
                                    { staticClass: "required_asterisk" },
                                    [_vm._v("*")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "dropbox-code-block",
                                {
                                  attrs: {
                                    name: "warehouse_select",
                                    id: "warehouse_select",
                                    options: _vm.$props.product.warehouses,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.loadZones(_vm.warehouse_id)
                                    },
                                  },
                                  model: {
                                    value: _vm.warehouse_id,
                                    callback: function ($$v) {
                                      _vm.warehouse_id = $$v
                                    },
                                    expression: "warehouse_id",
                                  },
                                },
                                [_c("option")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "zone_select" } }, [
                                _vm._v("Zone"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "dropbox-code-block",
                                {
                                  attrs: {
                                    name: "zone_select",
                                    id: "zone_select",
                                    options: _vm.zones_show,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.loadLocations()
                                    },
                                    setLocation: _vm.setZone,
                                  },
                                  model: {
                                    value: _vm.zone_id,
                                    callback: function ($$v) {
                                      _vm.zone_id = $$v
                                    },
                                    expression: "zone_id",
                                  },
                                },
                                [_c("option")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "location_select" } },
                                [_vm._v("Location")]
                              ),
                              _vm._v(" "),
                              _c(
                                "dropbox-code-block",
                                {
                                  attrs: {
                                    name: "location_select",
                                    id: "location_select",
                                    options: _vm.locations_show,
                                  },
                                  on: { setLocation: _vm.setLocation },
                                  model: {
                                    value: _vm.location_id,
                                    callback: function ($$v) {
                                      _vm.location_id = $$v
                                    },
                                    expression: "location_id",
                                  },
                                },
                                [_c("option")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-5" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.scanLocation()
                                },
                              },
                            },
                            [_vm._v("Scan Location")]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticStyle: { color: "red" },
                        attrs: { hidden: !_vm.no_quantity },
                      },
                      [
                        _vm._v(
                          "Please, input a quantity or select serials to return."
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.clearAll()
                  },
                },
              },
              [_vm._v("Close")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.submitReturn()
                  },
                },
              },
              [_vm._v("Submit")]
            ),
          ]),
          _vm._v(" "),
          _c("scan-location-modal", {
            attrs: {
              index: _vm.index,
              product: _vm.$props.product_returns[0],
              locations: _vm.locations,
              zones: _vm.zones,
              flag1: _vm.return_flag,
              parent: this,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }