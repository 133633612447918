var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          ref: "receiving-form",
          attrs: { id: "receiving-form", name: "receiving-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "row",
                    staticStyle: { "margin-bottom": "25px" },
                  },
                  [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                        _c("div", { staticClass: "row" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-lg-4 col-xl-6 ml-2 ml-lg-0" },
                            [
                              _c("p", { staticClass: "mb-2" }, [
                                _vm._v(_vm._s(_vm.purchase_order.po_number)),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-lg-4 col-xl-6 ml-2 ml-lg-0" },
                            [
                              _c("p", { staticClass: "mb-2" }, [
                                _vm._v(
                                  _vm._s(_vm.purchase_order.supplier.name)
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-lg-4 col-lg-2 ml-2 ml-lg-0" },
                            [
                              _c("p", { staticClass: "mb-2" }, [
                                _vm._v(_vm._s(_vm.purchase_order.quote)),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(4),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-lg-4 col-xl-6 ml-2 ml-lg-0" },
                            [
                              _c("p", { staticClass: "mb-2" }, [
                                _vm._v(_vm._s(_vm.purchase_order.comments)),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-lg-8 col-xl-9 ml-2 ml-lg-0" },
                            _vm._l(
                              _vm.purchase_order.documents,
                              function (document, index) {
                                return _c("div", [
                                  _vm.order.invoice_number !== ""
                                    ? _c("p", [
                                        document.type === "documentation" &&
                                        _vm.order.invoice_number !== null
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/orders/" +
                                                    _vm.purchase_order.id +
                                                    "/documents/" +
                                                    document.id,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.order.invoice_number +
                                                      "_(" +
                                                      document.created_at.substring(
                                                        0,
                                                        10
                                                      ) +
                                                      ")_" +
                                                      index
                                                  )
                                                ),
                                              ]
                                            )
                                          : document.type === "documentation"
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/orders/" +
                                                    _vm.purchase_order.id +
                                                    "/documents/" +
                                                    document.id,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "doc_(" +
                                                      document.created_at.substring(
                                                        0,
                                                        10
                                                      ) +
                                                      ")_" +
                                                      index
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/orders/" +
                                                    _vm.purchase_order.id +
                                                    "/documents/" +
                                                    document.id,
                                                  target: "_blank",
                                                },
                                              },
                                              [_vm._v(_vm._s(document.type))]
                                            ),
                                      ])
                                    : _c("p", [
                                        document.type === "documentation"
                                          ? _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/orders/" +
                                                    _vm.purchase_order.id +
                                                    "/documents/" +
                                                    document.id,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "doc_(" +
                                                      document.created_at.substring(
                                                        0,
                                                        10
                                                      ) +
                                                      ")_" +
                                                      index
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/orders/" +
                                                    _vm.purchase_order.id +
                                                    "/documents/" +
                                                    document.id,
                                                  target: "_blank",
                                                },
                                              },
                                              [_vm._v(_vm._s(document.type))]
                                            ),
                                      ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "0",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            "-ms-transform": "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%)",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.scanItem(_vm.tools_index)
                                },
                              },
                            },
                            [_c("h3", [_vm._v("Scan Item")])]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-12",
                      staticStyle: { "margin-bottom": "25px" },
                    },
                    _vm._l(this.form.tools, function (tool, index) {
                      return _c(
                        "div",
                        {
                          staticClass: "accordion",
                          attrs: { id: "accordion" + tool.id, role: "tablist" },
                        },
                        [
                          _c("div", { staticClass: "card" }, [
                            _c(
                              "div",
                              {
                                staticClass: "card-header",
                                attrs: {
                                  role: "tab",
                                  id: "headingOne-" + tool.id,
                                },
                              },
                              [
                                _c("h3", { staticClass: "mb-0" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        "data-toggle": "collapse",
                                        href: "#collapseOne-" + tool.id,
                                        "aria-expanded": "false",
                                        "aria-controls":
                                          "collapseOne-" + tool.id,
                                      },
                                    },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          _vm._s(tool.tool_identifier) +
                                            " (" +
                                            _vm._s(tool.description) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "collapse",
                                attrs: {
                                  id: "collapseOne-" + tool.id,
                                  role: "tabpanel",
                                  "aria-labelledby": "headingOne-" + tool.id,
                                  "data-parent": "#accordion" + tool.id,
                                },
                              },
                              [
                                _c("div", { staticClass: "card-body" }, [
                                  _c("div", { staticClass: "row mb-3" }, [
                                    _vm._m(6, true),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-md-6" }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4 mr-1" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _vm._m(7, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xl-7 ml-2 ml-xl-0",
                                            },
                                            [
                                              _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(
                                                  _vm._s(tool.category.name)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _vm._m(8, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                " col-xl-7 ml-2 ml-xl-0",
                                            },
                                            [
                                              _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(
                                                  _vm._s(tool.tool_identifier)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _vm._m(9, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                " col-xl-7 ml-2 ml-xl-0",
                                            },
                                            [
                                              _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(_vm._s(tool.upc)),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _vm._m(10, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xl-7 ml-2 ml-xl-0",
                                            },
                                            [
                                              _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(_vm._s(tool.brand.name)),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _vm._m(11, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xl-7 ml-2 ml-xl-0",
                                            },
                                            [
                                              _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(_vm._s(tool.model)),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _vm._m(12, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xl-7 ml-2 ml-xl-0",
                                            },
                                            [
                                              _c("p", { staticClass: "mb-2" }, [
                                                _vm._v(_vm._s(tool.order_qty)),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        tool.remaining_qty > 0
                                          ? _c("div", { staticClass: "row" }, [
                                              _vm._m(13, true),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-xl-7 ml-2 ml-xl-0",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          tool.remaining_qty
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mr-2" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _vm._m(14, true),
                                              _vm._v(" "),
                                              tool.same_warehouse == false &&
                                              tool.scan_serials == false
                                                ? _c("div", [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            tool.warehouses_allocation.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  (parseInt(
                                                                    b.qty
                                                                  ) || 0)
                                                                )
                                                              },
                                                              0
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _c("div", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            tool.qty_received,
                                                          expression:
                                                            "tool.qty_received",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "number",
                                                        oninput:
                                                          "validity.valid||(value='')",
                                                        min: "0",
                                                        name: "tool",
                                                        id: "order_qty",
                                                        placeholder: "####",
                                                      },
                                                      domProps: {
                                                        value:
                                                          tool.qty_received,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            tool,
                                                            "qty_received",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-check form-check-flat form-check-primary",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-check-label",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          tool.same_warehouse,
                                                        expression:
                                                          "tool.same_warehouse",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-check-input",
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        tool.same_warehouse
                                                      )
                                                        ? _vm._i(
                                                            tool.same_warehouse,
                                                            null
                                                          ) > -1
                                                        : tool.same_warehouse,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            tool.same_warehouse,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                tool,
                                                                "same_warehouse",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                tool,
                                                                "same_warehouse",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            tool,
                                                            "same_warehouse",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                                                Same Warehouse?\n                                                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row mb-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-check form-check-flat form-check-primary",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-check-label",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          tool.scan_serials,
                                                        expression:
                                                          "tool.scan_serials",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "form-check-input",
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        tool.scan_serials
                                                      )
                                                        ? _vm._i(
                                                            tool.scan_serials,
                                                            null
                                                          ) > -1
                                                        : tool.scan_serials,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            tool.scan_serials,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                tool,
                                                                "scan_serials",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                tool,
                                                                "scan_serials",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            tool,
                                                            "scan_serials",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                                                Scan Serial Numbers?\n                                                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-4" },
                                      [
                                        _c(
                                          "receiving-warehouse-allocation-component",
                                          {
                                            attrs: {
                                              index: index,
                                              parent: tool,
                                              type: "tool",
                                              zones: _vm.zones,
                                              locations: _vm.locations,
                                            },
                                          }
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _vm._m(15),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(16),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex",
                        },
                        [
                          _vm._m(17),
                          _vm._v(" "),
                          _c("datePicker", {
                            style: [
                              !_vm.$props.is_admin
                                ? { background: "#D3D3D3" }
                                : { background: "null" },
                            ],
                            attrs: {
                              disabled: !_vm.$props.is_admin,
                              autocomplete: "off",
                              config: _vm.datePickerOptions,
                              name: "received_date",
                            },
                            model: {
                              value: _vm.form.received_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "received_date", $$v)
                              },
                              expression: "form.received_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "invoice_number" } }, [
                            _vm._v("Invoice Number"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.invoice_number,
                                expression: "form.invoice_number",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "invoice_number",
                              id: "invoice_number",
                              placeholder: "####",
                            },
                            domProps: { value: _vm.form.invoice_number },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "invoice_number",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "comments" } }, [
                            _vm._v("Comments"),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.comments,
                                expression: "form.comments",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "comments",
                              name: "comments",
                              placeholder: "Comments",
                              rows: "5",
                            },
                            domProps: { value: _vm.form.comments },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "comments",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "documents" } }, [
                            _vm._v("Documents"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            ref: "documents",
                            staticClass: "border",
                            attrs: {
                              type: "file",
                              multiple: "",
                              name: "documents",
                              id: "documents",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { disabled: _vm.submit_disabled, type: "submit" },
                    on: {
                      click: function ($event) {
                        return _vm.disableButton()
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
                _vm._v(" "),
                _vm.purchase_order.status == "Active"
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-danger mr-2",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.changes_tatus()
                          },
                        },
                      },
                      [_vm._v("Close Active PO")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "btn btn-light", attrs: { href: "/orders" } },
                  [_vm._v("Cancel")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("receiving-scan-tool-modal", {
        attrs: {
          index: _vm.tools_index,
          products: _vm.form.tools,
          flag: _vm.flag,
          locations: _vm.locations,
          zones: _vm.zones,
          warehouses: _vm.warehouses,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-between" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Receiving PO")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 col-xl-3" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("PO #:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 col-xl-3" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Supplier:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 col-xl-3" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Quote #:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 col-xl-3" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Comments:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 col-xl-3" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Documents:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Order Details")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Category: ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Identifier: ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("UPC: ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Brand Name:")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Model:  ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Order Qty. ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Remaining Qty. ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "order_qty" } }, [
      _vm._v("Qty Received"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h6", { staticClass: "card-title" }, [
        _vm._v("Additional Information"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "received_date" } }, [
      _vm._v("Date Received"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon bg-transparent" }, [
      _c("i", {
        staticClass: " text-primary",
        attrs: { "data-feather": "calendar" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }