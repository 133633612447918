var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { name: "project-installation-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "form-group mb-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row ml-2" }, [
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("label", { staticClass: "form-check-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.returns,
                          expression: "form.returns",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "optionsRadios5",
                        id: "optionsRadios5",
                        value: "yes",
                      },
                      domProps: { checked: _vm._q(_vm.form.returns, "yes") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.form, "returns", "yes")
                        },
                      },
                    }),
                    _vm._v(
                      "\n                                Yes\n                            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c("label", { staticClass: "form-check-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.returns,
                          expression: "form.returns",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "product",
                        id: "optionsRadios6",
                        value: "no",
                      },
                      domProps: { checked: _vm._q(_vm.form.returns, "no") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.form, "returns", "no")
                        },
                      },
                    }),
                    _vm._v(
                      "\n                                No\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "comments" } }, [_vm._v("Comments")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.comments,
                    expression: "form.comments",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "comments",
                  name: "comments",
                  placeholder: "Description",
                  rows: "5",
                },
                domProps: { value: _vm.form.comments },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "comments", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "submit" },
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-light",
                attrs: { href: "/projects/installations" },
              },
              [_vm._v("Cancel")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "inventory" } }, [
      _vm._v("Returns: "),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }