<template>
    <div>
        <form @submit.prevent="submit" id="po-form" name="po-form" :ref="'po-form'">
            <div class="row">
                <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title mb-4">Editing PO <span style="color:green;font-weight:bold">#{{this.form.po_number}}</span> </h6>
                            <h6 class="mb-2">PO Details</h6>
                            <div class="form-group">
                                <label for="po_number">PO Number<span class="required_asterisk">*</span></label>
                                <input autocomplete="off" type="text" class="form-control" name="po_number" id="po_number" placeholder="Purchase Order #" v-model="form.po_number" disabled>
                            </div>
                            <div class="form-group">
                                <label for="supplier_id">Supplier<span class="required_asterisk">*</span></label>
                                <dropbox name="supplier_id" id="supplier_id" :options="this.suppliers" v-model="form.supplier_id" @change="loadProductsTools()">
                                    <option></option>
                                </dropbox>
                            </div>
                            <div class="form-group">
                                <label for="quote">Quote Number</label>
                                <input autocomplete="off" type="text" class="form-control" name="quote" id="quote" placeholder="####" v-model="form.quote" disabled>
                            </div>
                            <div class="form-group">
                                <label for="po_number">PO Date<span class="required_asterisk">*</span></label>
                                <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex" disabled="true">
                                    <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                    <datePicker autocomplete="off" v-model="form.po_date" :config="datePickerOptions" name="po_date" disabled="true"></datePicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="req_delivery_date">Request Delivery Date<span class="required_asterisk">*</span></label>
                                <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                    <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                    <datePicker autocomplete="off" v-model="form.req_delivery_date" :config="datePickerOptions" name="req_delivery_date"></datePicker>
                                </div>
                            </div>
                            <div class="mb-2 mt-2">
                                <h6 class="mb-2">Order Details</h6>
                                <div class="form-group">
                                    <label for="order">Order: <span class="required_asterisk">*</span></label>
                                    <div class="row ml-2">
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="optionsRadios5" id="optionsRadios5" value="Tool" v-model="form.order" disabled>
                                                Tool
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="product" id="optionsRadios6" value="Product" v-model="form.order" disabled>
                                                Product
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.form.order === 'Product'">
                                    <div class="form-group">
                                        <label for="name">Product<span class="required_asterisk">*</span></label>
                                        <dropbox name="name" id="name" :options="this.dropdown_products" v-model="product.id" @change="loadProductInfo">
                                            <option></option>
                                        </dropbox>
                                    </div>
                                    <div class="form-group">
                                        <label for="product_id">Product ID</label>
                                        <p>{{this.product.sku}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="sku">SKU</label>
                                        <p>{{this.product.inventory_sku}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="description">Description<span class="required_asterisk">*</span></label>
                                        <p>{{this.product.description}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="order_qty">Order Qty<span class="required_asterisk">*</span></label>
                                        <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="product" id="order_qty" placeholder="####" v-model="product.order_qty">
                                    </div>
                                    <div class="form-group">
                                        <label for="price">Price<span class="required_asterisk">*</span></label>
                                        <input type="number" oninput="validity.valid||(value='')" min="0" step="0.00001" class="form-control" name="product" id="price" placeholder="$##.#####" v-model="product.price">
                                    </div>
                                    <button type="button" class="btn btn-primary mr-2 mb-4" v-on:click="addProduct">Add Product</button>
                                    <div class="mb-4" v-if="this.form.products.length > 0">
                                        <h6 class="mb-2">Products List</h6>
                                        <table class="table table-bordered table-hover date-columns" id="inventory-table">
                                            <thead>
                                            <tr>
                                                <th>

                                                </th>
                                                <th style="width: 10%;">
                                                    SKU
                                                </th>
                                                <th>
                                                    Product
                                                </th>
                                                <th>
                                                    Description
                                                </th>
                                                <th>
                                                    Qty
                                                </th>
                                                <th>
                                                    Price
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(product,index) in form.products">
                                                <td>
                                                    <div style="cursor: pointer" v-on:click="editProduct(product.id)">
                                                        <edit-icon size="1.5x"></edit-icon>
                                                        <div style="cursor: pointer" v-on:click="removeProduct(product.id)">
                                                            <trash-icon size="1.5x"></trash-icon>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="width: 10%;">
                                                    {{product.inventory_sku}}
                                                </td>
                                                <td>
                                                    {{product.name}}
                                                </td>
                                                <td>
                                                    {{product.description}}
                                                </td>
                                                <td>
                                                    {{product.order_qty}}
                                                </td>
                                                <td>
                                                    {{product.price}}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div>

                                </div>

                                <div v-if="this.form.order === 'Tool'">
                                    <div class="form-group">
                                        <label for="tool">Category<span class="required_asterisk">*</span></label>
                                        <dropbox name="name" id="name" :options="this.dropdown_tools_categories" v-model="tool.category_id" @change="loadBrands()">
                                            <option></option>
                                        </dropbox>
                                    </div>
                                    <div class="form-group">
                                        <label for="brand">Brand Name<span class="required_asterisk">*</span></label>
                                        <dropbox name="brand" id="brand" :options="this.dropdown_tools_brand" v-model="tool.brand_id" @change="loadModels()">
                                            <option></option>
                                        </dropbox>
                                    </div>
                                    <div class="form-group">
                                        <label for="model">Model<span class="required_asterisk">*</span></label>
                                        <select-tool-model name="model" id="model" :options="this.dropdown_tools_model" v-model="tool.id" @change="loadToolInfo()">
                                            <option></option>
                                        </select-tool-model>
                                    </div>
                                    <div class="form-group">
                                        <label for="description">Description<span class="required_asterisk">*</span></label>
                                        <p>{{this.tool.description}}</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="order_qty">Order Qty<span class="required_asterisk">*</span></label>
                                        <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="product" id="order_qty" placeholder="####" v-model="tool.order_qty">
                                    </div>
                                    <div class="form-group">
                                        <label for="price">Price<span class="required_asterisk">*</span></label>
                                        <input type="number" oninput="validity.valid||(value='')" min="0" step="0.0001" class="form-control" name="product" id="price" placeholder="$##.####" v-model="tool.price">
                                    </div>
                                    <button type="button" class="btn btn-primary mr-2 mb-4" v-on:click="addTool">Add Tool</button>
                                    <div class="mb-4" v-if="this.form.tools.length > 0">
                                        <h6 class="mb-2">Tools List</h6>
                                        <table class="table table-bordered table-hover date-columns" id="tools-table">
                                            <thead>
                                            <tr>
                                                <th>

                                                </th>
                                                <th>
                                                    Category
                                                </th>
                                                <th>
                                                    Brand Name
                                                </th>
                                                <th>
                                                    Model
                                                </th>
                                                <th>
                                                    Qty
                                                </th>
                                                <th>
                                                    Price
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(tool,index) in form.tools">
                                                <td>
                                                    <div class="row">
                                                        <div style="cursor: pointer" v-on:click="editTool(tool.id)">
                                                            <edit-icon size="1.5x"></edit-icon>
                                                        </div>
                                                        <div style="cursor: pointer" v-on:click="removeTool(tool.id)">
                                                            <trash-icon size="1.5x"></trash-icon>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="width: 10%;">
                                                    {{tool.category}}
                                                </td>
                                                <td>
                                                    {{tool.brand}}
                                                </td>
                                                <td>
                                                    {{tool.model}}
                                                </td>
                                                <td>
                                                    {{tool.order_qty}}
                                                </td>
                                                <td>
                                                    {{tool.price}}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <h6 class="mb-2">Additional Information</h6>
                            <div class="form-group">
                                <label for="comments">Comments</label>
                                <textarea class="form-control" id="comments" name="comments" placeholder="Comments" rows="5" v-model="form.comments"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="documents">Documentation</label>
                                <input type="file" multiple  class="border" name="product" id="documents" ref="documents" v-on:change="updateDocuments">
                                <!--                                <vue2-dropzone ref="documents" id="dropzone" :options="dropzoneOptions"></vue2-dropzone>-->
                            </div>
                            <button :disabled="submit_disabled"  type="submit" class="btn btn-primary mr-2">Submit</button>
                            <!--                                                        <button type="button" v-on:click="deletePO()" class="btn btn-danger mr-2">Delete PO</button>-->
                            <button type="button" v-on:click="closePO()" class="btn btn-danger mr-2">Close PO</button>

                            <!-- <button :disabled="submit_disabled"  type="submit" class="btn btn-primary mr-2">Submit</button> -->
                            <a href="/orders" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import 'bootstrap-datepicker';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Swal from 'sweetalert2';
import { EditIcon,TrashIcon} from 'vue-feather-icons';
import 'dropify';
import 'dropify/dist/css/dropify.min.css'
import moment from 'moment';


export default {
    name: "PurchaseOrderEditForm",
    components:{
        datePicker,
        EditIcon,
        TrashIcon,
    },
    mounted() {

        $('#documents').dropify();
        $("form[name='po-form']").validate({
            rules: {
                po_number:{
                    required: true
                },
                supplier_id:{
                    required: true
                },
                po_date:{
                    required: true
                },
                req_delivery_date:{
                    required: true
                },
                order:{
                    required: true
                }
            },
            errorPlacement: function(label, element) {
                if(['supplier_id'].includes(element[0].name)){
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element.next());
                }else if(['po_date','req_delivery_date'].includes(element[0].name)){
                    label.addClass('mt-2 text-danger');
                    label.insertAfter($(element).parent());
                } else{
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element);
                }
            },
            highlight: function(element, errorClass) {
                $(element).parent().addClass('has-danger')
                $(element).addClass('form-control-danger')
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parent().removeClass('has-danger')
                $(element).removeClass('form-control-danger')
            },
            submitHandler: function(form){
                this.submit()
            },
        });

        this.reloadProductsTools();

        let supplier = this.$props.suppliers.find(supplier => supplier.id == this.form.supplier_id);

        this.$props.order.products.forEach(product => {
            this.form.products.push({
                description : product.description,
                id: product.id,
                inventory_sku: product.category.slug + '-' + supplier.slug + '-' + product.description_slug + '-' + product.product_identifier,
                name : product.name,
                order_qty : product.pivot.quantity,
                price : product.pivot.price,
                sku : product.sku
            })
        })

        this.$props.order.tools.forEach(tool => {
            this.form.tools.push({
                brand: tool.brand.name,
                brand_id: tool.tool_brand_id,
                category: tool.category.slug,
                category_id: tool.tool_category_id,
                description: tool.description,
                id:tool.id,
                model: tool.model,
                model_id: tool.model_id,
                order_qty: tool.pivot.quantity,
                price: tool.pivot.price,
            })
        })



    },
    props: ['suppliers','products','order'],
    data(){
        return {
            form:{
                po_number: this.$props.order.po_number,
                supplier_id: this.$props.order.supplier_id,
                quote: this.$props.order.quote,
                po_date: moment(this.$props.order.po_date).format('MM/DD/YYYY'),
                req_delivery_date: moment(this.$props.order.req_delivery_date).format('MM/DD/YYYY'),
                products: [],
                tools: [],
                comments: this.$props.order.comments,
                order: this.$props.order.order,
                documents: []
            },
            datePickerOptions:{
                format: 'MM/DD/YYYY',
                useCurrent: false,
            },
            product: {
                id: "",
                name: "",
                sku: "",
                description: "",
                order_qty: "",
                price: "",
                inventory_sku: "",
            },
            tool:{
                id:"",
                category:"",
                description:"",
                brand:"",
                model:"",
                order_qty:"",
                price:"",
                category_id:"",
                brand_id:"",
                model_id:""
            },
            dropdown_products: [],
            dropdown_tools_categories: [],
            dropdown_tools_brand: [],
            dropdown_tools_model: [],
            submit_disabled: false,
        }
    },
    methods:{
        reloadProductsTools(){
            let supplier_id = this.form.supplier_id;
            let vm = this;
            // this.form.products.splice(0,this.form.products.length);

            axios.get('/suppliers/' + supplier_id + '/products').then(function(response) {
                this.dropdown_products = response.data.products;
            }.bind(this))

            //Tools
            // this.form.tools.splice(0,this.form.tools.length);
            this.dropdown_tools_categories = this.$props.suppliers.find(supplier => supplier.id == supplier_id).tool.map(tool => tool.category);

            let seen = new Set();

            this.dropdown_tools_categories = this.dropdown_tools_categories.filter(category =>{
                const duplicate = seen.has(category.id);
                seen.add(category.id);
                return !duplicate;
            })
        },
        loadProductsTools(){
            let supplier_id = this.form.supplier_id;
            let vm = this;
            this.resetProduct();
            this.form.products.splice(0,this.form.products.length);

            axios.get('/suppliers/' + supplier_id + '/products').then(function(response) {
                this.dropdown_products = response.data.products;
            }.bind(this))

            //Tools
            this.resetTool();
            this.form.tools.splice(0,this.form.tools.length);
            this.dropdown_tools_categories = this.$props.suppliers.find(supplier => supplier.id == supplier_id).tool.map(tool => tool.category);

            let seen = new Set();

            this.dropdown_tools_categories = this.dropdown_tools_categories.filter(category =>{
                const duplicate = seen.has(category.id);
                seen.add(category.id);
                return !duplicate;
            })
        },
        loadProductInfo(){
            let component = this;
            let selectedProduct = this.dropdown_products.find(product => product.id == component.product.id);
            let supplier = this.$props.suppliers.find(supplier => supplier.id == component.form.supplier_id);

            console.log(supplier);
            console.log(selectedProduct.category);

            if(selectedProduct){
                component.product.order_qty = selectedProduct.min_qty_order;
                component.product.name = selectedProduct.name;
                component.product.sku = selectedProduct.sku;
                component.product.description = selectedProduct.description;
                component.product.inventory_sku = selectedProduct.category.slug + '-' + supplier.slug + '-' + selectedProduct.description_slug + '-' + selectedProduct.product_identifier;
            }
        },
        loadToolInfo(){
            let component = this;
            let selectedTool = this.dropdown_tools_model.find(tool => tool.id == component.tool.id)

            if(selectedTool){
                component.tool.category = selectedTool.category.name;
                component.tool.brand = selectedTool.brand.name;
                component.tool.model = selectedTool.model;
                component.tool.description = selectedTool.description;
            }
        },
        submit(){
            if(this.form.products.length > 0 || this.form.products.length || this.form.tools.length > 0 || this.form.tools.length){
                let formData = new FormData();
                for(let i = 0; i < this.$refs.documents.files.length; i++){
                    console.log(this.$refs.documents.files[i]);
                    formData.append('files['+i+']',this.$refs.documents.files[i])
                }


                let attributes = this.form;

                this.submit_disabled = true;

                axios.patch('/orders/' + this.order.id + '/edit',attributes).then(response => {
                    let id = response.data.message
                    axios.post('/orders/'+id+'/documents',formData).then(response =>{
                        location = response.data.message
                    }).catch(error =>{
                        this.submit_disabled = false;

                    });
                }).catch(error =>{
                    this.submit_disabled = false;
                    if(error.response){
                        console.log(error.response)
                        let validationErrors = [];

                        if(error.response.status === 422){
                            validationErrors = error.response.data.errors;
                            console.log(validationErrors);
                            if(validationErrors['po_number']){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: validationErrors['po_number'][0]
                                })
                            }
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error,
                            });
                        }
                    }
                });
            }else{
                if(this.form.order === 'Product'){

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You need to add at least one product.',
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You need to add at least one tool.',
                    });
                }
            }
        },
        addProduct(){
            let productFilled = this.product.name != null && this.product.name !== '' && this.product.sku != null && this.product.sku !== '';
            let component = this;
            let sameProduct = this.form.products.find(product => product.id == component.product.id);

            if(sameProduct == null){
                if(this.product.order_qty == null || this.product.order_qty === '' || (this.product.order_qty <= 0)){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a order quantity greater than 0.',
                    });
                }else if(this.product.price == null || this.product.price === ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a price for the product.',
                    });
                }else{
                    if(productFilled){
                        this.form.products.push({...this.product})
                        this.resetProduct();
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'You need to pick a product.',
                        });
                    }
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Product already added. You can edit it in the table.',
                });
            }
        },
        resetProduct(){
            this.product.id = '';
            this.product.name = '';
            this.product.sku = '';
            this.product.description = '';
            this.product.order_qty = '';
            this.product.price = '';
        },
        removeProduct(id){
            let tool_remove = this.form.tools.find(tool => tool.id == id);
            this.form.products.pop({...this.product})
        },
        editProduct(id){
            console.log(this.form.products);
            console.log(id);
            let component = this;
            let product_edit = this.form.products.find(product => product.id == id);

            const { value: productValues} = Swal.fire({
                title: product_edit.name,
                html:
                    '<label for="product_quantity">Order Qty<span class="required_asterisk">*</span></label>'+
                    '<input id="product_quantity" class="swal2-input" value="' + product_edit.order_qty + '">' +
                    '<label for="product_price">Order Price<span class="required_asterisk">*</span></label>'+
                    '<input id="product_price" class="swal2-input" value="' + product_edit.price + '">',
                focusConfirm: false,
                preConfirm: () => {
                    product_edit.order_qty = document.getElementById('product_quantity').value;
                    product_edit.price = document.getElementById('product_price').value;
                }
            })

            if(productValues){
                product_edit.quantity = productValues.quantity;
                product_edit.price = productValues.price;
            }
        },
        addTool(){
            let toolFilled = this.tool.model != null && this.tool.model !== '' && this.tool.brand != null && this.tool.brand !== '' && this.tool.category != null && this.tool.category !== '';
            let component = this;
            let sameTool = this.form.tools.find(tool => tool.id == component.tool.id);

            if(sameTool == null){
                if(this.tool.order_qty == null || this.tool.order_qty === '' || (this.tool.order_qty <= 0)){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a order quantity greater than 0.',
                    });
                }else if(this.tool.price == null || this.tool.price === ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a price for the tool.',
                    });
                }else{
                    if(toolFilled){
                        this.form.tools.push({...this.tool})
                        this.resetTool();
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'You need to pick a tool.',
                        });
                    }
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Tool already added. You can edit it in the table.',
                });
            }

        },
        resetTool(){
            this.tool.id = '';
            this.tool.category = '';
            this.tool.brand = '';
            this.tool.description = '';
            this.tool.order_qty = '';
            this.tool.price = '';
            this.tool.model = '';
            this.tool.category_id = '';
            this.tool.brand_id = '';
            this.tool.model_id = '';
        },
        editTool(id){

            let component = this;
            let tool_edit = this.form.tools.find(tool => tool.id == id);


            const { value: toolValues} = Swal.fire({
                title: tool_edit.model,
                html:
                    '<label for="tool_quantity">Order Qty<span class="required_asterisk">*</span></label>'+
                    '<input id="tool_quantity" class="swal2-input" value="' + tool_edit.order_qty + '">' +
                    '<label for="tool_price">Order Price<span class="required_asterisk">*</span></label>'+
                    '<input id="tool_price" class="swal2-input" value="' + tool_edit.price + '">',
                focusConfirm: false,
                preConfirm: () => {
                    tool_edit.order_qty = document.getElementById('tool_quantity').value;
                    tool_edit.price = document.getElementById('tool_price').value;
                }
            })

            if(toolValues){
                tool_edit.quantity = productValues.quantity;
                tool_edit.price = productValues.price;
            }
        },
        removeTool(id){
            let tool_remove = this.form.tools.find(tool => tool.id == id);
            this.form.tools.pop({...this.tool})
        },
        loadBrands(){
            let supplier_id = this.form.supplier_id;
            let vm = this;

            this.dropdown_tools_brand = this.$props.suppliers
                .find(supplier => supplier.id == supplier_id).tool
                .filter(tool => tool.category.id == vm.tool.category_id)
                .map(tool => tool.brand)

            const seen = new Set();

            this.dropdown_tools_brand = this.dropdown_tools_brand.filter(brand =>{
                const duplicate = seen.has(brand.id);
                seen.add(brand.id);
                return !duplicate;
            })
        },
        loadModels(){
            let supplier_id = this.form.supplier_id;
            let vm = this;


            this.dropdown_tools_model = this.$props.suppliers.find(supplier => supplier.id == supplier_id).tool
                .filter(tool => tool.category.id == vm.tool.category_id && tool.brand.id == vm.tool.brand_id);

        },
        updateDocuments(){
            // this.form.documents = this.$refs.documents.value;

            // this.form.documents = [];
            // console.log(this.$refs.documents.files);
            // // this.$refs.documents.files.forEach(file =>{
            // //     this.form.documents.push(file);
            // // })
            //
            // let formData = new FormData();
            //
            // for(let i = 0; i < this.$refs.documents.files.length; i++){
            //     console.log(this.$refs.documents.files[i]);
            //     formData.append('files['+i+']',this.$refs.documents.files[i])
            // }
            // this.form.documents = formData;
        },
        deletePO(){
            console.log( this.order.id);
            axios.delete('/orders/' + this.order.id).then(response =>{
                location = response.data.message;
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });

                this.submit_disabled = false;
            })
        },

        closePO(){
            Swal.fire({
                tittle: 'Are you sure?',
                text: 'You wont be able to revert this!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText:'Close PO'
            }).then((result) => {
                if(!result.dismiss){
                    axios.delete('/orders/' + this.order.id + '/close'
                    )
                        .then(response =>{
                            location = response.data.message;
                        })
                    Swal.fire(
                        'Closed!',
                        'PO ' + this.order.po_number + ' has been closed.',
                        'success'
                    );
                    this.submit_disabled = false;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
