var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "inventory-serials-selection-modal",
        height: "90%",
        width: "50%",
        scrollable: true,
      },
    },
    [
      _c(
        "div",
        { staticClass: "container modal-content border-bottom-0 h-100" },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Select Serials for Product"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "d-flex justify-content-between mt-3" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", { staticClass: "row align-items-end" }, [
                _c("div", { staticClass: "form-group mr-2" }, [
                  _c("label", { attrs: { for: "filter" } }, [_vm._v("Serial")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter,
                        expression: "filter",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      id: "filter",
                      name: "filter",
                      placeholder: "Search",
                    },
                    domProps: { value: _vm.filter },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.filter = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.getData(_vm.path + "?" + _vm.getFilter())
                        },
                      },
                    },
                    [_vm._v("Filter")]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-12 overflow-auto" }, [
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-bordered table-hover products-table",
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Select")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("SKU")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Serial Number")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.filtered_serials, function (product, index) {
                        return _c("tr", [
                          _c("td", { staticClass: "text-center" }, [
                            !_vm.inSerials(product)
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "cursor-pointer",
                                    staticStyle: { color: "blue" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectProduct(product)
                                      },
                                    },
                                  },
                                  [_vm._v("Select")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "cursor-pointer",
                                    staticStyle: { color: "red" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeProduct(product)
                                      },
                                    },
                                  },
                                  [_vm._v("Remove")]
                                ),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(product.sku))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(product.serial_code))]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-between mt-3" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", [
                _c("nav", [
                  _c(
                    "ul",
                    { staticClass: "pagination" },
                    [
                      _c("li", { class: _vm.prevButtonClass }, [
                        _vm.prev_page_url == "" || _vm.prev_page_url == null
                          ? _c("span", { staticClass: "page-link" }, [
                              _vm._v(_vm._s("<")),
                            ])
                          : _c(
                              "button",
                              {
                                staticClass: "page-link",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getData(_vm.prev_page_url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s("<"))]
                            ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.page_buttons, function (button, index) {
                        return _c("li", { class: button.class }, [
                          button.isActualPage
                            ? _c("span", { staticClass: "page-link" }, [
                                _vm._v(_vm._s(button.pageNumber)),
                              ])
                            : button.disabledButton
                            ? _c("span", { staticClass: "page-link" }, [
                                _vm._v("..."),
                              ])
                            : _c(
                                "button",
                                {
                                  staticClass: "page-link",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getData(button.path)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(button.pageNumber))]
                              ),
                        ])
                      }),
                      _vm._v(" "),
                      _c("li", { class: _vm.nextButtonClass }, [
                        _vm.next_page_url == "" || _vm.next_page_url == null
                          ? _c("span", { staticClass: "page-link" }, [
                              _vm._v(_vm._s(">")),
                            ])
                          : _c(
                              "button",
                              {
                                staticClass: "page-link",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getData(_vm.next_page_url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(">"))]
                            ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$modal.hide("inventory-serials-selection-modal")
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }