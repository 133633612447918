var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "warehouse-form",
        attrs: { id: "warehouse-form", name: "warehouse-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6 grid-margin stretch-card" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h6", { staticClass: "card-title" }, [
                  _vm._v("Edit Warehouse"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.warehouse_id,
                        expression: "form.warehouse_id",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "warehouse_id",
                      id: "warehouse_id",
                      placeholder: "Warehouse ID",
                      value: "id",
                    },
                    domProps: { value: _vm.form.warehouse_id },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "warehouse_id", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "name",
                      id: "name",
                      placeholder: "Warehouse Name",
                      value: "name",
                    },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "address" } }, [
                    _vm._v("Address"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.address,
                        expression: "form.address",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "address",
                      id: "address",
                      placeholder: "Address",
                      value: "address",
                    },
                    domProps: { value: _vm.form.address },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "address", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "supervisor_id" } }, [
                      _vm._v("Warehouse Supervisor"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select-box",
                      {
                        attrs: {
                          name: "supervisor_id",
                          id: "supervisor_id",
                          options: this.supervisors,
                        },
                        model: {
                          value: _vm.form.supervisor_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supervisor_id", $$v)
                          },
                          expression: "form.supervisor_id",
                        },
                      },
                      [_c("option")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "description" } }, [
                    _vm._v("Description"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "textarea",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.description,
                          expression: "form.description",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "description",
                        name: "description",
                        placeholder: "Description",
                        rows: "5",
                      },
                      domProps: { value: _vm.form.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "description", $event.target.value)
                        },
                      },
                    },
                    [_vm._v("Description")]
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "row align-items-center mb-2" }, [
                  _c("h6", { staticClass: "card-title mb-0" }, [
                    _vm._v("Add Doors"),
                  ]),
                  _vm._v(" "),
                  _c("div", { on: { click: _vm.addDoor } }, [
                    _c("i", {
                      staticClass: "link-icon ml-3",
                      staticStyle: {
                        cursor: "pointer",
                        width: "24px",
                        height: "24px",
                        fill: "none",
                        color: "green",
                      },
                      attrs: {
                        "data-feather": "plus-square",
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "Click to add doors",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "doors" } }, [_vm._v("Doors")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.form.doors, function (door, index) {
                      return _c("div", { staticClass: "col-md-6 mb-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "align-items-center",
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c("div", { staticClass: "mr-0 w-75" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: door.name,
                                    expression: "door.name",
                                  },
                                ],
                                staticClass: "form-control",
                                staticStyle: { cursor: "text" },
                                attrs: { type: "text" },
                                domProps: { value: door.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(door, "name", $event.target.value)
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ml-1",
                                staticStyle: {
                                  height: "100%",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDoor(index)
                                  },
                                },
                              },
                              [
                                _c("x-circle-icon", {
                                  staticStyle: { color: "red" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { type: "submit" },
                  },
                  [_vm._v("Submit")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-light",
                    attrs: { href: "/masterdata/warehouses" },
                  },
                  [_vm._v("Cancel")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center mb-3 justify-content-between",
              },
              [
                _c("h6", { staticClass: "card-title mb-0" }, [
                  _vm._v("Add Zones & Locations"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary ml-2 mt-0",
                    attrs: { type: "button" },
                    on: { click: _vm.addZone },
                  },
                  [_vm._v("Add New Zone")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "zonesDiv",
                staticStyle: {
                  overflow: "auto",
                  "overflow-x": "hidden",
                  height: "80vh",
                },
              },
              _vm._l(_vm.form.zones, function (zone, index) {
                return _c("div", [
                  _c(
                    "div",
                    {
                      ref: "zoneInfo" + index,
                      refInFor: true,
                      staticClass: "row card",
                      staticStyle: { display: "none" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row justify-content-end pr-3",
                              on: {
                                click: function ($event) {
                                  return _vm.hideZoneInfo(index)
                                },
                              },
                            },
                            [
                              _c("chevron-up-icon", {
                                staticStyle: { cursor: "pointer" },
                                attrs: { size: "1.5x" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "zone" + index } }, [
                              _vm._v("Zone"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: zone.name,
                                  expression: "zone.name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "zone" + index,
                                id: "zone" + index,
                                placeholder: "Zone",
                              },
                              domProps: { value: zone.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(zone, "name", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            zone.locations,
                            function (location, location_index) {
                              return _c("div", [
                                _c("div", { staticClass: "form-group px-4" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "location" + location_index,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Location " + _vm._s(location_index + 1)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "row align-items-center" },
                                    [
                                      _c("div", { staticClass: "col-md-11" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: location.name,
                                              expression: "location.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            name: "location" + location_index,
                                            id: "location" + location_index,
                                            placeholder: "Location",
                                          },
                                          domProps: { value: location.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                location,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-md-1",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteLocation(
                                                index,
                                                location_index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("x-circle-icon", {
                                            staticStyle: { color: "red" },
                                            attrs: { size: "1.5x" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.addLocation(index)
                                },
                              },
                            },
                            [_vm._v("Add New Location")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteZone(index)
                                },
                              },
                            },
                            [_vm._v("Delete Zone")]
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "zoneCard" + index,
                      refInFor: true,
                      staticClass: "row",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card w-100",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.showZoneInfo(index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row justify-content-between align-items-center px-3",
                              },
                              [
                                _c("div", [
                                  _c("div", { staticClass: "card-title" }, [
                                    _vm._v(_vm._s(zone.name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "Locations: " +
                                        _vm._s(zone.locations.length)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("chevron-down-icon", {
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "warehouse_id" } }, [
      _vm._v("Warehouse ID"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "name" } }, [
      _vm._v("Warehouse Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }