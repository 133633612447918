<template>
    <modal :name="'scan-item-bin-2-bin-modal'" :scrollable="true" height="auto" width="400px">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Scan Item</h4>
                </div>
                <form @submit.prevent="submit" method="GET" id="scan_form" name="scan_form">
                    <div class="modal-body justify-content-between">
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="product_sku" id="product_sku" placeholder="Scan Item" autofocus v-model="input_scan_sku" v-on:keyup.enter="submit(input_scan_sku)">
                            <p style="color: red" :hidden="!input_empty">SKU or UPC not found.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('scan-item-bin-2-bin-modal')">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan_sku)">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ScanItemB2BinModal",
    props: ['products', 'flag'],
    data(){
        return {
            input_scan_sku:'',
            input_empty:false,
            scanned_product:'',
            flag2:false
        }
    },
    methods: {
        submit(input_scan_sku) {
            if(this.input_scan_sku === ''){
                this.input_empty = true;
            }
            for(let i = 0; i < this.$props.products.length; i++){
                let match = null;
                if(this.$props.products[i].upc != null){
                    this.upc_array = this.$props.products[i].upc.split(",");
                    match = this.getMatchingResults(this.upc_array).length > 0;
                }else{
                    match = this.input_scan_sku === this.$props.products[i].upc;
                }
                if (this.input_scan_sku.toLowerCase() === this.$props.products[i].sku.toLowerCase() || (match)){
                    this.input_empty = false;
                    this.scanned_product = this.$props.products[i];
                    this.flag2 = !this.flag2;
                    window.location.href = '/bintobin?product_id='+this.$props.products[i].id;
                    break;
                }else{
                    this.input_empty = true;
                }
            }
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan_sku.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        clear_all(){
            console.log('Clear')
            this.input_scan_sku = '';
            this.input_empty = false;
            setTimeout(function(){
                document.getElementById("product_sku").focus();
            },0)
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.input_empty = false;
        }
    }
}
</script>

<style scoped>

</style>
