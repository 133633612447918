var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "product-checkin-form",
        attrs: { id: "product-checkin-form", name: "product-checkin-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h6", { staticClass: "card-title" }, [
                _vm._v("Damaged Returns Details"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4 row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm._v(
                        "\n                                    Product ID:\n                                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.product.sku) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm._v(
                        "\n                                    Product:\n                                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.product.name) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive pt-3" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-bordered table-hover data-table",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(this.product_out, function (item, index) {
                        return _c("tr", [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addModal(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-out-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.project.project_number) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.serial_records
                                    .map(function (sr) {
                                      return sr["serial_number"]
                                    })
                                    .join()
                                ) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.project.client_name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.project.financing_source_name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.returned_quantity) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.reason) +
                                "\n                                "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
                _vm._v("Products to Write Off"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive pt-3" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.product_in, function (item, index) {
                        return _c("tr", [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFromIn(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-in-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.project.project_number) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.serial_records
                                    .map(function (sr) {
                                      return sr["serial_number"]
                                    })
                                    .join()
                                ) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.project.client_name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.project.financing_source_name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.returned_quantity) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.reasons.find(function (r) {
                                    return (r.id = item.write_off_reason)
                                  }).name
                                ) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.form.writeoff_date) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.comment) +
                                "\n                                "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                    Add to list\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Project Number\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Serial Numbers\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Deal Name\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Finance Company\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Returnted Qty\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Return Reason\n                                "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                    Remove from list\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Project Number\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Serial Numbers\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Deal Name\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Finance Company\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Returned Qty\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Write Off Reason\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Write-off Date\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Comment\n                                "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-5" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary mr-2", attrs: { type: "submit" } },
        [_vm._v("Submit")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-light",
          attrs: { href: "/accounting/products/damaged" },
        },
        [_vm._v("Cancel")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }