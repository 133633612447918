var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "return-scan-item-modal-" + _vm.index,
        scrollable: true,
        height: "auto",
        width: "400px",
      },
      on: { close: _vm.clear_all },
    },
    [
      _c(
        "div",
        {
          ref: "vuemodal",
          staticClass: "modal-content border-bottom-0",
          attrs: { id: "myModal" },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between" }, [
                _vm.modal_type === "scan_item_product"
                  ? _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Scan Item to Return"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "input_qty"
                  ? _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Input Qty. to Return"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "select_quality"
                  ? _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Select quality of product"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "scan_location"
                  ? _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Scan Location to Return"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modal_type === "input_serials"
                  ? _c("h4", { staticClass: "modal-title" }, [
                      _vm._v("Scan Serial Numbers to Return"),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.modal_type === "scan_item_product"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form",
                    attrs: { id: "form", name: "form" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-body justify-content-between" },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "text-align-all": "center",
                              "align-self": "center",
                              "align-content": "center",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input_scan,
                                  expression: "input_scan",
                                },
                              ],
                              staticClass: "form-control-lg text-center",
                              attrs: {
                                type: "text",
                                name: "scan_item",
                                id: "scan_item",
                                placeholder: "Scan Item",
                                autofocus: "",
                              },
                              domProps: { value: _vm.input_scan },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.submit(_vm.input_scan, _vm.index)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.input_scan = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: { color: "red" },
                                attrs: { hidden: !_vm.already_in_list },
                              },
                              [
                                _vm._v(
                                  "Product Already added to the list of returns."
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticStyle: { color: "red" },
                                attrs: { hidden: !_vm.input_empty },
                              },
                              [
                                _vm._v(
                                  "SKU or UPC not found in Dispatched Inventory."
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "return-scan-item-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.submit(_vm.input_scan, _vm.index)
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "input_qty"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form2",
                    attrs: { id: "form2", name: "form2" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit_qty.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                        _c("h5", { staticStyle: { color: "#f89b24" } }, [
                          _vm._v(
                            _vm._s(_vm.scanned_product.sku) +
                              " (" +
                              _vm._s(_vm.scanned_product.name) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("b", [_vm._v("Dispatched Qty.: ")]),
                          _vm._v(
                            _vm._s(_vm.scanned_product.pivot.quantity_picked)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "text-align-all": "center",
                            "align-self": "center",
                            "align-content": "center",
                            "align-items": "center",
                            "margin-top": "4px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-md-12 justify-content-between",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.qty_returned,
                                        expression: "qty_returned",
                                      },
                                    ],
                                    staticClass:
                                      "text-center form-control-lg mb-2",
                                    attrs: {
                                      disabled: "",
                                      autofocus: "",
                                      oninput: "validity.valid||(value='')",
                                      min: "0",
                                      type: "number",
                                      name: "order_qty1",
                                      id: "order_qty1",
                                      placeholder: "Qty. to Return",
                                    },
                                    domProps: { value: _vm.qty_returned },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.qty_returned = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;",
                                      },
                                    },
                                    [_vm._v("1")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;",
                                      },
                                    },
                                    [_vm._v("2")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;",
                                      },
                                    },
                                    [_vm._v("3")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';",
                                      },
                                    },
                                    [_vm._v("4")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';",
                                      },
                                    },
                                    [_vm._v("5")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';",
                                      },
                                    },
                                    [_vm._v("6")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';",
                                      },
                                    },
                                    [_vm._v("7")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';",
                                      },
                                    },
                                    [_vm._v("8")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';",
                                      },
                                    },
                                    [_vm._v("9")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-md-12 justify-content-between",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=null",
                                      },
                                    },
                                    [_vm._v("C")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';",
                                      },
                                    },
                                    [_vm._v("0")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn-lg btn-outline-secondary py-3 px-4",
                                      staticStyle: {
                                        "border-color": "#f89b24",
                                        margin: "2px",
                                      },
                                      attrs: {
                                        type: "button",
                                        onclick:
                                          "document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);",
                                      },
                                    },
                                    [_vm._v("<")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm.quantity_error
                          ? _c("p", { staticStyle: { color: "red" } }, [
                              _vm._v("Please, input a valid quantity value."),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "return-scan-item-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: { click: _vm.submit_qty },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "select_quality"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form3",
                    attrs: { id: "form3", name: "form3" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit_quality.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                        _c("h5", { staticStyle: { color: "#f89b24" } }, [
                          _vm._v(
                            _vm._s(_vm.scanned_product.sku) +
                              " (" +
                              _vm._s(_vm.scanned_product.name) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("b", [_vm._v("Qty. to Return: ")]),
                          _vm._v(_vm._s(_vm.scanned_product.returned_qty)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "4px",
                            "margin-left": "6px",
                          },
                        },
                        [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { staticClass: "container" }, [
                              _vm._v("Good\n                                "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.quality_picked,
                                    expression: "quality_picked",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  checked: "",
                                  name: "radio",
                                },
                                domProps: {
                                  value: _vm.$props.quality.find(function (
                                    quality
                                  ) {
                                    return quality.slug == "good"
                                  }).id,
                                  checked: _vm._q(
                                    _vm.quality_picked,
                                    _vm.$props.quality.find(function (quality) {
                                      return quality.slug == "good"
                                    }).id
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.quality_picked =
                                      _vm.$props.quality.find(function (
                                        quality
                                      ) {
                                        return quality.slug == "good"
                                      }).id
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "container" }, [
                              _vm._v("RMA\n                                "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.quality_picked,
                                    expression: "quality_picked",
                                  },
                                ],
                                attrs: { type: "radio", name: "radio" },
                                domProps: {
                                  value: _vm.$props.quality.find(function (
                                    quality
                                  ) {
                                    return quality.slug == "rma"
                                  }).id,
                                  checked: _vm._q(
                                    _vm.quality_picked,
                                    _vm.$props.quality.find(function (quality) {
                                      return quality.slug == "rma"
                                    }).id
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.quality_picked =
                                      _vm.$props.quality.find(function (
                                        quality
                                      ) {
                                        return quality.slug == "rma"
                                      }).id
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "container" }, [
                              _vm._v(
                                "Damaged\n                                "
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.quality_picked,
                                    expression: "quality_picked",
                                  },
                                ],
                                attrs: { type: "radio", name: "radio" },
                                domProps: {
                                  value: _vm.$props.quality.find(function (
                                    quality
                                  ) {
                                    return quality.slug == "damaged"
                                  }).id,
                                  checked: _vm._q(
                                    _vm.quality_picked,
                                    _vm.$props.quality.find(function (quality) {
                                      return quality.slug == "damaged"
                                    }).id
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.quality_picked =
                                      _vm.$props.quality.find(function (
                                        quality
                                      ) {
                                        return quality.slug == "damaged"
                                      }).id
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkmark" }),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm.quality_error
                          ? _c("p", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "Please, select a quality value for the returned product."
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "return-scan-item-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: { click: _vm.submit_quality },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "input_serials"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "modal-header justify-content-between" },
                  [
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "h5",
                          {
                            staticClass: "modal-title",
                            staticStyle: { color: "#f89b24" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.scanned_product.sku +
                                  " (" +
                                  _vm.scanned_product.name +
                                  ")"
                              )
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("p", { staticClass: "text-right" }, [
                          _c("b", [_vm._v("Return Serials:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.scanned_product.serial_numbers.length
                              ) +
                              " / " +
                              _vm._s(_vm.scanned_product.pivot.quantity_picked)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-3 row" }, [
                        _c(
                          "h6",
                          {
                            staticClass: "mr-2 p-2",
                            class: { "modal-serial-selected": _vm.isInput },
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.changeScreen("input")
                              },
                            },
                          },
                          [_vm._v("Scan Serial Numbers")]
                        ),
                        _vm._v(" "),
                        _c(
                          "h6",
                          {
                            staticClass: "p-2",
                            class: { "modal-serial-selected": _vm.isList },
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.changeScreen("list")
                              },
                            },
                          },
                          [_vm._v("Item Serial Numbers")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    ref: "form4",
                    attrs: { id: "form4", name: "form4" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit_serials.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "modal-body" }, [
                      _vm.screen == "input"
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.serial_number_scanned,
                                  expression: "serial_number_scanned",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autofocus: "",
                                autocomplete: "off",
                                type: "text",
                                rows: "4",
                                name: "serial_number",
                                id: "serial_number",
                                placeholder: "Input Serial Numbers",
                              },
                              domProps: { value: _vm.serial_number_scanned },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.inputSerial.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.serial_number_scanned =
                                    $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.screen === "input"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-outline-primary mt-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.inputSerial()
                                      },
                                    },
                                  },
                                  [_vm._v("Add S/N")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.screen === "input"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary mt-2 ml-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.clearSerialInput()
                                      },
                                    },
                                  },
                                  [_vm._v("Clear")]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.screen == "list"
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "table-responsive pt-3" },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-bordered table-hover date-columns",
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                                        Serial Numbers\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("th", [
                                          _vm._v(
                                            "\n                                        Action\n                                    "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.$props.dispatched_record.filter(
                                          function (record) {
                                            return (
                                              record.product_id ==
                                                _vm.scanned_product.id &&
                                              (record.last_history
                                                .warehouse_id == null
                                                ? true
                                                : _vm.scanned_product.pivot
                                                    .warehouse_id ==
                                                  record.last_history
                                                    .warehouse_id)
                                            )
                                          }
                                        ),
                                        function (serial, index) {
                                          return _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(serial.serial_number)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            typeof _vm.scanned_product.serial_numbers.find(
                                              function (s) {
                                                return s == serial.id
                                              }
                                            ) == "undefined"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      color: "mediumseagreen",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectSerial(
                                                              serial
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Select")]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeSerial(
                                                              serial
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Remove")]
                                                    ),
                                                  ]
                                                ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "return-scan-item-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.submit_serials()
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modal_type === "scan_location"
            ? _c("div", [
                _c(
                  "form",
                  {
                    ref: "form5",
                    attrs: { id: "form5", name: "form5" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit_location.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "modal-body justify-content-between" },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "20px" } },
                          [
                            _c("h5", { staticStyle: { color: "#f89b24" } }, [
                              _vm._v(
                                _vm._s(_vm.scanned_product.sku) +
                                  " (" +
                                  _vm._s(_vm.scanned_product.name) +
                                  ")"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("b", [_vm._v("Qty. to Return: ")]),
                              _vm._v(_vm._s(_vm.scanned_product.returned_qty)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "text-align-all": "center",
                              "align-self": "center",
                              "align-content": "center",
                              "align-items": "center",
                              "margin-top": "4px",
                            },
                          },
                          [
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.input_scan_location,
                                    expression: "input_scan_location",
                                  },
                                ],
                                staticClass: "form-control-lg text-center",
                                attrs: {
                                  type: "text",
                                  name: "scan_location",
                                  id: "scan_location",
                                  placeholder: "Location",
                                },
                                domProps: { value: _vm.input_scan_location },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.submit_location(
                                      _vm.input_scan_location
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.input_scan_location =
                                      $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { hidden: !_vm.input_empty_location },
                                },
                                [_vm._v("Please, input a valid location.")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.$modal.hide(
                                "return-scan-item-modal-" + _vm.index
                              ),
                                _vm.clear_all()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.submit_location(
                                _vm.input_scan_location
                              )
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }