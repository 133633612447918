var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mr-2",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              _vm.$modal.show("scan-item-bin-2-bin-modal")
              _vm.changeFlag()
            },
          },
        },
        [_vm._v("Scan Item")]
      ),
      _vm._v(" "),
      _c("scan-item-bin-2-bin-modal", {
        attrs: { products: _vm.$props.products, flag: _vm.flag },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }