var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-md-6 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  ref: "form",
                  attrs: { id: "form", name: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.addProduct.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("h6", { staticClass: "card-title" }, [
                    _vm._v("Product Category"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive pt-3 mb-3" }, [
                    _c(
                      "table",
                      {
                        staticClass:
                          "table table-bordered table-hover date-columns",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.product_category.items,
                            function (item, index) {
                              return _c("tr", [
                                _c("td", [_vm._v(_vm._s(item.name))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(item.slug))]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product_category.name,
                          expression: "product_category.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "product_category_name",
                        id: "product_category_name",
                        placeholder: "Name",
                        value: "id",
                      },
                      domProps: { value: _vm.product_category.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.product_category,
                            "name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product_category.slug,
                          expression: "product_category.slug",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "product_category_slug",
                        id: "product_category_slug",
                        placeholder: "Slug",
                        value: "id",
                      },
                      domProps: { value: _vm.product_category.slug },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.product_category,
                            "slug",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Add")]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: { name: "Roles", dropdown: _vm.role, path: "role" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("dropdown-config-component", {
          attrs: {
            name: "Tool Category",
            dropdown: _vm.tool_category,
            path: "tool_category",
          },
        }),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: {
            name: "Tool Brand",
            dropdown: _vm.tool_brand,
            path: "tool_brand",
          },
        }),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: {
            name: "Tool Type",
            dropdown: _vm.tool_type,
            path: "tool_type",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("dropdown-config-component", {
          attrs: {
            name: "Product Sold",
            dropdown: _vm.product_sold,
            path: "product_sold",
          },
        }),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: {
            name: "Inventory Quality Check",
            dropdown: _vm.quality_check,
            path: "quality_check",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("dropdown-config-component", {
          attrs: {
            name: "Tool Quality Check",
            dropdown: _vm.tool_quality_check,
            path: "tool_quality_check",
          },
        }),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: {
            name: "Battery Type",
            dropdown: _vm.battery_type,
            path: "battery_type",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("dropdown-config-component", {
          attrs: {
            name: "Tool Write Off Reason",
            dropdown: _vm.tool_reason,
            path: "tool_writeoff_reason",
          },
        }),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: {
            name: "Product Write Off Reason",
            dropdown: _vm.product_reason,
            path: "product_writeoff_reason",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("dropdown-config-component", {
          attrs: {
            name: "Project Priority",
            dropdown: _vm.priority,
            path: "priority",
          },
        }),
        _vm._v(" "),
        _c("dropdown-config-component", {
          attrs: {
            name: "Waiting Dock",
            dropdown: _vm.waiting_dock,
            path: "waiting_dock",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                        Name\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Abbreviation\n                                    "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "product_category_name" } }, [
      _vm._v("Product Category Name"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "product_category_slug" } }, [
      _vm._v("Product Category Abbreviation"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }