var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.index + "-dispatch-serial-codes-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.product.name + " (" + _vm.product.sku + ")")),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-right" }, [
                _vm._v(
                  "Serials: " +
                    _vm._s(_vm.product.serial_codes.length) +
                    "/" +
                    _vm._s(_vm.quantity === "" ? "0" : _vm.quantity)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4 row" }, [
              _c(
                "h6",
                {
                  staticClass: "mr-2 p-2",
                  class: { "modal-serial-selected": _vm.isInput },
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScreen("input")
                    },
                  },
                },
                [_vm._v("Input Serial Number")]
              ),
              _vm._v(" "),
              _c(
                "h6",
                {
                  staticClass: "mr-2 p-2",
                  class: { "modal-serial-selected": _vm.isScan },
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScreen("scan")
                    },
                  },
                },
                [_vm._v("Scan Serial Number")]
              ),
              _vm._v(" "),
              _c(
                "h6",
                {
                  staticClass: "p-2",
                  class: { "modal-serial-selected": _vm.isList },
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScreen("list")
                    },
                  },
                },
                [_vm._v("List of Serial Numbers")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _vm.screen == "input"
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "serial_number" } }, [
                  _vm._v("Serial Number"),
                  _c("span", { staticClass: "required_asterisk" }, [
                    _vm._v("*"),
                  ]),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serial,
                      expression: "serial",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    autofocus: "",
                    autocomplete: "off",
                    type: "text",
                    rows: "5",
                    name: "serial_number",
                    id: "serial_number",
                    placeholder: "####",
                  },
                  domProps: { value: _vm.serial },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.addSerial.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.serial = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: " mt-4 alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      "You can enter multiple serial numbers separated by comma (e.g: 12345, 56789)"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.screen == "scan"
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "p-0 m-0",
                    staticStyle: { height: "50vh", width: "50vm" },
                  },
                  [
                    _c("v-quagga", {
                      staticClass: "w-100 h-100",
                      attrs: {
                        onDetected: _vm.addSerialScan,
                        readerSize: _vm.readerSize,
                        readerTypes: _vm.readerTypes,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.screen == "list"
            ? _c("div", [
                _c("div", { staticClass: "table-responsive pt-3" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-bordered table-hover date-columns",
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              "\n                                Serial Codes\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n                                In Stock\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.product.serial_codes,
                          function (serial, index) {
                            return _c("tr", [
                              _c("td", [_vm._v(_vm._s(serial))]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(
                                  _vm.serialsInventoryCodes,
                                  function (valuer, i) {
                                    return _c("div", [
                                      serial === valuer
                                        ? _c("p", [_vm._v("Yes")])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c("td", { staticStyle: { color: "#ff0000" } }, [
                                _c(
                                  "p",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteSerial(index)
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                ),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide(
                    _vm.index + "-dispatch-serial-codes-modal"
                  )
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _vm.screen === "input"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "button" },
                  on: { click: _vm.addSerial },
                },
                [_vm._v("Add Serial Number")]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }