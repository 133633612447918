var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "zone-location-change-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0 h-100" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Select Zona and Location"),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "zone" } }, [
                _vm._v("Zone"),
                _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c(
                "dropbox",
                {
                  attrs: { options: _vm.zone_options },
                  model: {
                    value: _vm.zone,
                    callback: function ($$v) {
                      _vm.zone = $$v
                    },
                    expression: "zone",
                  },
                },
                [_c("option")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "location" } }, [
                _vm._v("Location"),
                _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c(
                "dropbox",
                {
                  attrs: { options: _vm.location_options },
                  model: {
                    value: _vm.location,
                    callback: function ($$v) {
                      _vm.location = $$v
                    },
                    expression: "location",
                  },
                },
                [_c("option")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("zone-location-change-modal")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary mr-2",
              attrs: { type: "button" },
              on: { click: _vm.submit },
            },
            [_vm._v("Submit")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }