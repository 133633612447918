var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "receiving-form",
        attrs: { id: "receiving-form", name: "receiving-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h6", { staticClass: "card-title" }, [
                  _vm._v("RMA Returns Details"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-4 row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v(
                          "\n                                    Project Number:\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-10" }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.returned.project.project_number) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v(
                          "\n                                    Deal Name:\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-10" }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.returned.project.client_name) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _vm._v(
                          "\n                                    Finance Company:\n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-10" }, [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.returned.project.financing_source_name) +
                            "\n                                "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.form.products, function (product, index) {
                  return _c("div", { staticClass: "row mb-3 border-bottom" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(product.name)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(2, true),
                        _vm._v(" "),
                        _c("div", { staticClass: " col-xl-7 ml-2 ml-xl-0" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(product.sku)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(3, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(product.description)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(4, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(_vm._s(_vm.returned.returned_quantity)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(5, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xl-7 ml-2 ml-xl-0" }, [
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.returned.serial_records
                                  .map(function (sr) {
                                    return sr["serial_number"]
                                  })
                                  .join()
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _vm._m(6, true),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: product.qty_received,
                                  expression: "product.qty_received",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                oninput: "validity.valid||(value='')",
                                min: "0",
                                name: "product",
                                id: "order_qty",
                                placeholder: "####",
                              },
                              domProps: { value: product.qty_received },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    product,
                                    "qty_received",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-check form-check-flat form-check-primary",
                            },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: product.same_warehouse,
                                      expression: "product.same_warehouse",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      product.same_warehouse
                                    )
                                      ? _vm._i(product.same_warehouse, null) >
                                        -1
                                      : product.same_warehouse,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = product.same_warehouse,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                product,
                                                "same_warehouse",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                product,
                                                "same_warehouse",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            product,
                                            "same_warehouse",
                                            $$c
                                          )
                                        }
                                      },
                                      _vm.updateComponent,
                                    ],
                                  },
                                }),
                                _vm._v(
                                  "\n                                        Same Warehouse?\n                                    "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-check form-check-flat form-check-primary",
                            },
                            [
                              _c("label", { staticClass: "form-check-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: product.scan_serials,
                                      expression: "product.scan_serials",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(product.scan_serials)
                                      ? _vm._i(product.scan_serials, null) > -1
                                      : product.scan_serials,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = product.scan_serials,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                product,
                                                "scan_serials",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                product,
                                                "scan_serials",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(product, "scan_serials", $$c)
                                        }
                                      },
                                      _vm.updateComponent,
                                    ],
                                  },
                                }),
                                _vm._v(
                                  "\n                                        Scan Serial Numbers?\n                                    "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._m(7, true),
                        _vm._v(" "),
                        _c("warehouse-allocation-component", {
                          ref: "warehouse-component",
                          refInFor: true,
                          staticClass: "mb-4",
                          attrs: {
                            index: 1,
                            parent: product,
                            type: "product",
                            zones: _vm.zones,
                            locations: _vm.locations,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _vm._m(8, true),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex",
                              },
                              [
                                _vm._m(9, true),
                                _vm._v(" "),
                                _c("datePicker", {
                                  attrs: {
                                    autocomplete: "off",
                                    config: _vm.datePickerOptions,
                                    name: "received_date",
                                  },
                                  model: {
                                    value: _vm.form.received_date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "received_date", $$v)
                                    },
                                    expression: "form.received_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group w-100" }, [
                            _c("label", { attrs: { for: "comment" } }, [
                              _vm._v("Comment"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "textarea",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.comment,
                                    expression: "form.comment",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "comment",
                                  name: "comment",
                                  placeholder: "Comments",
                                  rows: "5",
                                },
                                domProps: { value: _vm.form.comment },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "comment",
                                      $event.target.value
                                    )
                                  },
                                },
                              },
                              [_vm._v("Comment")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { type: "submit" },
                  },
                  [_vm._v("Submit")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-light",
                    attrs: { href: "/accounting/rma" },
                  },
                  [_vm._v("Cancel")]
                ),
              ],
              2
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-5" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("h6", { staticClass: "card-title" }, [_vm._v("Return Details")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 pl-0" }, [
        _c("h6", { staticClass: "card-title" }, [
          _vm._v("Replacement Details"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [_c("b", [_vm._v("Product: ")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Product ID: ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Description:  ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Returned Qty.:  ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-5" }, [
      _c("p", { staticClass: "mr-4 mb-2" }, [
        _c("b", [_vm._v("Serial Number: ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "order_qty" } }, [
      _vm._v("Qty Received"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("h6", { staticClass: "card-title" }, [_vm._v("Warehouse Allocation")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "received_date" } }, [
      _vm._v("Date Received"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon bg-transparent" }, [
      _c("i", {
        staticClass: " text-primary",
        attrs: { "data-feather": "calendar" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }