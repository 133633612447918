<template>
    <div>
        <form @submit.prevent="submit" name="project-installation-form">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="status">Completed?<span class="required_asterisk">*</span></label>

<!--                        <dropbox name="status" id="status" :options="[{'id':'In Progress','name':'In Progress'},{'id':'Completed','name':'Completed'}]" v-model="form.status">-->
<!--                            <option></option>-->
<!--                        </dropbox>-->
                        <div class="row ml-2">
                            <div class="form-check form-check-inline">
                                <label for="status" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="status" value="Completed" :checked="form.status == 'Completed'" v-model="form.status">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label for="status" class="form-check-label">
                                    <input type="radio" class="form-check-input" name="status" value="Dispatched" :checked="form.status == 'Dispatched'" v-model="form.status">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2 my-4"  v-if="form.status == 'Completed'">
                        <h6>I hereby confirm that the project was successfully dispatched.</h6>
                        <div class="m-signature-pad my-4" style="border: 2px dashed rgb(204, 204, 204);">
                            <VueSignaturePad width="498px" height="158px" ref="signaturePad" class="signature-pad--body"/>
                        </div>
                        <div>
                            <button type="button" class="btn btn-sm btn-secondary" @click="clearSignature">Clear</button>
                            <button type="button" class="btn btn-sm btn-primary" @click="saveSignature">Save</button>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="comments">Comments</label>
                        <textarea class="form-control" id="comments" name="comments" placeholder="Description" v-model="form.comments" rows="5"></textarea>
                    </div>
<!--                    <button type="button" v-on:click="submit" class="btn btn-primary mr-2">Submit</button>-->
                    <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2" v-on:click="disableButton()">Submit</button>
                    <a href="/projects/installations" class="btn btn-light">Cancel</a>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import datePicker from 'vue-bootstrap-datetimepicker';
    import Swal from "sweetalert2";
    import VueSignaturePad from 'vue-signature-pad';

    Vue.use(VueSignaturePad);

    export default {
        name: "ProjectInstallationComponent",
        props: ['project'],
        components:{
            datePicker,
            // VueSignaturePad
        },
        data(){
            return{
                form:{
                    date_completed:'',
                    comments: '',
                    returns: this.$props.project.returns ? 'yes' : 'no',
                    status: this.$props.project.status
                },
                datePickerOptions:{
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                },
                submit_disabled: false
            }
        },
        methods:{
            disableButton(){
                this.submit_disabled = true;
                this.submit();
            },
            submit(){
                console.log('Submitted');

                axios.post('/projects/' + this.$props.project.id + '/installation',this.form).then(response => {

                    console.log("Submit")
                    if(response.data.error == 404){
                        this.submit_disabled = false;

                        console.log("Submit 404", response.data.message);

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        });

                        return false;
                    }else{
                        console.log("Hola")
                        location = response.data.message;
                    }
                }).catch(error =>{
                    this.submit_disabled = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'There was a problem.',
                    });
                });
            },
            clearSignature(){
                this.$refs.signaturePad.undoSignature();
            },
            saveSignature(){
                const {isEmpty,data} = this.$refs.signaturePad.saveSignature();

                let attributes = {
                    'image_data': data
                }

                axios.post("/projects/"+ this.$props.project.id +"/installation/signatures",attributes).then(response =>{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Signature was saved.',
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true
                    })
                });
            }
        }
    }
</script>

<style scoped>

</style>
