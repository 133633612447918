<template>
    <div class="card">
        <div class="card-body">
            <div class="form-group">
                <div style="float: left" class="col-md-3">
                    <p>Select Warehouse:</p>
                    <dropbox name="warehouse_id" id="warehouse_id" :options="warehouses" v-model="filter.new_warehouse_id" v-on:change="resetZone(filter.new_warehouse_id)">
                        <option></option>
                    </dropbox>
                </div>
                <div v-if="filter.new_warehouse_id !==''" style="float: left" class="col-md-3">
                    <p>Select Zone:</p>
                    <dropbox name="zone_id" id="zone_id" :options="filtered_zones" v-model="filter.new_zone_id" v-on:change="resetLocation(filter.new_zone_id)">
                        <option></option>
                    </dropbox>
                </div>
                <div v-if="filter.new_zone_id !=='' && filter.new_warehouse_id !==''" style="float: left" class="col-md-3">
                    <p>Select Location:</p>
                    <dropbox name="location_id" id="location_id" :options="filtered_locations" v-model="filter.new_location_id">
                        <option></option>
                    </dropbox>
                </div>
                <div style="float: left" class="col-md-3 mt-1">
                    <button type="button" class="btn btn-primary mt-3 mb-3" v-on:click="filter_search=''; getData(path + '?' + getFilter())">Search</button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="form-group">
                <div class="row col-md-12" style="display: inline-block">
                    <div class="col-md-1" style="float: right">
                        <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Search</button>
                    </div>
                    <div class="col-md-3" style="float: right">
                        <input v-on:keydown.enter.prevent="getData(path + '?' + getFilter())" type="text" class="form-control" id="filter" name="filter" placeholder="Search" v-model="filter_search">
                    </div>
                </div>
                <form @submit.prevent="submit" id="create-cycle-count-reconciliation-form" name="create-cycle-count-reconciliation-form" :ref="'edit-cycle-count-reconciliation-form'">
                    <div class="table-responsive col-md-12">
                        <table class="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>
                                    Warehouse
                                </th>
                                <th>
                                    Zone
                                </th>
                                <th>
                                    Location
                                </th>
                                <th>
                                    SKU
                                </th>
                                <th>
                                    Product
                                </th>
                                <th>
                                    Description
                                </th>
                                <th v-if="is_admin === 1">
                                    In Stock
                                </th>
                                <th>
                                    Physical Inventory
                                </th>
                                <th v-if="is_admin === 1">
                                    Variance
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in list_of_products">
                                <td>
                                    {{warehouses.find(wh => wh.id === item.warehouse_id).name}}
                                </td>
                                <td v-if="zones.find(zn => zn.id === item.zone_id) != null">
                                    {{zones.find(zn => zn.id === item.zone_id).name}}
                                </td>
                                <td v-else>
                                </td>
                                <td v-if="locations.find(ln => ln.id === item.location_id) != null">
                                    {{locations.find(ln => ln.id === item.location_id).name}}
                                </td>
                                <td v-else>
                                </td>
                                <td>
                                    {{item.sku}}
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{item.description}}
                                </td>
                                <td v-if="is_admin === 1">
                                    {{item.quantity}}
                                </td>
                                <td>
                                    <input type="number" oninput="validity.valid" min="0" class="form-control" name="reconciliation_qty" placeholder="##" v-model="item.reconciliation_qty">
                                </td>
                                <td v-if="is_admin === 1">
                                    <p v-if="('reconciliation_qty' in item && item.reconciliation_qty !== '') && item.reconciliation_qty < item.quantity">
                                        {{'-'+Math.abs(item.quantity - item.reconciliation_qty)}}
                                    </p>
                                    <p v-else-if="('reconciliation_qty' in item && item.reconciliation_qty !== '') && item.reconciliation_qty > item.quantity">
                                        {{'+'+Math.abs(item.quantity - item.reconciliation_qty)}}
                                    </p>
                                    <p v-else>
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div>
                            <button type="button" class="btn btn-primary mt-3 mb-3"  @click="addWithNoInventory">Add Inventory Product</button>
                            <button style="float: right" type="button" class="btn btn-primary mt-3 mb-3"  @click="reconcileInventory">Add Reconciliation</button>
                        </div>
                    </div>
                    <br>
                    <h6 class="card-subtitle mb-2 text-muted">Manual Reconciliations</h6>
                    <div class="table-responsive pt-3">
                        <table class="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>
                                    Warehouse
                                </th>
                                <th>
                                    Zone
                                </th>
                                <th>
                                    Location
                                </th>
                                <th>
                                    SKU
                                </th>
                                <th>
                                    Product
                                </th>
                                <th>
                                    Description
                                </th>
                                <th v-if="is_admin === 1">
                                    In Stock
                                </th>
                                <th>
                                    Physical Count
                                </th>
                                <th v-if="is_admin === 1">
                                    Variance
                                </th>
                                <th>
                                    Action
                                </th>
                                <th v-if="reconciliation.status === 'Recount'">
                                    Recount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in form.product_reconciliations">
                                <td>
                                    {{warehouses.find(wh => wh.id === item.warehouse_id).name}}
                                </td>
                                <td v-if="zones.find(zn => zn.id === item.zone_id) != null">
                                    {{zones.find(zn => zn.id === item.zone_id).name}}
                                </td>
                                <td v-else>
                                </td>
                                <td v-if="locations.find(ln => ln.id === item.location_id) != null">
                                    {{locations.find(ln => ln.id === item.location_id).name}}
                                </td>
                                <td v-else>
                                </td>
                                <td>
                                    {{item.sku}}
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{item.description}}
                                </td>
                                <td v-if="is_admin === 1">
                                    {{item.quantity}}
                                </td>
                                <td>
                                    {{item.reconciliation_qty_list}}
                                </td>
                                <td v-if="is_admin === 1">
                                    <p v-if="item.reconciliation_qty_list < item.quantity">
                                        {{'-'+Math.abs(item.quantity - item.reconciliation_qty_list)}}
                                    </p>
                                    <p v-else-if="item.reconciliation_qty_list > item.quantity">
                                        {{'+'+Math.abs(item.quantity - item.reconciliation_qty_list)}}
                                    </p>
                                    <p v-else>
                                    </p>
                                </td>
                                <td>
                                    <button class="btn btn-primary" type="button" @click="removeReconciliation(index)"><trash2-icon size="1.5x"></trash2-icon></button>
                                </td>
                                <td v-if="reconciliation.status === 'Recount'">
                                    <div v-if="'recount' in item !== false">
                                        <p style="color: red" v-if="reconciliation_products.find(elem => (elem.product_id === item.product_id) && (elem.warehouse_id === item.warehouse_id) && (elem.zone_id === item.zone_id) && (elem.location_id === item.location_id)).recount !== null">
                                            <check-square-icon size="1.5x" ></check-square-icon>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div>
                            <button v-if="reconciliation.status !== 'Recount'" style="float: left" type="button" class="btn btn-primary mt-3 mb-3 mr-3" @click="save_edited_reconciliation(false)">Save</button>
                            <button v-if="is_admin !== 1" style="float: left" type="button" class="btn btn-primary mt-3 mb-3 mr-3" @click="submit_for_approval_edit()">Submit for Approval</button>
                            <button v-if="is_admin === 1" style="float: left" type="button" class="btn btn-primary mt-3 mb-3" @click="submit_warning()" :disabled="disable_save">Submit</button>
                            <a href="/accounting/reconciliation" style="float: right" class="btn btn-primary mt-3 mb-3">Serials Reconciliation</a>
                        </div>
                        <cycle-reconciliation-modal
                            :inventory_products = "inventory_products"
                            :warehouses="warehouses"
                            :zones="zones"
                            :locations="locations"
                            :products_to_reconcile="form.product_reconciliations"
                            :all_products="products_inv"
                            :is_admin = "is_admin">
                        </cycle-reconciliation-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import {LogInIcon, LogOutIcon, Trash2Icon, CheckSquareIcon} from 'vue-feather-icons';
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from "axios";
import CycleReconciliationModal from "./CycleReconciliationModal";

export default {
    name: "EditCycleCountReconciliation",
    props:{
        warehouses: Array,
        zones: Array,
        locations: Array,
        reconciliation:Object,
        reconciliation_products:Array,
        products_inv:Array,
        no_inventory_products: Array,
        is_admin:Number,
        inventory_products:Array,
    },
    components:{
        CycleReconciliationModal,
        LogOutIcon, LogInIcon, Trash2Icon, CheckSquareIcon, datePicker
    },
    data(){
        return{
            reconciliation_qty:'',
            list_of_products:[],
            form:{
                product_reconciliations: [],
                reconciliation_id:'',
                total_items:'',
                is_submit: false
            },
            filter:{
                new_warehouse_id:'',
                new_zone_id:'',
                new_location_id:'',
                filter:''
            },
            disable_save:false,
            prod_ids:[],
            filtered_zones:[],
            filtered_locations:[],
            new_array_of_products:[],
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/cycle_count/manual_reconciliation/get_reconciliation_products",
            per_page: 1,
            prev_page_url: "",
            to: 1,
            total: 1,
            paginate: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
            filter_search:''
        }
    },
    mounted() {
        this.form.reconciliation_id = this.$props.reconciliation.id;
        this.$props.inventory_products.forEach(elem => {
            this.prod_ids.push(elem.product_id);
            this.$props.reconciliation_products.forEach(prod=>{
                if((elem.product_id === prod.product_id) &&
                    (elem.warehouse_id === prod.warehouse_id) &&
                    (elem.zone_id === prod.zone_id) &&
                    (elem.location_id === prod.location_id))
                {
                    elem.reconciliation_qty = prod.inventory_count;
                    elem.reconciliation_qty_list = prod.inventory_count;
                    this.form.product_reconciliations.push(elem);
                }
            })
        })
        // this.getData(this.path + '?' + this.getFilter());
        this.$props.reconciliation_products.forEach(prod=>{
            if(!(this.prod_ids.includes(prod.product_id))){
                let non_inv = this.$props.products_inv.find(r=> r.id === prod.product_id);
                prod.sku = non_inv.sku;
                prod.description = non_inv.description;
                prod.name = non_inv.name;
                prod.quantity = prod.original_inventory_quantity;
                prod.reconciliation_qty = prod.inventory_count;
                prod.reconciliation_qty_list = prod.inventory_count;
                this.form.product_reconciliations.push(prod);
            }
        })
    },
    methods:{
        reconcileInventory(){
            let flag;
            let toast = false;
            let i;
            for(i = this.list_of_products.length - 1; i >=0; i--) {
                let product = this.list_of_products[i];
                if(('reconciliation_qty' in product && product.reconciliation_qty !== "")){
                    if(this.form.product_reconciliations.length > 0){
                        if(this.form.product_reconciliations.includes(product)){
                            if(product.reconciliation_qty !== this.list_of_products[i].reconciliation_qty_list){
                                this.list_of_products[i].reconciliation_qty_list = product.reconciliation_qty;
                                this.$forceUpdate();
                            }
                            toast = true;
                        }else if(this.form.product_reconciliations.find(pr => (pr.product_id == product.product_id) && (pr.warehouse_id == product.warehouse_id) && (pr.zone_id == product.zone_id) && (pr.location_id == product.location_id)) != null){
                            if(product.reconciliation_qty !== this.list_of_products[i].reconciliation_qty_list){
                                this.list_of_products[i].reconciliation_qty_list = product.reconciliation_qty;
                                this.$forceUpdate();
                            }
                            let pr = this.form.product_reconciliations.find(pr => (pr.product_id == product.product_id)
                                && (pr.warehouse_id == product.warehouse_id)
                                && (pr.zone_id == product.zone_id)
                                && (pr.location_id == product.location_id));
                            pr.reconciliation_qty_list = product.reconciliation_qty;
                            pr.reconciliation_qty = product.reconciliation_qty;
                            toast = true;
                        }else if(flag !== true ){
                            this.$forceUpdate()
                            product.recount = false;
                            product.reconciliation_qty_list = this.list_of_products[i].reconciliation_qty;
                            this.form.product_reconciliations.push(product);
                            toast = true;
                        }
                    }else{
                        this.$forceUpdate()
                        product.reconciliation_qty_list = this.list_of_products[i].reconciliation_qty;
                        this.form.product_reconciliations.push(product);
                        toast = true;
                    }
                    if(toast === true){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2500
                        });
                        Toast.fire({
                            icon: 'success',
                            title: 'Items were added to reconciliation list.'
                        })
                    }
                }
            }
        },
        removeReconciliation(index){
            this.form.product_reconciliations.splice(index, 1);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500
            });
            Toast.fire({
                icon: 'warning',
                title: 'Item was removed from reconciliation list.'
            })
        },
        save_edited_reconciliation(submit){
            let error = false;
            this.form.total_items = this.form.product_reconciliations.length;
            if( this.form.product_reconciliations.length <= 0){
                error = true;
            }else{
                let attributes = this.form;
                axios.post('/cycle_count/save_edited_reconciliation',attributes).then(response =>{
                    if(!submit){
                        window.location = response.data.redirect;
                    }
                }).catch(error_x =>{
                    error = true;
                });
            }
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500
            });
            if(error === true){
                Toast.fire({
                    icon: 'error',
                    title: 'List of manual reconciliations cannot be empty.'
                })
            }
        },
        submit_warning(){
            Swal.fire({
                title: 'Do you want to submit the reconciliation?',
                text: 'This action will add or delete inventory permanently.',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Submit',
            }).then((result) => {
                if(result.value === true){
                    this.form.is_submit = true;
                    this.submit_reconciliation()
                }
            })
        },
        submit_reconciliation(){
            this.save_edited_reconciliation(true);
            this.disable_save = true;
            let attributes = this.form;
            axios.post('/cycle_count/submit_reconciliation',attributes).then(response =>{
                window.location = response.data.redirect;
            }).catch(error =>{
                Swal.fire({
                    icon: 'error',
                    title: 'An error occurred during request.'
                })
            });
        },
        submit_for_approval_edit(){
            let error = false;
            this.form.total_items = this.form.product_reconciliations.length;
            if( this.form.product_reconciliations.length <= 0){
                error = true;
            }else{
                let attributes = this.form;
                axios.post('/cycle_count/submit_for_approval_edit',attributes).then(response =>{
                    window.location = response.data.redirect;
                }).catch(error_x =>{
                    error = true;
                });
            }
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500
            });
            if(error === true){
                Toast.fire({
                    icon: 'error',
                    title: 'List of manual reconciliations cannot be empty.'
                })
            }
        },
        addWithNoInventory(){
            this.$modal.show('cycle-reconciliation-modal');
        },
        resetZone(warehouse_id){
            this.filtered_zones = this.$props.zones.filter(zn=> zn.warehouse_id === parseInt(warehouse_id));
        },
        resetLocation(zone_id){
            this.filtered_locations= this.$props.locations.filter(ln=> ln.zone_id === parseInt(zone_id));
        },
        getFilter(){
            let query = 'warehouse_id=' + this.filter.new_warehouse_id??null;
            query = query + '&zone_id=' + this.filter.new_zone_id??null;
            query = query + '&location_id=' + this.filter.new_location_id??null;
            query = query + (this.filter_search != null && this.filter_search !== '' ? '&filter_search=' + this.filter_search : '');
            return query;
        },
        setButtons(){
            this.page_buttons = [];
            if(this.last_page > 13){
                if(this.current_page < 8){
                    this.setLeftSideButtons();
                }else if(this.last_page - this.current_page < 7){
                    this.setRightSideButtons();
                }else{
                    this.setMiddleButtons();
                }
            }else{
                this.setNormalButtons();
            }
        },
        setNormalButtons(){
            for(let i = 1; i <= this.last_page; i++){
                let button = this.createButton(i);
                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons(){
            for(let i = 1; i <= 10; i++){
                let button = this.createButton(i)
                this.page_buttons.push(button)
            }
            let button = this.createButton(0, false);
            this.page_buttons.push(button);
            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);
            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        setRightSideButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);
            button = this.createButton(2);
            this.page_buttons.push(button);
            button = this.createButton(0, false);
            this.page_buttons.push(button);
            for(let i = this.last_page - 9; i <= this.last_page; i++){
                button = this.createButton(i);
                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);
            button = this.createButton(2);
            this.page_buttons.push(button);
            button = this.createButton(0, false);
            this.page_buttons.push(button);
            for(let i = this.current_page - 3; i <= this.current_page + 3; i++){
                button = this.createButton(i);
                this.page_buttons.push(button);
            }
            button = this.createButton(0, false);
            this.page_buttons.push(button);
            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);
            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();
            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }
            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active':'') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        },
        getData(path){
            axios.get(path).then(response => {
                this.setProductsPaginationData(response.data)
            })
        },
        setProductsPaginationData(data){
            let filterPath = this.getFilter();
            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }
            this.list_of_products = data.products.data
            this.current_page = data.products.current_page;
            this.first_page_url = data.products.first_page_url;
            this.from = data.products.from;
            this.last_page = data.products.last_page;
            this.last_page_url = data.products.last_page_url;
            this.next_page_url = data.products.next_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.per_page = data.products.per_page;
            this.prev_page_url = data.products.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.products.to;
            this.total = data.products.total;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');
            this.setButtons();
        },
    }
}
</script>

<style scoped>

</style>
