var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { name: "project-installation-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "row ml-2" }, [
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "status" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.status,
                            expression: "form.status",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "radio",
                          name: "status",
                          value: "Completed",
                        },
                        domProps: {
                          checked: _vm.form.status == "Completed",
                          checked: _vm._q(_vm.form.status, "Completed"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, "status", "Completed")
                          },
                        },
                      }),
                      _vm._v(
                        "\n                                    Yes\n                                "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check form-check-inline" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "status" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.status,
                            expression: "form.status",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "radio",
                          name: "status",
                          value: "Dispatched",
                        },
                        domProps: {
                          checked: _vm.form.status == "Dispatched",
                          checked: _vm._q(_vm.form.status, "Dispatched"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, "status", "Dispatched")
                          },
                        },
                      }),
                      _vm._v(
                        "\n                                    No\n                                "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.form.status == "Completed"
              ? _c("div", { staticClass: "mb-2 my-4" }, [
                  _c("h6", [
                    _vm._v(
                      "I hereby confirm that the project was successfully dispatched."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "m-signature-pad my-4",
                      staticStyle: { border: "2px dashed rgb(204, 204, 204)" },
                    },
                    [
                      _c("VueSignaturePad", {
                        ref: "signaturePad",
                        staticClass: "signature-pad--body",
                        attrs: { width: "498px", height: "158px" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.clearSignature },
                      },
                      [_vm._v("Clear")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.saveSignature },
                      },
                      [_vm._v("Save")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "comments" } }, [_vm._v("Comments")]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.comments,
                    expression: "form.comments",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "comments",
                  name: "comments",
                  placeholder: "Description",
                  rows: "5",
                },
                domProps: { value: _vm.form.comments },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "comments", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { disabled: _vm.submit_disabled, type: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.disableButton()
                  },
                },
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-light",
                attrs: { href: "/projects/installations" },
              },
              [_vm._v("Cancel")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "status" } }, [
      _vm._v("Completed?"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }