var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "tool-checkin-form",
        attrs: { id: "tool-checkin-form", name: "tool-checkin-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h6", { staticClass: "card-title" }, [
                _vm._v("Damaged Tools Details"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4 row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm._v(
                        "\n                                        Category:\n                                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _vm._v(
                        "\n                                        " +
                          _vm._s(_vm.tool.category.name) +
                          "\n                                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm._v(
                        "\n                                        Description:\n                                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _vm._v(
                        "\n                                        " +
                          _vm._s(_vm.tool.description) +
                          "\n                                    "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm._v(
                        "\n                                        Brand Name:\n                                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _vm._v(
                        "\n                                        " +
                          _vm._s(_vm.tool.brand.name) +
                          "\n                                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _vm._v(
                        "\n                                        Model:\n                                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _vm._v(
                        "\n                                        " +
                          _vm._s(_vm.tool.model) +
                          "\n                                    "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive pt-3" }, [
                _c(
                  "table",
                  {
                    staticClass: "table table-bordered table-hover",
                    attrs: { id: "damaged-tools-details-table" },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(this.tool_out, function (item, index) {
                        return _c("tr", [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addModal(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-out-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(item.tool_number) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(item.serial_code) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? item.histories[item.histories.length - 1]
                                        .supervisor.first_name +
                                        " " +
                                        item.histories[
                                          item.histories.length - 1
                                        ].supervisor.last_name
                                    : ""
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? item.histories[item.histories.length - 1]
                                        .crew.name
                                    : ""
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? _vm
                                        .moment(
                                          item.histories[
                                            item.histories.length - 1
                                          ].check_in_date
                                        )
                                        .format("l")
                                    : ""
                                ) +
                                "\n\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? item.histories[item.histories.length - 1]
                                        .return_reason
                                    : ""
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(item.quality_check.name) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? item.histories[item.histories.length - 1]
                                        .comment
                                    : ""
                                ) +
                                "\n                                    "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
                _vm._v("Tools to Write-Off"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive pt-3" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.tool_in, function (item, index) {
                        return _c("tr", [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFromIn(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-in-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(item.tool_number) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(item.serial_code) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? item.histories[item.histories.length - 1]
                                        .supervisor.first_name +
                                        " " +
                                        item.histories[
                                          item.histories.length - 1
                                        ].supervisor.last_name
                                    : ""
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  item.histories.length > 0
                                    ? item.histories[item.histories.length - 1]
                                        .crew.name
                                    : ""
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  _vm.moment(_vm.form.writeoff_date).format("l")
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(
                                  _vm.reasons.find(function (reason) {
                                    return reason.id == item.write_off_reason
                                  }).name
                                ) +
                                "\n                                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                        " +
                                _vm._s(item.comment) +
                                "\n                                    "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                        Add to Write-Off List\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                       Tool Number\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Serial Number\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Crew Supervisor\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Crew\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Check In Date\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Return Reason\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Quality Check\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Comment\n                                    "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                        Remove from list\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Tool Number\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Serial Number\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Crew Supervisor\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Crew\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Write-off Date\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Write Off Reason\n                                    "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                        Comment\n                                    "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-5" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary mr-2", attrs: { type: "submit" } },
        [_vm._v("Submit")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-light",
          attrs: { href: "/accounting/tools/damaged" },
        },
        [_vm._v("Cancel")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }