var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "bin-to-bin-serial-codes-modal",
        height: "auto",
        scrollable: true,
      },
    },
    [
      _c("div", { staticClass: "modal-content border-bottom-0" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("div", [
            _c("div", { staticClass: "row justify-content-between" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  _vm._s(
                    _vm.product.product_name +
                      " (" +
                      _vm.product.product_sku +
                      ")"
                  )
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-right" }, [
                _vm._v(
                  "Serials: " +
                    _vm._s(_vm.serials.length) +
                    "/" +
                    _vm._s(
                      _vm.product.quantity === "" ? "0" : _vm.product.quantity
                    )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4 row" }, [
              _c(
                "h6",
                {
                  staticClass: "mr-2 p-2",
                  class: { "modal-serial-selected": _vm.isInput },
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScreen("input")
                    },
                  },
                },
                [_vm._v("Input Serial Number")]
              ),
              _vm._v(" "),
              _c(
                "h6",
                {
                  staticClass: "p-2",
                  class: { "modal-serial-selected": _vm.isList },
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScreen("list")
                    },
                  },
                },
                [_vm._v("List of Serial Numbers")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _vm.screen == "input"
            ? _c("div", { staticClass: "form-group" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.serial,
                      expression: "serial",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    autofocus: "",
                    autocomplete: "off",
                    type: "text",
                    rows: "5",
                    name: "serial_number",
                    id: "serial_number",
                    placeholder: "Input Serial Numbers",
                  },
                  domProps: { value: _vm.serial },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.addSerial.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.serial = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: " mt-4 alert alert-warning",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      "You can enter multiple serial numbers separated by comma (e.g: 1234, 5678)"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.screen == "list"
            ? _c("div", [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-end",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row align-items-end" }, [
                          _c("div"),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mr-2" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filter,
                                  expression: "filter",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "filter",
                                name: "filter",
                                placeholder: "Search",
                              },
                              domProps: { value: _vm.filter },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.filter = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.getData(
                                      _vm.path + "?" + _vm.getFilter()
                                    )
                                  },
                                },
                              },
                              [_vm._v("Filter")]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row mt-1" }, [
                      _c("div", { staticClass: "col-12 overflow-auto" }, [
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-bordered table-hover products-table",
                          },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Select")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("SKU")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Serial Number")]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.filtered_serials,
                                function (product, index) {
                                  return _c("tr", [
                                    _c("td", { staticClass: "text-center" }, [
                                      !_vm.inSerials(product)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "cursor-pointer",
                                              staticStyle: { color: "blue" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectProduct(
                                                    product
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Select")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass: "cursor-pointer",
                                              staticStyle: { color: "red" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeProduct(
                                                    product
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Remove")]
                                          ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(product.sku))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(product.serial_code)),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-between mt-3" },
                      [
                        _c("div"),
                        _vm._v(" "),
                        _c("div", [
                          _c("nav", [
                            _c(
                              "ul",
                              { staticClass: "pagination" },
                              [
                                _c("li", { class: _vm.prevButtonClass }, [
                                  _vm.prev_page_url == "" ||
                                  _vm.prev_page_url == null
                                    ? _c("span", { staticClass: "page-link" }, [
                                        _vm._v(_vm._s("<")),
                                      ])
                                    : _c(
                                        "button",
                                        {
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getData(
                                                _vm.prev_page_url
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s("<"))]
                                      ),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.page_buttons,
                                  function (button, index) {
                                    return _c("li", { class: button.class }, [
                                      button.isActualPage
                                        ? _c(
                                            "span",
                                            { staticClass: "page-link" },
                                            [_vm._v(_vm._s(button.pageNumber))]
                                          )
                                        : button.disabledButton
                                        ? _c(
                                            "span",
                                            { staticClass: "page-link" },
                                            [_vm._v("...")]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              staticClass: "page-link",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getData(
                                                    button.path
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(button.pageNumber))]
                                          ),
                                    ])
                                  }
                                ),
                                _vm._v(" "),
                                _c("li", { class: _vm.nextButtonClass }, [
                                  _vm.next_page_url == "" ||
                                  _vm.next_page_url == null
                                    ? _c("span", { staticClass: "page-link" }, [
                                        _vm._v(_vm._s(">")),
                                      ])
                                    : _c(
                                        "button",
                                        {
                                          staticClass: "page-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getData(
                                                _vm.next_page_url
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(">"))]
                                      ),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$modal.hide("bin-to-bin-serial-codes-modal")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _vm.screen === "input"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "button" },
                  on: { click: _vm.addSerial },
                },
                [_vm._v("Add Serial Number")]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }