<template>
    <div>
        <form @submit.prevent="submit" id="receiving-form" name="receiving-form" :ref="'receiving-form'">

            <h6>Adjustment Details</h6>
            <div class="col-md-6">
                <div class="form-group mb-3">
                    <label for="inventory">Inventory: <span class="required_asterisk">*</span></label>
                    <div class="row ml-2">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="optionsRadios5" id="optionsRadios5" @change="updateComponent" value="add" v-model="form.adjustment_type">
                                Add
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="product" id="optionsRadios6" value="remove" @change="updateComponent" v-model="form.adjustment_type">
                                Remove
                            </label>
                        </div>
                    </div>
                </div>
                <div v-for="(item,index) in form.products" :key="index">
                    <div v-if="form.adjustment_type == 'add'">
                        <div class="form-group col-md-12 ml-0 pl-0 mb-3">
                            <label for="supplier">Supplier<span class="required_asterisk">*</span></label>
                            <dropbox :options="suppliers" v-model="form.supplier_id">
                                <option></option>
                            </dropbox>
                        </div>
                        <div class="form-group mb-3">
                            <label for="order_qty">Amount<span class="required_asterisk">*</span></label>
                            <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="amount" id="amount" placeholder="####" v-model="item.qty_received">
                        </div>
                        <div class="form-check form-check-flat form-check-primary mb-3">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" @change="updateComponent" v-model="item.same_warehouse" >
                                Same Warehouse?
                            </label>
                        </div>
                        <div class="form-check form-check-flat form-check-primary mb-3">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" @change="updateComponent" v-model="item.scan_serials">
                                Scan Serial Numbers?
                            </label>
                        </div>
                        <div class="form-group w-100 mb-3">
                            <label for="comment">Adjustment Reason</label>
                            <textarea class="form-control" id="adjustment" name="adjustment" placeholder="Adjustment Reason" rows="5" v-model="form.reason"></textarea>
                        </div>
                        <div class="row">
                            <h6 class="card-title">Warehouse Allocation</h6>
                        </div>
                        <div class="mb-3">
                            <warehouse-allocation-component ref="warehouse-component" :index="index" :parent="item" :type="'product'" :zones="zones" :locations="locations"></warehouse-allocation-component>
                        </div>
                    </div>
                    <div v-else>
                        <div class="form-group col-md-12 ml-0 pl-0 mb-3">
                            <label for="supplier">Supplier<span class="required_asterisk">*</span></label>
                            <dropbox :options="suppliers" v-model="form.supplier_id">
                                <option></option>
                            </dropbox>
                        </div>
                        <div class="form-group mb-3">
                            <label for="amount">Amount<span class="required_asterisk">*</span></label>
                            <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="amount"  placeholder="####" v-model="item.qty_received">
                        </div>
                        <div class="form-check form-check-flat form-check-primary mb-3">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" @change="updateComponent" v-model="item.same_warehouse" >
                                Same Warehouse?
                            </label>
                        </div>
                        <div class="form-check form-check-flat form-check-primary mb-3">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" @change="updateComponent" v-model="item.scan_serials">
                                Scan Serial Numbers?
                            </label>
                        </div>
<!--                        <div class="form-group">-->
<!--                            <label for="order_qty">Serial Numbers</label>-->
<!--                            <p type="button" style="color: #007bff" @click="$modal.show(index+'-serial-codes-modal')">Add or Edit Codes</p>-->
<!--                            <serial-codes-modal :ref="'serial-code-modal'" :product="item" :index="index" v-bind:quantity="item.qty_received"></serial-codes-modal>-->
<!--                        </div>-->
                        <div class="form-group w-100 mb-3">
                            <label for="comment">Adjustment Reason</label>
                            <textarea class="form-control" id="adjustment" name="adjustment" placeholder="Adjustment Reason" rows="5" v-model="form.reason"></textarea>
                        </div>
                        <div class="row">
                            <h6 class="card-title">Warehouse Allocation</h6>
                        </div>
                        <div class="mb-3">
                            <warehouse-allocation-component ref="warehouse-component" :index="index" :parent="item" :type="'product'" :zones="zones" :locations="locations"></warehouse-allocation-component>
                        </div>
                    </div>
                </div>
            </div>


            <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2">Submit</button>
            <a href="/accounting/reconciliation" class="btn btn-light">Cancel</a>
        </form>

    </div>
</template>

<script>
    import 'bootstrap-datepicker';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import Swal from 'sweetalert2';
    import { EditIcon} from 'vue-feather-icons';
    import 'dropify';
    import 'dropify/dist/css/dropify.min.css'
    import moment from "moment";

    export default {
        name: "InventoryAdjustmentComponent",
        props:['product','suppliers','warehouses','zones','locations','date'],
        components:{
            EditIcon,
            datePicker
        },
        beforeMount() {
            $('#documents').dropify();

            let product = this.$props.product;
            this.form.products.push({
                id: product.id,
                name: product.name,
                sku: product.sku,
                description: product.description,
                order_qty: "",
                qty_received: "",
                serial_codes: [],
                // warehouse_id: product.warehouse_id,
                // zone_id: product.zones.length > 0 ? product.zones[0].id : "",
                // location_id: product.locations.length > 0 ? product.locations[0].id:"",
                zones_options: [],
                locations_options: [],
                category: product.category,
                description_slug: product.description_slug,
                product_identifier: product.product_identifier,
                warehouses_allocation:[],
                warehouses: this.warehouses,
                warehouse_id:"",
                zone_id: "",
                location_id: "",
                scan_serials:true,
                same_warehouse:true
            })
            // this.form.product.warehouses_allocation = [];
            // this.form.product.warehouse_id = '';
            // this.form.product.zone_id = "";
            // this.form.product.location_id = "";
            // this.form.product.scan_serials = true;
            // this.form.product.same_warehouse = true;
            // this.form.product.serial_codes = [];
            // this.form.product.warehouses = this.warehouses;
            // this.form.product.zones_options = [];
            // this.form.product.locations_options = [];


        },
        data(){
            return{
                form:{
                    products: [],
                    received_date: moment().format('MM/DD/YYYY'),
                    invoice_number: "",
                    supplier_id: "",
                    amount: "",
                    date: this.$props.date,
                    adjustment_type: 'add',
                    reason: '',
                    supplier_id: '',
                },
                purchase_order: this.$props.order,
                datePickerOptions:{
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    maxDate: new Date()
                },
                moment: moment,
                submit_disabled: false,
            }
        },
        methods:{
            submit(){
                this.submit_disabled = true;
                let validSerials = true;
                let validQtyReceived = true;
                let diffQty = false;
                let validWarehouseAmount = true;
                let supplierNotSelected = false;
                let warehouseNotSelected = false;


                this.form.products.forEach(product=>{
                    if(product.serial_codes.length > 0 && product.qty_received !== product.serial_codes.length.toString()){
                        validSerials = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The product '+product.name + ' (' + product.sku + ') does not have enough serials.',
                        });
                    }else if(product.qty_received === '' || product.qty_received === null){
                        validQtyReceived = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Input the quantity received for the product '+product.name + ' (' + product.sku + ').',
                        });
                    }else{
                        let amount = 0;
                        let productsWithNoWarehouseID = 0;
                        if(product.same_warehouse == false && product.scan_serials == false){
                            product.warehouses_allocation.forEach(warehouse =>{
                                amount += parseInt(warehouse.qty,10);

                                if (warehouse.warehouse_id == '' || product.warehouse_id == null)
                                {
                                 productsWithNoWarehouseID += 1;
                                }



                            })
                            //console.log(productsWithNoWarehouseID)
                            if(productsWithNoWarehouseID != 0){
                                warehouseNotSelected = true;
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'A Product Warehouse was Not Selected',
                                });
                            }


                            if(amount != product.qty_received){
                                validWarehouseAmount = false;
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Warehouse Allocation quantities does not match with Qty. Received '+product.name + ' (' + product.sku + ').',
                                });
                            }






                        }
                    }



                    //WMS-316 Validate Required Fields
                    //console.log({'supplier_id': this.form.supplier_id ,'warehouse_id':  product.warehouse_id});
                    //console.log({'adjuemtent_type': this.form.adjustment_type});

                    if(this.form.supplier_id == "" || this.form.supplier_id == null){
                            supplierNotSelected = true;
                    }

                    if(this.form.adjustment_type == 'add'){

                        if (product.same_warehouse == true) {
                            if(product.warehouse_id == "" || product.warehouse_id == null){
                            warehouseNotSelected = true;
                        }


                    }
                    }




                    //console.log({'supplier': supplierNotSelected ,'warehouse': warehouseNotSelected});

                    if( supplierNotSelected && warehouseNotSelected){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The product Supplier And Warehouse were NOT selected.',
                        });
                    }else if(supplierNotSelected) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The product Supplier was NOT selected.',
                        });
                    } else if(warehouseNotSelected){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The product Warehouse was NOT selected.',
                        });
                    }




                })





                if(validSerials && validQtyReceived && validWarehouseAmount && !supplierNotSelected && !warehouseNotSelected){
                    let attributes = this.form;

                    //console.log('PASS');

                    axios.post('/accounting/products/' + this.$props.product.id + '/adjustment',attributes).then(response => {
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                            this.submit_disabled = false;
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error=>{
                        if(error.response.status === 403){
                            this.submit_disabled = false;
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Error 403',
                            });
                        }
                    });
                }else{
                    this.submit_disabled = false;
                }
            },
            updateComponent(){
                this.$forceUpdate();
                this.$refs['warehouse-component'][0].$forceUpdate();
            }
        }
    }
</script>

<style scoped>

</style>
