var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        name: "scan-location-modal",
        scrollable: true,
        height: "auto",
        width: "400px",
      },
    },
    [
      _c(
        "div",
        {
          ref: "vuemodal",
          staticClass: "modal-content border-bottom-0",
          attrs: { id: "myModal" },
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", [
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v("Scan Location"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              attrs: { id: "form", name: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "text-align-all": "center",
                        "align-self": "center",
                        "align-content": "center",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.input_scan_location,
                            expression: "input_scan_location",
                          },
                        ],
                        staticClass: "form-control-lg text-center",
                        attrs: {
                          type: "text",
                          name: "scan_location",
                          id: "scan_location",
                          placeholder: "Location",
                          autofocus: "",
                        },
                        domProps: { value: _vm.input_scan_location },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submit(
                              _vm.input_scan_location,
                              _vm.index
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.input_scan_location = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "red" },
                          attrs: { hidden: !_vm.input_empty_location },
                        },
                        [_vm._v("Location not found for scanned product.")]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.$modal.hide("scan-location-modal"), _vm.clear_all()
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.submit(_vm.input_scan_location, _vm.index)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }