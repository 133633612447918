var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-6 grid-margin stretch-card" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            attrs: { id: "form", name: "form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.add.apply(null, arguments)
              },
            },
          },
          [
            _c("h6", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive pt-3 mb-3" }, [
              _c(
                "table",
                {
                  staticClass: "table table-bordered table-hover date-columns",
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.dropdown.items, function (item, index) {
                      return _c("tr", [_c("td", [_vm._v(_vm._s(item.name))])])
                    }),
                    0
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "product_category_name" } }, [
                _vm._v(_vm._s(_vm.name) + " Name"),
                _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dropdown.name,
                    expression: "dropdown.name",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "product_category_name",
                  id: "product_category_name",
                  placeholder: "Name",
                  value: "id",
                },
                domProps: { value: _vm.dropdown.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.dropdown, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "submit" },
              },
              [_vm._v("Add")]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                Name\n                            "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }