var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        ref: "tool-checkin-form",
        attrs: { id: "tool-checkin-form", name: "tool-checkin-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h6", { staticClass: "card-title" }, [_vm._v("Check In")]),
              _vm._v(" "),
              _c("label", { attrs: { for: "crew_id" } }, [_vm._v("Crew:")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group col-md-12",
                  staticStyle: { display: "flex" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "crew_id",
                            id: "crew_id",
                            options: this.$props.crews,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.loadTools(_vm.form.crew_id)
                            },
                          },
                          model: {
                            value: _vm.form.crew_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "crew_id", $$v)
                            },
                            expression: "form.crew_id",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "auto",
                        "margin-right": "0",
                        display: "flex",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary mt-6 mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.searchTools(_vm.scanned_serial)
                            },
                          },
                        },
                        [_vm._v("Scan")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.scanned_serial,
                            expression: "scanned_serial",
                          },
                        ],
                        staticClass: "form-control mt-6",
                        attrs: {
                          type: "text",
                          id: "tool_serial_number",
                          name: "tool_serial_number",
                          autocomplete: "off",
                          placeholder: "Insert S/N or Tool #",
                        },
                        domProps: { value: _vm.scanned_serial },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.scanned_serial = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("h6", { staticClass: "card-subtitle mb-2 mt-2 text-muted" }, [
                _vm._v("Tools Available for Check In "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive pt-3" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(this.tool_out, function (item, index) {
                        return _c("tr", [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addModal(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-out-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool_number) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.serial_code) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.category.name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          item.tool.type !== null
                            ? _c("td", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(item.tool.type.name) +
                                    "\n                                "
                                ),
                              ])
                            : _c("td"),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.description) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.brand.name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.model) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.histories
                                    ? _vm
                                        .moment(
                                          item.histories[
                                            item.histories.length - 1
                                          ].check_out_date
                                        )
                                        .format("l")
                                    : ""
                                ) +
                                "\n\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.supervisor.first_name +
                                    " " +
                                    item.supervisor.last_name
                                ) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.histories
                                    ? item.histories[item.histories.length - 1]
                                        .comment
                                    : ""
                                ) +
                                "\n                                "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12 mt-2 grid-margin stretch-card" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h6", { staticClass: "card-subtitle mb-2 text-muted" }, [
                _vm._v("Tools to be check in"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive pt-3" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-hover" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.tool_in, function (item, index) {
                        return _c("tr", [
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFromIn(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("log-in-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-icon ",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editInfo(item.id, index)
                                  },
                                },
                              },
                              [
                                _c("edit-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { size: "1.5x" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool_number) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.serial_code) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          item.tool.type !== null
                            ? _c("td", [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(item.tool.type.name) +
                                    "\n                                "
                                ),
                              ])
                            : _c("td"),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.description) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.brand.name) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.tool.model) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.histories
                                    ? _vm
                                        .moment(
                                          item.histories[
                                            item.histories.length - 1
                                          ].check_out_date
                                        )
                                        .format("l")
                                    : ""
                                ) +
                                "\n\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  item.histories
                                    ? item.histories[item.histories.length - 1]
                                        .crew.name
                                    : ""
                                ) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.comment) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(
                                  _vm.quality_checks.find(function (quality) {
                                    return quality.id == item.quality_check
                                  }).name
                                ) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(item.return_reason) +
                                "\n                                "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group mt-5" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex",
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("datePicker", {
                      attrs: {
                        autocomplete: "off",
                        config: _vm.datePickerOptions,
                        name: "check_out_date",
                      },
                      model: {
                        value: _vm.form.check_in_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "check_in_date", $$v)
                        },
                        expression: "form.check_in_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                    Add to check in list\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Tool Number\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Serial Number\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Category\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Tool Type\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Description\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Brand Name\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Model\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Check out Date\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Crew Supervisor\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Comment\n                                "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                    Remove from list\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Tool Number\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Serial Number\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Tool Type\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Description\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Brand Name\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Model\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Check out date\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Crew\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Comment\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Quality Check\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Return Reason\n                                "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "check_in_date" } }, [
      _vm._v("Check In Date"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon bg-transparent" }, [
      _c("i", {
        staticClass: " text-primary",
        attrs: { "data-feather": "calendar" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-5" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary mr-2", attrs: { type: "submit" } },
        [_vm._v("Complete Check In")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }