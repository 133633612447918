var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-md-12 grid-margin stretch-card" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h6", { staticClass: "card-title" }, [_vm._v("Edit BOM")]),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.bom_identifier,
                          expression: "form.bom_identifier",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "bom_identifier",
                        id: "bom_identifier",
                        placeholder: "Bom ID",
                      },
                      domProps: { value: _vm.form.bom_identifier },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "bom_identifier",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "battery_type",
                            id: "battery_type",
                            options: this.$props.battery_types,
                          },
                          model: {
                            value: _vm.form.battery_type_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "battery_type_id", $$v)
                            },
                            expression: "form.battery_type_id",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.system_size,
                          expression: "form.system_size",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "system_size",
                        id: "system_size",
                        placeholder: "####",
                      },
                      domProps: { value: _vm.form.system_size },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "system_size", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.battery_qty,
                          expression: "form.battery_qty",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        oninput: "validity.valid||(value='')",
                        min: "0",
                        disabled: "disabled",
                        name: "battery_qty",
                        id: "battery_qty",
                        placeholder: "",
                      },
                      domProps: { value: _vm.form.battery_qty },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "battery_qty", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "description" } }, [
                      _vm._v("Description"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.comment,
                          expression: "form.comment",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "description",
                        id: "description",
                        placeholder: "",
                      },
                      domProps: { value: _vm.form.comment },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "comment", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "category",
                            id: "category",
                            options: this.$props.categories,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.loadProducts()
                            },
                          },
                          model: {
                            value: _vm.category_id,
                            callback: function ($$v) {
                              _vm.category_id = $$v
                            },
                            expression: "category_id",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "product",
                            id: "product",
                            options: this.product_options,
                          },
                          on: {
                            change: function ($event) {
                              _vm.loadWarehouses(), _vm.inventoryWarehouse()
                            },
                          },
                          model: {
                            value: _vm.product_id,
                            callback: function ($$v) {
                              _vm.product_id = $$v
                            },
                            expression: "product_id",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "quantity" } }, [
                      _vm._v("Quantity"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quantity,
                          expression: "quantity",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        oninput: "validity.valid||(value='')",
                        min: "0",
                        name: "quantity",
                        id: "quantity",
                        placeholder: "",
                      },
                      domProps: { value: _vm.quantity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.quantity = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "category",
                            id: "warehouse",
                            options: this.warehouse_options,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.inventoryWarehouse()
                            },
                          },
                          model: {
                            value: _vm.warehousename,
                            callback: function ($$v) {
                              _vm.warehousename = $$v
                            },
                            expression: "warehousename",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "dropbox",
                        {
                          attrs: {
                            name: "zone",
                            id: "zone",
                            options: this.zone_options,
                          },
                          model: {
                            value: _vm.zone,
                            callback: function ($$v) {
                              _vm.zone = $$v
                            },
                            expression: "zone",
                          },
                        },
                        [_c("option")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-4" }, [
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "button" },
                      on: { click: _vm.addProduct },
                    },
                    [_vm._v("Add Product")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row col-md-12 mb-4" }, [
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-bordered table-hover date-columns",
                    attrs: { id: "product-table" },
                  },
                  [
                    _vm._m(8),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _vm._l(
                          _vm.categories,
                          function (category, index_category) {
                            return [
                              _c("tr", [
                                _c("td", [_vm._v(_vm._s(category.name))]),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c("td"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.form.products.filter(function (item) {
                                  return item.category.id == category.id
                                }),
                                function (product, index_product) {
                                  return _c("tr", [
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row justify-content-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mr-2",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editProduct(
                                                    product.id,
                                                    product.pivot.warehouse
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("edit-icon", {
                                                attrs: { size: "1.5x" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteProduct(
                                                    product
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("trash2-icon", {
                                                attrs: { size: "1.5x" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "dropbox",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              options: product.warehouses,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.updateWarehouse(
                                                  product
                                                )
                                              },
                                            },
                                            model: {
                                              value: product.pivot.warehouse_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  product.pivot,
                                                  "warehouse_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "product.pivot.warehouse_id",
                                            },
                                          },
                                          [_c("option")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: product.pivot.zone_id,
                                              expression:
                                                "product.pivot.zone_id",
                                            },
                                          ],
                                          staticStyle: { width: "100%" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadZones2(
                                                product,
                                                product.pivot.warehouse_id
                                              )
                                            },
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                product.pivot,
                                                "zone_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("option"),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { domProps: { value: null } },
                                            [_vm._v("None")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            product.zone_options != null
                                              ? product.zone_options
                                              : [],
                                            function (zone) {
                                              return _c(
                                                "option",
                                                {
                                                  key: zone.id,
                                                  domProps: { value: zone.id },
                                                },
                                                [_vm._v(_vm._s(zone.name))]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(product.sku))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(product.name))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(product.description)),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(product.pivot.quantity)),
                                    ]),
                                  ])
                                }
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-light", attrs: { href: "/boms" } },
                [_vm._v("Cancel")]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "bom_identifier" } }, [
      _vm._v("BOM ID"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "battery_type" } }, [
      _vm._v("Battery Type"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "system_size" } }, [
      _vm._v("System Size"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "battery_qty" } }, [
      _vm._v("Battery Qty"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "category" } }, [
      _vm._v("Category"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "category" } }, [
      _vm._v("Product"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "category" } }, [
      _vm._v("Warehouse"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "zone" } }, [
      _vm._v("Zone"),
      _c("span", { staticClass: "required_asterisk" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v(
            "\n                                    Category\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Warehouse\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Zone\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Product ID\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Product\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Description\n                                "
          ),
        ]),
        _vm._v(" "),
        _c("th", [
          _vm._v(
            "\n                                    Amount\n                                "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }